import React, { useState } from "react";

function Features({ features }) {
  //console.log(features.length)
  const [visible, setVisible] = useState(2);
  const [loadcontent, setLoadContent] = useState(true);

  // console.log(features)

  function loadMore(quoteLength) {
    setVisible(quoteLength);
    setLoadContent(false);
  }

  function loadless() {
    setVisible(2);
    setLoadContent(true);
  }

  return (
    <div>
      {features &&
        features
          .filter((feature) => feature.IsOptional === "false")
          .slice(0, visible)
          .map((feature) => {
            return (
              <li>
                <strong>
                  {feature.Coveragename} : <span>Covered</span>
                </strong>{" "}
                <span>Free</span>
              </li>
            );
          })}
      <p
        onClick={() => loadMore(features.length)}
        className={loadcontent ? "more" : "loadfeaturecontent"}
      >
        {" "}
        More...
      </p>

      <p
        onClick={() => loadless(features.length)}
        className={loadcontent ? "loadfeaturecontent" : "more"}
      >
        Less...
      </p>
    </div>
  );
}

export default Features;
