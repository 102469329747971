import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import InsuranceList from "./components/insurancelist";
import Quotation from "./components/quotation";
import AdminLogin from "./components/admin/login";
import Dashboard from "./components/admin/dashboard";
// import Home from "./components/home"
// import { createBrowserHistory } from 'history'
ReactDOM.render(
  // <React.StrictMode>
  // <App/>
  // </React.StrictMode>,
  // document.getElementById('root')
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/staff" component={App} />
        <Route path="/App" component={App} />
        <Route path="/insurancelist" component={InsuranceList} />
        <Route path="/insurancelist?customerId" component={InsuranceList} />
        <Route path="/quotation" component={Quotation} />
        <Route path="/admin" component={AdminLogin} />
        <Route path="/dashboard" component={Dashboard} />
      </Switch>
    </Router>
  </React.StrictMode>,
  //   <Router history={createBrowserHistory}>
  //         <Route exact path="/" component={withRouter(Home)} />
  //          <Route path="/insurancelist" component={withRouter(InsuranceList)} />
  //          <Route path="/quotation" component={withRouter(Quotation)} />
  // </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
