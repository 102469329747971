import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
//import { Accordion} from 'react-bootstrap'
import { Label, Button, Modal, ModalBody, ModalFooter } from "reactstrap";

import axios from "axios";

import { api } from "../apiConfig/apiurl";

function Qutation(props) {
  const [counter, setCounter] = useState(0);

  const [paymentLink, setPaymentLink] = useState("");

  let counterCondi = 0;

  useEffect(() => {
    console.log("quotation props", props);
  }, []);

  const launch = useRef();

  const counterTimes = 10;

  console.log(props);
  const [netpremium, setnetpremium] = useState(
    props.location.state.quote.NetPremium
  );

  const [premium, setpremium] = useState(props.location.state.quote.Premium);

  const [vat, setvat] = useState(props.location.state.quote.VAT);

  const [quoteDetails, setQuoteDetails] = useState([]);

  const [coverageID, setCoverageID] = useState([]);

  const [checkedItems, setCheckedItems] = useState(new Map());

  const [QuoteNo, setQuoteNo] = useState("");

  const [QuoteNoUp, setQuoteNoUp] = useState("");

  const [updatequoteDetails, setupdateQuoteDetails] = useState([]);

  const [checked, setChecked] = useState([]);
  const [smShow, setSmShow] = useState(false);
  const [agree, setAgree] = useState(false);

  const [oasisURL, setoasisURL] = useState([]);
  const [smShowURL, setSmShowURL] = useState(false);
  const [coverageidCHeck, setcoverageidCHeck] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("pending");

  //const [AgentDetails, setAgentDetails] = useState([]);

  //     const featuredisplay= props.location.state.quote.Features.filter(feature => feature.IsOptional=== 'false');
  // const middleindex = Math.ceil(featuredisplay.length / 2);
  // console.log("length",featuredisplay.length)

  let featuredisplay = [];
  let middleindex = 0;
  let quotenumber;
  let quotedata;
  let history = useHistory();

  //Hakkim
  const [paymentErrMsg, setpaymentErrMsg] = useState("Something went wrong");
  const [paymentErrMsgDesc, setpaymentErrMsgDesc] = useState(
    "Please wait while we prepare your policy.. Something went wrong"
  );
  let PersonalAdvisorDetails;
  const [transactionRefNo, settransactionRefNo] = useState("0");
  //const [PrepolicyFields, setPrepolicyFields] = useState();
  const [disablePaymentBtn, setdisablePaymentBtn] = useState(false);

  useEffect(() => {
    axios
      .post(api + "Vehicle/GetQuoteNumber", {
        quoteNumber: "",

        vehicleDetailsID: props.location.state.quote.VehicleDetailsID,

        insQuoteID: props.location.state.quote.InsQuoteID,

        insName: props.location.state.quote.InsName,

        planName: props.location.state.quote.PlanName,

        coveragesList: "",

        //  insQuoteID: "RQP00395343",

        //  insName: "The New India Assurance Company Limited",

        //  planName: "SPECIAL SCHEME - RENEWALS",
      })

      .then(function (response) {
        var myobj = JSON.parse(response.data);

        quotenumber = myobj.QuoteNumber;

        setQuoteNo(quotenumber);

        if (quotenumber !== null) fnGetQuotationDetails(quotenumber);
      })

      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function fnGetQuotationDetails(quoteno) {
    axios
      .post(api + `Vehicle/GetMotorQuoteDetails?QuoteNumber=` + quoteno)
      .then(function (response) {
        quotedata = response.data;
        setQuoteDetails(quotedata);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //Hakkim
  function fnGetPersonalAdvisorDetails(username) {
    debugger;
    axios
      .post(api + `User/GetPersonalAdvisorDetails?username=` + username)
      .then(function (response) {
        PersonalAdvisorDetails = response.data;

        if (PersonalAdvisorDetails.Status === "Success") {
          setSmShowURL(true);
          setPaymentStatus("Agent");
        } else {
          setPaymentStatus("Fail");
        }
        //console.log("fnGetPersonalAdvisorDetails", PersonalAdvisorDetails);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function AddAdvisor() {
    debugger;
    axios
      .post(
        api +
          `User/GetPersonalAdvisorDetails?username=` +
          this.state.personalDetails.email._value
      )
      .then(function (response) {
        if (response.data !== null) {
          console.log(response.data);
          //Store advisor details
          axios.post(api + `Advisor/AddAdvisor`, {
            customerid: props.location.state.vehicleId,
            name: response.data.Name,
            mobileno: response.data.MobileNo,
            email: response.data.Email,
            isactive: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //Hakkim
  //QuoteNumber,TRNNo
  function fnGetPrePolicyDetails(QuoteNumber, TRNNo) {
    setSmShowURL(true);
    setPaymentStatus("Success");
    setpaymentErrMsgDesc("Please wait while we prepare your policy..");
    debugger;
    //let QuoteNumber = "14MOT051221AG0001",      TRNNo = "11371775";
    debugger;
    axios
      .post(api + `Vehicle/GetPrepolicyFields?QuoteNumber=` + QuoteNumber)
      .then(function (response) {
        if (response.status === 200) {
          debugger;
          if (response != null && response.data != null) {
            fnUpdatePrePolicyDetails(TRNNo, response.data);
          }
        } else {
          setpaymentErrMsgDesc("Unable to retrieve policy details");
          //setPaymentStatus("Error");
          // return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  //Hakkim
  function fnUpdatePrePolicyDetails(TRNNo, PrepolicyFields) {
    debugger;
    if (PrepolicyFields === null && PrepolicyFields.QuoteNo === null) {
      setpaymentErrMsg("Unable to Proceed");
      setPaymentStatus("Error");
      return false;
    }

    axios
      .post(api + `Vehicle/UpdatePrepolicyDetails`, {
        QuoteNo: PrepolicyFields.QuoteNo,
        InsName: props.location.state.quote.InsName,
        CommonData: {
          PolicyStartDate: PrepolicyFields.CommonData.PolicyStartDate,
          PolicyEndDate: PrepolicyFields.CommonData.PolicyEndDate,
          PreviousPolicyEndDate:
            PrepolicyFields.CommonData.PreviousPolicyEndDate,
          Address: PrepolicyFields.CommonData.Address,
          POBoxNo: PrepolicyFields.CommonData.POBoxNo,
          EmiratesoftheInsured: PrepolicyFields.CommonData.EmiratesoftheInsured,
          UAEDriverExperienceinYears: 2,
          //PrepolicyFields.CommonData.UAEDriverExperienceinYears,
          IsGCC: PrepolicyFields.CommonData.IsGCC,
          EngineSize: PrepolicyFields.CommonData.EngineSize,
          VehicleColor: "White", //PrepolicyFields.CommonData.VehicleColor,
          NoOfDoors: PrepolicyFields.CommonData.NoOfDoors,
          NoOfSeats: PrepolicyFields.CommonData.NoOfSeats,
          IsMortgage: PrepolicyFields.CommonData.IsMortgage,
          MortgageBank: PrepolicyFields.CommonData.MortgageBank,
          EmirateExpiryDate: PrepolicyFields.CommonData.EmirateExpiryDate,
          DLExpiryDate: PrepolicyFields.CommonData.DLExpiryDate,
        },
        InsData: { TRNNo: String(TRNNo) },
      })
      .then(function (response) {
        debugger;
        //PrepolicyFields = response.data;
        console.log(response);

        if (response.status === 200 && response.data.Status === "Success") {
          fnGetPolicyCertificate(response.data.PolicyNo); //response.data.PolicyNo
          console.log("UpdatePrepolicyDetails " + response);
          return true;
        } else {
          setpaymentErrMsgDesc(
            "Sorry, unable to download your policy certificate now, your personal advisor will be in touch with you shortly."
          );
          //setPaymentStatus("Error");
          console.log(response.data.ErrorDesc);
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  //Hakkim  PolicyNo
  function fnGetPolicyCertificate(PolicyNo) {
    //let PolicyNo = "813/C/278752";
    debugger;
    axios
      .post(api + `Vehicle/GetPolicyCertificate`, {
        PolicyNo: PolicyNo,
      })
      .then(function (response) {
        var respData = JSON.parse(response.data);
        if (response.status === 200 && respData.Result === "Success") {
          setSmShowURL(true);
          setPaymentStatus("Success");
          setpaymentErrMsgDesc(
            "Your Policy download should automatically begin in a few seconds, but if not, click the below link to download."
          );

          //document.getElementById("aDownloadPolicy").className = document.getElementById("aDownloadPolicy").className.replace('hidden','');
          document.getElementById(
            "aDownloadPolicy"
          ).href = `data:application/pdf;base64,${respData.CertificateBase64String}`;

          fnDownloadPolicyCertificate();
          return true;
        } else {
          setpaymentErrMsg("Unable to Proceed");
          setPaymentStatus("Error");
          console.log(response.data.ErrorDesc);
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  function fnDownloadPolicyCertificate() {
    let pdf = document.getElementById("aDownloadPolicy").getAttribute("href");
    const linkSource = `${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "PolicyCertificate.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function paymentDone() {
    setSmShowURL(false);
    window.location.replace("/");
  }

  useEffect(() => {
    if (coverageidCHeck) {
      console.log("cover " + coverageID);

      axios
        .post(api + `Vehicle/UpdateQuoteDetails`, {
          refId: quoteDetails.RefId, //,  "592"quoteDetails.RefId

          quoteNo: quoteDetails.QuoteNo, //"32MOT031220AG0000"  QuoteNo

          coverageid: coverageID,
        })

        .then(function (response1) {
          var myobj = response1.data;

          quotedata = myobj;

          //console.log("jjja", "ll", typeof myobj.QuoteNo);

          setupdateQuoteDetails(myobj);

          setnetpremium(myobj.NetPremium.toFixed(2));

          setpremium(myobj.Premium.toFixed(2));

          setvat(myobj.VATonPremium.toFixed(2));

          setQuoteNoUp(myobj.QuoteNo);
        })

        .catch(function (error) {
          console.log(error);
        });
    } else {
      setcoverageidCHeck(true);
    }
  }, [coverageID]);

  // function handleCheckboxChange(event)
  const backToQuote = (index) => (event) => {
    history.push({
      pathname: "/insurancelist",
      state: {
        quoteList: props.location.state.quoteList,
        vehicleDetails: props.location.state.vehicleDetails,
        vehicleRegistrationWithoutChassisNumber:
          props.location.state.vehicleRegistrationWithoutChassisNumber,
        personalDetails: props.location.state.personalDetails,
      },
    });
  };
  const handleCheckboxChange = (index) => (event) => {
    if (checked.indexOf(index) === -1) {
      // setActive([...isActive, i]);
      setChecked([...checked, index]);
    }

    if (checked.indexOf(index) !== -1) {
      // setActive([...isActive, i]);
      let indexofchecked = [];
      indexofchecked = checked.filter((a) => a !== index);
      setChecked(indexofchecked);
      // console.log("click check",checked.indexOf(index))
    }

    //console.log("Checked Checked",checked)
    // setChecked(index)
    // setCheckedItems(checkedItems => checkedItems.set(event.target.name, event.target.checked));

    let newArr = [];

    //if (!coverageID.includes(event.target.value))

    if (event.target.checked === true) {
      newArr = [...coverageID, event.target.value];

      setCoverageID(newArr);
    } else if (event.target.checked === false) {
      newArr = coverageID.filter((a) => a !== event.target.value);

      setCoverageID(newArr);
    }

    //  if (coverageID.length>0)

    ///Without calling the api update the netpremium////

    //    if (event.target.checked===true)

    //   {

    //      setCoverageID([event.target.id])

    //  setnetpremium((netpremium)*1+(event.target.value)*1);

    //  }

    // else

    //  {

    //  setnetpremium((netpremium)*1-(event.target.value)*1);

    //  }
  };

  const setSmShows = (event) => {
    setSmShow(event);
  };

  const setSmShowsURL = (event) => {
    setSmShowURL(event);
  };
  const onChange = (event) => {
    //console.log(event.target.checked);
    if (!event.target.checked) {
      // setSmShow(true)
      setAgree(false);
      console.log(agree);
    } else {
      setAgree(true);
      console.log(agree);
    }
  };

  const paymentstatustimer = () => {
    launch.current = setInterval(paymentstatuschecker, 10000);
  };

  const paymentstatuschecker = () => {
    //counter=counter+1;
    //setSomeState(prev => ({...prev, count: prev.count + 1}));

    console.log(
      counterCondi,
      " ",
      counterTimes,
      "paymentstatuschecker",
      props.location.state.vehicleId
    );

    axios
      .get(
        api +
          `Quote/GetQuoteDetails?CustomerId=${props.location.state.vehicleId}`
      )

      .then(function (response1) {
        debugger;
        setSmShowURL(true);
        setCounter((prev) => prev + 1);
        counterCondi = counterCondi + 1;
        //Hakkim
        if (response1 !== null && response1.data[0] !== null) {
          settransactionRefNo(response1.data[0].transactionRefNo);
        }

        if (response1.data[0].transactionStatus === "pending") {
          setPaymentStatus("pending");
        }

        if (response1.data[0].transactionStatus === "Success") {
          setPaymentStatus("Success");
          clearInterval(launch.current);
          setCounter(0);
          counterCondi = 0;

          //Check Indicative/Actual Quote
          debugger;
          if (props.location.state.stateActualQuote === 0) {
            //Indicative Quote
            setPaymentStatus("Agent");
            AddAdvisor();
          } else {
            //Actual Quote
            console.log(response1.data[0].quoteNo);
            debugger;
            fnGetPrePolicyDetails(
              response1.data[0].quoteNo,
              response1.data[0].transactionRefNo
            );
            //fnUpdatePrePolicyDetails(response1.data[0].transactionRefNo);
          }
        }

        if (
          response1.data[0].transactionStatus === "Fail" ||
          counterCondi === counterTimes
        ) {
          setPaymentStatus("Fail");
          clearInterval(launch.current);
          setCounter(0);
          counterCondi = 0;
        }
      });
  };

  const payPremium = (event) => {
    //console.log(smShow, agree);
    if (agree === false) {
      setSmShow(true);
    } else {
      let quotenoLocal;

      if (QuoteNoUp !== "") {
        quotenoLocal = QuoteNoUp;
      } else {
        quotenoLocal = quoteDetails.QuoteNo;
      }
      quotenoLocal = quoteDetails.QuoteNo;

      //console.log("Payment", quotenoLocal);

      if (netpremium > 0) {
        axios
          .post(api + `Vehicle/GetPaymentLink`, {
            quoteNumber: quotenoLocal, //QuoteNo,
          })

          .then(function (response1) {
            var myobj = JSON.parse(response1.data);
            if (myobj.Result === "Success") {
              //  quotedata=myobj
              setSmShowURL(true);
              setPaymentLink(myobj.PaymentLink);
              window.open(myobj.PaymentLink, "_blank");
              setoasisURL(myobj.PaymentLink);
              let query = myobj.PaymentLink.split("&");
              let userID = query[0];
              let quoteID = query[1];
              let token = localStorage
                .getItem("user")
                .substring(0, localStorage.getItem("user").length - 1);
              token = token.substring(1);
              userID = userID.split("?");
              debugger;
              console.log("Add quote details");
              console.log("Customer ID: " + props.location.state.vehicleId);
              axios
                .post(api + `Quote/AddQuoteDetails`, {
                  quoteDetailsId: 0,
                  userID: userID[1].substring(7),
                  quoteID: quoteID.substring(8),
                  token: token,
                  transactionRefNo: quoteDetails.RefId.toString(),
                  transactionStatus: "pending",
                  quoteNo: quoteDetails.QuoteNo,
                  insName: props.location.state.quote.InsName,
                  planName: props.location.state.quote.PlanName,
                  premium: premium,
                  vatOnPremium: vat,
                  netPremium: netpremium,
                  isActive: true,
                  quoteStatus: "Quote Generated",
                  status: "pending",
                  customerId: props.location.state.vehicleId,
                  // "features":  quoteDetails.Features
                  features: [
                    {
                      Coveragename: "PA Benefit - Driver",
                      Coverageid: "11",
                      CoveragePremium: "0.00",
                      IsOptional: false,
                    },
                    {
                      Coveragename: "Hire Car Benefit",
                      Coverageid: "14",
                      CoveragePremium: "145.00",
                      IsOptional: true,
                    },
                  ],
                })
                .then(function (response1) {});

              paymentstatustimer();

              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ QuoteID: quoteID.substring(8) }),
              };
            }
            // setoasisURL( myobj.PaymentLink);
            //  window.location = myobj.PaymentLink;
          })

          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };
  const today = new Date();

  return (
    <div className="wrapper bg-gradient-grey">
      <header className="pt-2 pb-2 mb-4 border-bottom bg-blue">
        <div className="container">
          <a
            href="/"
            className="d-flex align-items-center text-dark text-decoration-none"
          >
            <img alt="" src="img/bbc-1.png" width="250px" />
          </a>
        </div>
      </header>
      <div className="outer-body-wrap ">
        <div className="container no-padding">
          <div className="col-lg-12 ">
            <div className=" q-summary bg-grey">
              <div className="row">
                <div className="col-lg-8 ">
                  <div className="container">
                    <div className="right-full-section">
                      <div className="q-sum-head">
                        <h3>Quote Summary</h3>

                        <div className="go-back">
                          <span className="icon-back">
                            <a onClick={(event) => backToQuote()(event)}>
                              {" "}
                              <i
                                className="fas fa-arrow-left"
                                onClick={(event) => backToQuote()(event)}
                              ></i>
                            </a>
                          </span>
                          <p onClick={(event) => backToQuote()(event)}>
                            Back to Quote List
                          </p>
                        </div>
                      </div>

                      <div className="quote-summary-block">
                        <div className="q-head-customer flex-between">
                          <h3>
                            <span className="svg-animation">
                              <svg
                                id="Layer1"
                                enableBackground="new 0 0 64 64"
                                viewBox="0 0 64 64"
                                xmlns="https://www.w3.org/2000/svg"
                              >
                                <g>
                                  <path d="m63 21.536c0-2.501-2.035-4.536-4.536-4.536-1.211 0-2.351.472-3.207 1.329l-2.964 2.964-3.293 3.293v-9.666c3.387-.488 6-3.401 6-6.92 0-3.86-3.14-7-7-7h-40c-3.86 0-7 3.14-7 7v50c0 .552.448 1 1 1h5v3c0 .552.448 1 1 1h40c.552 0 1-.448 1-1v-24.586l9.707-9.707 1.027-1.027c-.518 2.58-1.781 4.952-3.683 6.855l-4.757 4.757 1.414 1.414 4.757-4.757c2.838-2.838 4.425-6.586 4.518-10.585.647-.802 1.017-1.782 1.017-2.828zm-6.329-1.793c.479-.479 1.116-.743 1.793-.743 1.398 0 2.536 1.138 2.536 2.536 0 .677-.264 1.314-.743 1.793l-2.257 2.257-3.586-3.586zm-3.671 3.671 1.086 1.086-12.586 12.586-1.086-1.086zm-23.783 26.389 2.057-4.115 3.038 3.038-4.115 2.057zm3.197-5.803 6.586-6.586 3.586 3.586-6.586 6.586zm15.586-41c2.757 0 5 2.243 5 5s-2.243 5-5 5h-35.11c1.3-1.272 2.11-3.042 2.11-5s-.81-3.728-2.11-5zm-39 9.899v-2.899c0-.551.449-1 1-1h2.899c-.399 1.956-1.943 3.5-3.899 3.899zm-6 44.101v-49c0-2.757 2.243-5 5-5 2.414 0 4.434 1.721 4.899 4h-2.899c-1.654 0-3 1.346-3 3v47zm44 4h-38v-46h38v11.586l-4.414 4.414h-29.586v2h27.586l-2 2h-25.586v2h23.586l-6.293 6.293c-.078.078-.129.171-.176.265l-.011-.006-3 6c-.192.385-.117.85.188 1.154l.292.294-2.293 2.293 1.414 1.414 2.293-2.293.293.293c.192.192.448.293.707.293.151 0 .305-.035.447-.105l6-3-.006-.011c.094-.047.187-.098.265-.176l8-8 2.294-2.294zm-3-21.414-1.086-1.086 12.586-12.586 1.086 1.086z" />
                                  <path d="m13 19h30v2h-30z" />
                                  <path d="m13 23h30v2h-30z" />
                                  <path d="m13 27h30v2h-30z" />
                                  <path d="m13 51h11v2h-11z" />
                                  <path d="m13 55h30v2h-30z" />
                                </g>
                              </svg>
                            </span>{" "}
                            Quote Details:{" "}
                          </h3>
                          {/* <div className="edit-icon">
                                 <a href=""> <i className="fas fa-pencil-alt"></i></a>
                                  </div> */}
                        </div>
                        <div className="col-lg-12 border-top">
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Insurance Company :{" "}
                                  <span>
                                    {props.location.state.quote.InsName}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Plan :{" "}
                                  <span>
                                    {props.location.state.quote.PlanName}
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Quote Number :{" "}
                                  <span>
                                    {quoteDetails.QuoteNo
                                      ? quoteDetails.QuoteNo
                                      : "none"}
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Policy Start Date :{" "}
                                  <span>
                                    {props.location.state
                                      ? props.location.state
                                          .vehicleRegistrationWithoutChassisNumber
                                          .dateOfRegistration._values
                                      : ""}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Status : <span>Active</span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Repair Type :{" "}
                                  <span>
                                    {props.location.state.quote.CoverageType}
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Created Date :{" "}
                                  <span>
                                    {today.getDate() +
                                      "/" +
                                      parseInt(today.getMonth() + 1) +
                                      "/" +
                                      today.getFullYear()}
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Quote Validity :{" "}
                                  <span>
                                    {today.getDate() +
                                      "/" +
                                      parseInt(today.getMonth() + 1) +
                                      "/" +
                                      today.getFullYear()}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="quote-summary-block">
                        <div className="row">
                          <div className="q-head-customer">
                            <h3>Recommended Optional Covers: </h3>
                          </div>

                          <div className="check-buttons">
                            <div className="pill-container">
                              {/* { props.location.state.quote.Features.filter(fea =>fea.IsOptional==="true").length > 0 ? ( */}
                              {quoteDetails.Features ? (
                                quoteDetails.Features.map((p, index) => {
                                  if (p.IsOptional === "true") {
                                    return (
                                      <div
                                        key={index}
                                        className="single-button"
                                      >
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          checked={checkedItems.get(
                                            p.Coverageid
                                          )}
                                          onChange={(event) =>
                                            handleCheckboxChange(index)(event)
                                          }
                                          id={p.Coverageid}
                                          key={p.Coverageid}
                                          name={p.Coveragename}
                                          value={p.Coverageid}
                                        />

                                        <label
                                          className="selector option-a"
                                          htmlFor={p.Coverageid}
                                        >
                                          {" "}
                                          <i
                                            className={
                                              checked.indexOf(index) !== -1
                                                ? "fas fa-check"
                                                : "fas fa-plus"
                                            }
                                          ></i>
                                          {p.Coveragename +
                                            " AED " +
                                            p.CoveragePremium}{" "}
                                        </label>
                                      </div>
                                    );
                                  }
                                })
                              ) : (
                                <p>No Optional Covers</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="quote-summary-block">
                        <div className="q-head-customer flex-between">
                          <h3>
                            <span className="svg-animation">
                              <svg
                                version="1.1"
                                id="Layer1"
                                xmlns="https://www.w3.org/2000/svg"
                                xmlnsXlink="https://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 48.997 48.998"
                                style={{
                                  enableBackground: "new 0 0 48.997 48.998",
                                }}
                                xmlSpace="preserve"
                              >
                                <g>
                                  <path
                                    d="M45.961,18.702c-0.033-0.038-0.061-0.075-0.1-0.112l-1.717-1.657c1.424-0.323,2.957-1.516,2.957-2.74
c0-1.426-1.979-1.932-3.668-1.932c-1.766,0-1.971,1.21-1.992,2.065l-4.43-4.271c-0.9-0.891-2.607-1.592-3.883-1.592H24.5h-0.002
h-8.63c-1.275,0-2.981,0.701-3.882,1.592l-4.429,4.271c-0.023-0.855-0.228-2.065-1.992-2.065c-1.691,0-3.669,0.506-3.669,1.932
c0,1.224,1.534,2.417,2.958,2.74l-1.717,1.657c-0.039,0.037-0.066,0.074-0.1,0.112C1.2,20.272,0,23.184,0,25.297v6.279
c0,1.524,0.601,2.907,1.572,3.938v2.435c0,1.424,1.192,2.585,2.658,2.585h3.214c1.466,0,2.657-1.159,2.657-2.585v-0.623h14.397
H24.5h14.396v0.623c0,1.426,1.19,2.585,2.658,2.585h3.213c1.467,0,2.657-1.161,2.657-2.585v-2.435
c0.972-1.031,1.572-2.414,1.572-3.938v-6.279C48.998,23.184,47.798,20.272,45.961,18.702z M13.613,11.953
c0.623-0.519,1.712-0.913,2.255-0.913h8.63H24.5h8.63c0.543,0,1.632,0.394,2.255,0.913l5.809,5.63H24.5h-0.002H7.805L13.613,11.953
z M1.993,24.347c0-1.546,1.21-2.801,2.704-2.801c1.493,0,6.372,2.864,6.372,4.41s-4.879,1.188-6.372,1.188
C3.203,27.144,1.993,25.894,1.993,24.347z M10.102,34.573H9.587H9.072l-3.055,0.005c-0.848-0.264-1.446-0.572-1.869-0.903
c-0.214-0.167-0.378-0.341-0.506-0.514c-0.129-0.175-0.223-0.347-0.284-0.519c-0.38-1.074,0.405-2.061,0.405-2.061h5.214
l3.476,3.99L10.102,34.573L10.102,34.573z M31.996,34.575H24.5h-0.002h-7.496c-1.563,0-2.832-1.269-2.832-2.831h10.328H24.5h10.328
C34.828,33.308,33.559,34.575,31.996,34.575z M32.654,29.812H24.5h-0.002h-8.154c-1.7,0-3.08-2.096-3.08-4.681h11.234H24.5h11.234
C35.734,27.717,34.354,29.812,32.654,29.812z M45.641,32.644c-0.062,0.172-0.156,0.344-0.285,0.518
c-0.127,0.173-0.291,0.347-0.506,0.514c-0.422,0.331-1.021,0.641-1.869,0.903l-3.055-0.005h-0.515h-0.515h-2.353l3.478-3.99h5.213
C45.234,30.583,46.02,31.568,45.641,32.644z M44.301,27.144c-1.492,0-6.371,0.356-6.371-1.188s4.879-4.41,6.371-4.41
c1.494,0,2.704,1.255,2.704,2.801C47.005,25.892,45.795,27.144,44.301,27.144z"
                                  />
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                              </svg>
                            </span>
                            Vehicle Details:{" "}
                          </h3>
                          {/* <div className="edit-icon">
                                  <a href=""> <i className="fas fa-pencil-alt"></i></a>
                                   </div> */}
                        </div>
                        <div className="col-lg-12 border-top">
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Make :{" "}
                                  <span>
                                    {
                                      props.location.state
                                        .vehicleRegistrationWithoutChassisNumber
                                        .make._value
                                    }
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Model Year :{" "}
                                  <span>
                                    {
                                      props.location.state
                                        .vehicleRegistrationWithoutChassisNumber
                                        .modelYear._value
                                    }
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Model :{" "}
                                  <span>
                                    {
                                      props.location.state
                                        .vehicleRegistrationWithoutChassisNumber
                                        .model._value
                                    }
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Body Type :{" "}
                                  <span>
                                    {
                                      props.location.state
                                        .vehicleRegistrationWithoutChassisNumber
                                        .bodyType._value
                                    }
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Plate Code :{" "}
                                  <span>
                                    {
                                      props.location.state.vehicleDetails
                                        .plateCode._value
                                    }
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Plate Number :{" "}
                                  <span>
                                    {
                                      props.location.state.vehicleDetails
                                        .plateNumber._value
                                    }
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="q-car-content">
                                <p>
                                  Chassis Number :{" "}
                                  <span>
                                    {props.location.state.fileDetailsMulkiya
                                      .chassisNumber._value
                                      ? props.location.state.fileDetailsMulkiya
                                          .chassisNumber._value
                                      : "none"}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="quote-summary-block">
                        <div className="q-head-customer flex-between">
                          <h3>
                            <span className="svg-animation">
                              <svg
                                version="1.1"
                                id="Layer1"
                                xmlns="https://www.w3.org/2000/svg"
                                xmlnsXlink="https://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 428.16 428.16"
                                style={{
                                  enableBackground: "new 0 0 428.16 428.16",
                                }}
                                xmlSpace="preserve"
                              >
                                <g>
                                  <g>
                                    <path
                                      d="M393.8,110.208c-0.512-11.264-0.512-22.016-0.512-32.768c0-8.704-6.656-15.36-15.36-15.36
 c-64,0-112.64-18.432-153.088-57.856c-6.144-5.632-15.36-5.632-21.504,0C162.888,43.648,114.248,62.08,50.248,62.08
 c-8.704,0-15.36,6.656-15.36,15.36c0,10.752,0,21.504-0.512,32.768c-2.048,107.52-5.12,254.976,174.592,316.928l5.12,1.024
 l5.12-1.024C398.408,365.184,395.848,218.24,393.8,110.208z M201.8,259.2c-3.072,2.56-6.656,4.096-10.752,4.096h-0.512
 c-4.096,0-8.192-2.048-10.752-5.12l-47.616-52.736l23.04-20.48l37.376,41.472l82.944-78.848l20.992,22.528L201.8,259.2z"
                                    />
                                  </g>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                              </svg>
                            </span>
                            Cover Details:{" "}
                          </h3>
                          {/* <div className="edit-icon">
                                  <a href=""> <i className="fas fa-pencil-alt"></i></a>
                                   </div> */}
                        </div>
                        <div className="col-lg-12 border-top pad-20">
                          <div className="row">
                            <div className="col-lg-6">
                              <ul className="plan_cover">
                                {quoteDetails.Features &&
                                  quoteDetails.Features.filter(
                                    (f) => f.IsOptional === "false"
                                  )
                                    .slice(
                                      0,
                                      Math.ceil(
                                        quoteDetails.Features.filter(
                                          (f) => f.IsOptional === "false"
                                        ).length / 2
                                      )
                                    )
                                    .map((feature) => {
                                      return (
                                        <li>
                                          <strong>
                                            {feature.Coveragename}:{" "}
                                            <span>Covered</span>
                                          </strong>
                                          <span>Free</span>
                                        </li>
                                      );
                                    })}
                              </ul>

                              {/* <li><strong>Fire &amp; Theft Cover :  <span>Covered</span></strong> <span>Free</span></li>
                <li><strong>Personal Belongings :  <span>Covered</span></strong> <span>Free</span></li>
                <li><strong>PA Benefit (Driver) :  <span>Upto AED 200,000</span></strong> <span>Free</span></li>
                <li><strong>PA Benefit (Passengers) :  <span>Covered</span></strong> <span>Free</span></li>
                <li><strong>Hire Car Benefit :  <span>Covered</span></strong> <span>Free</span></li>
                 <li><strong>Roadside Assistance :  <span>Covered</span></strong> <span>Free</span></li> */}
                            </div>
                            <div className="col-lg-6">
                              <ul className="plan_cover">
                                {quoteDetails.Features &&
                                  quoteDetails.Features.filter(
                                    (f) => f.IsOptional === "false"
                                  )
                                    .slice(
                                      Math.ceil(
                                        quoteDetails.Features.filter(
                                          (f) => f.IsOptional === "false"
                                        ).length / 2
                                      ),
                                      quoteDetails.Features.length
                                    )
                                    .map((feature) => {
                                      return (
                                        <li>
                                          <strong>
                                            {feature.Coveragename}:{" "}
                                            <span>Covered</span>
                                          </strong>
                                          <span>Free</span>
                                        </li>
                                      );
                                    })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="quote-summary-block">
                        <div className="row">
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingOne"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOne"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  What are the features I have?
                                </button>
                              </h2>

                              <div
                                id="flush-collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <ul className="plan_cover">
                                    {quoteDetails.Features &&
                                      quoteDetails.Features.filter(
                                        (feature) =>
                                          feature.IsOptional === "false"
                                      ).map((feature) => {
                                        return (
                                          <li>
                                            <strong>
                                              {feature.Coveragename}:{" "}
                                              <span>Covered</span>
                                            </strong>
                                            <span>Free</span>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingThree"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseThree"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseThree"
                                >
                                  Personal Information
                                </button>
                              </h2>

                              <div
                                id="flush-collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <p>
                                    <strong>Name :</strong>{" "}
                                    {
                                      props.location.state.personalDetails
                                        .yourName._value
                                    }
                                  </p>
                                  <p>
                                    <strong>Email :</strong>
                                    {
                                      props.location.state.personalDetails.email
                                        ._value
                                    }
                                  </p>
                                  <p>
                                    <strong>Mobile Number :</strong>{" "}
                                    {
                                      props.location.state.personalDetails
                                        .mobileNumber._value
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 ">
                  <div className="full-section">
                    <div className="inner-payment">
                      <h3>Premium details </h3>
                    </div>

                    <div className="payment-box">
                      <ul className="plan-details custom_pay">
                        <li className="p_money">
                          <strong>Premium: </strong> <span>AED {premium}</span>
                        </li>

                        <li className="p_money">
                          <strong>VAT: </strong> <span>AED {vat}</span>
                        </li>
                      </ul>
                      <div className="total-amount">
                        <ul className="p_total">
                          <li>
                            <strong>
                              Payable Amount: <span>(incl VAT)</span>
                            </strong>{" "}
                            <span>AED {netpremium}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="disclaimer-section">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            value="true"
                            name="agree"
                            id="agree"
                            className="form-check-input"
                            onChange={onChange}
                          />
                          {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked"  /> */}
                          <p className="label-checkbox">
                            I agree to provide No Claim Certificate (from
                            Insurer){" "}
                          </p>
                        </div>

                        <p className="disclaimer">
                          By clicking on pay, i agree to the{" "}
                          <a
                            target="_blank"
                            href="https://www.bankbychoice.com/terms-conditions/"
                            rel="noreferrer"
                          >
                            Terms &amp; Conditions
                          </a>{" "}
                          , and confirm that this car is not a commercial
                          vehicle.
                        </p>
                      </div>
                      <div className="promo-code">
                        <input
                          id="promocode"
                          type="text"
                          maxLength="20"
                          placeholder="Promotional Code"
                          className="input-promo"
                        />

                        <input
                          type="button"
                          value="Apply"
                          className="apply-promo"
                        />
                      </div>
                      {/* onClick={payPremium} onClick={fnGetPrePolicyDetails}  fnGetPolicyCertificate   */}
                      <div className="payment-button">
                        <button
                          onClick={payPremium}
                          className="btn btn-primary btn-custom"
                          disabled={disablePaymentBtn}
                        >
                          Pay AED {netpremium}
                        </button>
                      </div>
                      <div className="note-payment">
                        <p>
                          <strong>Note:</strong> Your Policy will be issued
                          instantly and uploaded to RTA/Traffic immediately. You
                          can download the Policy Schedule, Certificate and
                          Policy Wordings immediately
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="page-footer">
          <div className="footer">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-sm-12">
                  <p className="">
                    Copyright 2021 | bankbychoice.com – All rights reserved
                  </p>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <p className=" text-center">
                    Insurance is provided by Gulf Oasis Insurance Brokers LLC
                    Authored by UAE Insurance Authority
                  </p>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <ul className="breadcrumb pt-0 pr-0 float-right">
                    <li className="breadcrumb-item ">
                      <a
                        target="_blank"
                        href="https://www.bankbychoice.com/terms-conditions/"
                        className="btn-link"
                        rel="noreferrer"
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li className="breadcrumb-item ">
                      <a
                        target="_blank"
                        href="https://www.bankbychoice.com/privacy-policy/"
                        className="btn-link"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    {/* <li className="breadcrumb-item mb-0">
                        <a href="#" className="btn-link">Docs</a>
                      </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div>
          <Modal
            fade={false}
            size="lg"
            isOpen={smShowURL}
            toggle={() => setSmShowsURL(false)}
            backdrop="static"
          >
            <ModalBody>
              <div className="row">
                <div className="col-lg-12">
                  {/* {console.log(oasisURL)}
                <iframe width="100%" height="454"  src={oasisURL}  target="_parent"/> */}
                  {paymentStatus === "pending" && (
                    <div className="bk-modal">
                      <h3>Please wait !!</h3>
                      <h2>Payment is in processing....</h2>

                      <div className="modal-circle">
                        <div className="inner-circle">
                          <span id="loading">
                            <svg
                              viewBox="0 0 61 61"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>loading</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <g
                                  id="Artboard"
                                  transform="translate(-295.000000, -180.000000)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                >
                                  <g
                                    id="loading"
                                    transform="translate(295.000000, 180.000000)"
                                  >
                                    <circle
                                      id="Oval"
                                      fill-opacity="0.251183143"
                                      cx="31"
                                      cy="3"
                                      r="3"
                                    ></circle>
                                    <circle
                                      id="Oval"
                                      fill-opacity="0.8"
                                      cx="31"
                                      cy="58"
                                      r="3"
                                    ></circle>
                                    <circle
                                      id="Oval"
                                      fill-opacity="0.297399988"
                                      cx="17"
                                      cy="6"
                                      r="3"
                                    ></circle>
                                    <path
                                      d="M46.6158708,53.4908437 C47.4207542,54.8993896 46.9177021,56.7103771 45.5091563,57.6158708 C44.1006104,58.4207542 42.2896229,57.9177021 41.3841292,56.5091563 C40.5792458,55.1006104 41.0822979,53.2896229 42.4908437,52.3841292 C43.8993896,51.5792458 45.8109875,52.0822979 46.6158708,53.4908437 Z"
                                      id="Path"
                                      fill-opacity="0.99"
                                    ></path>
                                    <path
                                      d="M7.50915625,14.4047557 C8.91770209,15.2066828 9.42075417,17.0110187 8.61587084,18.514632 C7.8109875,19.9180044 6,20.4192088 4.49084375,19.6172817 C3.08229791,18.8153546 2.57924583,17.0110187 3.38412916,15.5074055 C4.1890125,14.0037922 6.10061042,13.6028287 7.50915625,14.4047557 Z"
                                      id="Path"
                                      fill-opacity="0.398237749"
                                    ></path>
                                    <path
                                      d="M56.5091563,41.3841292 C57.9177021,42.1890125 58.4207542,44 57.6158708,45.5091563 C56.8109875,46.9177021 55,47.4207542 53.4908437,46.6158708 C52.0822979,45.8109875 51.5792458,44 52.3841292,42.4908437 C53.1890125,41.0822979 55,40.5792458 56.5091563,41.3841292 Z"
                                      id="Path"
                                      fill-opacity="0.938646437"
                                    ></path>
                                    <circle
                                      id="Oval"
                                      fill-opacity="0.501667156"
                                      cx="3"
                                      cy="31"
                                      r="3"
                                    ></circle>
                                    <circle
                                      id="Oval"
                                      cx="58"
                                      cy="31"
                                      r="3"
                                    ></circle>
                                    <path
                                      d="M4.52418143,41.3841292 C5.92231342,40.5792458 7.7199117,41.0822979 8.61871083,42.4908437 C9.4176434,43.8993896 8.91831055,45.7103771 7.52017856,46.6158708 C6.12204656,47.4207542 4.32444829,46.9177021 3.42564915,45.5091563 C2.52685002,44.1006104 3.12604944,42.2896229 4.52418143,41.3841292 Z"
                                      id="Path"
                                      fill-opacity="0.599874003"
                                    ></path>
                                    <path
                                      d="M53.4908437,14.3841292 C54.8993896,13.5792458 56.7103771,14.0822979 57.6158708,15.4908437 C58.4207542,16.8993896 57.9177021,18.7103771 56.5091563,19.6158708 C55.1006104,20.4207542 53.2896229,19.9177021 52.3841292,18.5091563 C51.5792458,17.1006104 52.0822979,15.1890125 53.4908437,14.3841292 Z"
                                      id="Path"
                                      fill-opacity="0.0986012279"
                                    ></path>
                                    <path
                                      d="M14.3841292,53.4908437 C15.1890125,52.0822979 17,51.5792458 18.5091563,52.3841292 C19.9177021,53.1890125 20.4207542,55 19.6158708,56.5091563 C18.8109875,57.9177021 17,58.4207542 15.4908437,57.6158708 C14.0822979,56.7103771 13.5792458,54.8993896 14.3841292,53.4908437 Z"
                                      id="Path"
                                      fill-opacity="0.7"
                                    ></path>
                                    <path
                                      d="M41.3841292,4.49084375 C42.1890125,3.08229791 44,2.57924583 45.5091563,3.38412916 C46.9177021,4.1890125 47.4207542,6 46.6158708,7.50915625 C45.8109875,8.91770209 44,9.42075417 42.4908437,8.61587084 C41.0822979,7.8109875 40.5792458,6 41.3841292,4.49084375 Z"
                                      id="Path"
                                      fill-opacity="0.2"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>

                      <div className="attempt-section">
                        <h3>
                          <span>{counter}/10</span> attempts
                        </h3>
                      </div>
                    </div>
                  )}
                  {paymentStatus === "Success" && (
                    <div className="bk-modal">
                      <h3 className="success-txt">Payment Success !!</h3>
                      <h2 className="txt-black">
                        Your Ref no: {transactionRefNo}
                      </h2>

                      <br />
                      <h4>{paymentErrMsgDesc}</h4>
                      <div className="modal-circle">
                        <div className="inner-circle">
                          <span>
                            <svg
                              viewBox="0 0 46 34"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>tick</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <g
                                  id="Artboard-Copy"
                                  transform="translate(-302.000000, -193.000000)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                >
                                  <g
                                    id="tick"
                                    transform="translate(302.000000, 193.000000)"
                                  >
                                    <path
                                      d="M45.3263412,0.678049314 C44.4281743,-0.226016438 42.9719886,-0.226016438 42.073642,0.678049314 L14.5182381,28.4119255 L3.92639169,17.7515815 C3.02822482,16.8475158 1.5720391,16.8476062 0.673692537,17.7515815 C-0.224564179,18.6555568 -0.224564179,20.1211597 0.673692537,21.0252255 L12.8918886,33.3222558 C13.7897859,34.2262312 15.2470497,34.2255982 16.1445877,33.3222558 L45.3263412,3.95178371 C46.2245979,3.04780838 46.224508,1.58211507 45.3263412,0.678049314 Z"
                                      id="Path"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>

                      <div className="bottom-btn-section">
                        <a
                          id="aDownloadPolicy"
                          href="#"
                          className="btn btn-branding"
                          download="PolicyCertificate.pdf"
                        >
                          Download your Policy
                        </a>
                        <button
                          className="btn  btn-branding-outline"
                          onClick={() => setSmShowURL(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}

                  {paymentStatus === "Fail" && (
                    <div className="bk-modal">
                      <h3 className="red-txt">Payment failed !!</h3>
                      <h2 className="txt-black">
                        Your Ref no: {transactionRefNo}
                      </h2>

                      <div className="modal-circle">
                        <div className="inner-circle bk-red">
                          <span>
                            <svg
                              viewBox="0 0 49 49"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>cancel</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <g
                                  id="Artboard-Copy-2"
                                  transform="translate(-300.000000, -185.000000)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                >
                                  <g
                                    id="cancel"
                                    transform="translate(300.000000, 185.000000)"
                                  >
                                    <path
                                      d="M27.2070028,24.5001077 L48.4393488,3.26761413 C49.1868837,2.52007738 49.1868837,1.3080936 48.4393488,0.560652558 C47.6918139,-0.186788483 46.4798331,-0.186884186 45.7323939,0.560652558 L24.4999521,21.7931461 L3.26760615,0.560652558 C2.52007123,-0.186884186 1.30809041,-0.186884186 0.560651189,0.560652558 C-0.186788027,1.3081893 -0.18688373,2.52017309 0.560651189,3.26761413 L21.7929972,24.500012 L0.560651189,45.7325055 C-0.18688373,46.4800422 -0.18688373,47.692026 0.560651189,48.4394671 C0.934370798,48.8131876 1.42427366,49 1.91417652,49 C2.40407938,49 2.89388654,48.8131876 3.26770185,48.4394671 L24.4999521,27.2070692 L45.7322981,48.4394671 C46.1060178,48.8131876 46.5959206,49 47.0858235,49 C47.5757263,49 48.0655335,48.8131876 48.4393488,48.4394671 C49.1868837,47.6919303 49.1868837,46.4799465 48.4393488,45.7325055 L27.2070028,24.5001077 Z"
                                      id="Path"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>

                      <div className="bottom-btn-section">
                        <button
                          className="btn  btn-branding-outline"
                          onClick={() => setSmShowURL(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}

                  {paymentStatus === "Error" && (
                    <div className="bk-modal">
                      <h3 className="red-txt">Sorry!! {paymentErrMsg}</h3>

                      <br />
                      <h4>
                        {paymentErrMsgDesc}
                        Your personal advisor will be in touch with you shortly.
                      </h4>

                      <div className="bottom-btn-section">
                        <button
                          className="btn  btn-branding-outline"
                          onClick={() => setSmShowURL(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                  {paymentStatus === "Agent" && (
                    //Show Agent details
                    <div className="bk-modal">
                      <h3 className="success-txt">Payment Success !!</h3>
                      <h2 className="txt-black">
                        Your Ref no: {transactionRefNo}
                      </h2>
                      <br />
                      <h4>
                        Your personal advisor will be in touch with you shortly.
                      </h4>
                      <div className="bottom-btn-section">
                        <button
                          onClick={paymentDone}
                          className="btn  btn-branding-outline"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <div>
          <Modal
            fade={false}
            size="sm"
            isOpen={smShow}
            toggle={() => setSmShows(false)}
          >
            <ModalBody>
              <div>
                {" "}
                <Label>
                  {
                    "Please agree to provide No Claim Certificate (from Insurer)"
                  }
                </Label>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button color="secondary" onClick={() => setSmShows(false)}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>{" "}
    </div>
  );
}

export default Qutation;
