import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import validator from "validator";
import Select from "react-select";
import ReactInputDateMask from "react-input-date-mask";
import { Collapse } from "react-collapse";
import Features from "../components/features";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import NumberFormat from "react-number-format";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { utils } from "react-modern-calendar-datepicker";
import DatePicker from "react-modern-calendar-datepicker";
import DropzoneComponent from "react-dropzone-component";
import "dropzone/dist/min/dropzone.min.css";
import { Label, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { api, https, apiLocal } from "../apiConfig/apiurl.js";

const ReactDOMServer = require("react-dom/server");
class InsuranceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateActualQuote: 0,
      selectedOptionMake: {
        value: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber.make
              ._value
          : "",
        label: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber.make
              ._value
          : "",
      },
      selectedOptionModel: {
        value: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber.model
              ._value
          : "",
        label: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber.model
              ._value
          : "",
      },
      selectedOptionYear: {
        value: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .modelYear._value
          : "",
        label: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .modelYear._value
          : "",
      },
      selectedOptionTrim: {
        value: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .trimValue._value
          : "",
        label: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .trimValue._value
          : "",
      },
      selectedOptionEngineSize: {
        value: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .engineSize._value
          : "",
        label: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .engineSize._value
          : "",
      },
      selectedOptionBodyType: {
        value: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .bodyType._value
          : "",
        label: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .bodyType._value
          : "",
      },
      selectedOptionEmirates: {
        value: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .dLEmirate._value
          : "",
        label: props.location.state
          ? props.location.state.vehicleRegistrationWithoutChassisNumber
              .dLEmirate._value
          : "",
      },
      api: api,
      https: https,
      apiLocal: apiLocal,
      setinsuranceLogo: "",
      showSlide: true,
      disableEmiratesbtn: true,
      disableMulkiyabtn: true,
      disableLicencebtn: true,
      responseMsg: "",
      nationality: [],
      imgMulkiyaF: "",
      imgMulkiyaB: "",
      imgLicenceF: "",
      imgLicenceB: "",
      imgEmiratesF: "",
      imgEmiratesB: "",
      smShow: false,
      closePopup1: "",
      closePopup2: "",
      closePopup3: "",
      imgSrc: "",
      imgFile: "",
      showImgCapture: false,
      imgSrcBack: "",
      imgFileBack: "",
      showImgCaptureBack: false,
      imgSrcLicenceF: "",
      imgFileLicenceF: "",
      showImgCaptureLicenceF: false,
      imgSrcLicenceB: "",
      imgFileLicenceB: "",
      showImgCaptureLicenceB: false,
      imgSrcEmirateF: "",
      imgFileEmirateF: "",
      showImgCaptureEmirateF: false,
      imgSrcEmirateB: "",
      imgFileEmirateB: "",
      showImgCaptureEmirateB: false,

      vehicleDetailsIDs: "",
      configImage: "",
      configImageB: "",
      configImageLicenceF: "",
      configImageLicenceB: "",
      configImageEmiratesF: "",
      configImageEmiratesB: "",
      modelSearch: [],
      makeSearch: [],
      yearMaster: [
        {
          value: 1,
          label: "2021",
        },
        { value: 2, label: "2020" },
        { value: 3, label: "2019" },
        { value: 4, label: "2018" },
        { value: 5, label: "2017" },
        { value: 6, label: "2016" },
        { value: 7, label: "2015" },
        { value: 8, label: "2014" },
        { value: 9, label: "2013" },
        {
          value: 10,
          label: "2012",
        },
      ],
      emirates: [
        { value: 1, label: "Abudhabi" },
        { value: 2, label: "Ajman" },
        { value: 3, label: "Dubai" },
        { value: 4, label: "Sharjah" },
        { value: 5, label: "Fujairah" },
        { value: 6, label: "Al Ain" },
        {
          value: 7,
          label: "Ras Al Khaimah",
        },
      ],
      emiratesMaster: [
        { id: 1, emirates: "Abudhabi" },
        { id: 2, emirates: "Ajman" },
        { id: 3, emirates: "Dubai" },
        { id: 4, emirates: "Sharjah" },
        { id: 5, emirates: "Fujairah" },
        { id: 6, emirates: "Al Ain" },
        {
          id: 7,
          emirates: "Ras Al Khaimah",
        },
      ],
      gender: [
        { id: 1, gender: "Male" },
        { id: 2, gender: "Female" },
      ],
      displayPersonal: false,
      displayCarDetail: false,
      displayCarValue: false,
      displayPersonals: "none",
      displayCarDetails: "none",
      displayCarValues: "none",

      // Benny Declaration
      displayAgencys: "none",
      displayAgency: false,
      displaySorts: "none",
      displaySort: false,
      selectedOption: [],
      selectedPremiumSortOption: "",
      tempQuote: [],
      uniqueInsuranceName: [],
      insuranceFilterList: [],
      repairFilterList: [],
      // Benny Declaration End

      submitVehicleRegistrationWithoutChassisNumberDetails: false,

      makeMaster: [],
      trimMaster: [],
      modelMake: [],
      bodyTypeMaster: [],
      engineSizeMaster: [],
      closePopup: "",
      collapse: false,
      loader: false,
      submitfileDetailsMulkiya: false,
      vehicleDetailsID: "",
      submitDocumentDetailsMulkiya: false,
      submitDocumentDetailsEmiratesID: false,
      submitDocumentDetailsLicence: false,
      vehicleId: props.location.state ? props.location.state.vehicleId : "",
      CustomerId: props.location.state ? props.location.CustomerId : "",
      // vehicleRegistrationWithoutChassisNumber: props.location.state ? props.location.state.vehicleRegistrationWithoutChassisNumber : '',
      vehicleDetails: {
        vehicleId: {
          _value: props.location.state
            ? props.location.state.vehicleDetails.vehicleId._value
            : "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "Plate Code is required",
          errorMessage: "",
        },
        vehicleDetailsID: {
          _value: 0,
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
          errorMessage: "",
        },
        plateCode: {
          _value: props.location.state
            ? props.location.state.vehicleDetails.plateCode._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Plate Code  is required",
          errorMessage: "",
        },
        vehiclecol: {
          _value: props.location.state
            ? props.location.state.vehicleDetails.vehiclecol._value
            : "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
          errorMessage: "",
        },
        isActive: {
          _value: true,
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
          errorMessage: "",
        },
        chassisNo: {
          _value: props.location.state
            ? props.location.state.vehicleDetails.chassisNo._value
            : "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
          errorMessage: "",
        },
        plateNumber: {
          _value: props.location.state
            ? props.location.state.vehicleDetails.plateNumber._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Plate Number is required",
          errorMessage: "",
        },
      },
      personalDetails: {
        email: {
          _value: props.location.state
            ? props.location.state.personalDetails.email._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "E-mail is required",
          errorMessage: "",
        },
        yourName: {
          _value: props.location.state
            ? props.location.state.personalDetails.yourName._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Your Name is required",
          errorMessage: "",
        },
        mobileNumber: {
          _value: props.location.state
            ? props.location.state.personalDetails.mobileNumber._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Mobile Number is required",
          errorMessage: "",
        },
      },
      vehicleRegistrationWithoutChassisNumber: {
        make: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber.make
                ._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Make is required",
          errorMessage: "",
        },
        model: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber.model
                ._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Model is required",
          errorMessage: "",
        },
        modelYear: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .modelYear._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Model is required",
          errorMessage: "",
        },
        trimValue: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .trimValue._value
            : "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "Trim is required",
          errorMessage: "",
        },
        bodyType: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .bodyType._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Car Variant is required",
          errorMessage: "",
        },
        dateOfRegistration: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .dateOfRegistration._value
            : "",
          _values: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .dateOfRegistration._values
            : "",
          touched: true,
          required: true,
          error: "",
          errorMsg: "",
          errorMessage: "",
        },
        dateOfRegistrationIns: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .dateOfRegistrationIns._value
            : "",
          _values: "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "Year of manufacture is required",
          errorMessage: "",
        },
        engineSize: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .engineSize._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "Engine Size is required",
          errorMessage: "",
        },
        noClaimYear: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .noClaimYear._value
            : "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        gcc: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber.gcc
                ._value
            : "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        plateCode: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .plateCode._value
            : "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        plateNumber: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .plateNumber._value
            : "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        lOCRegion: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .lOCRegion._value
            : "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        policyStartDate: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .policyStartDate._value
            : "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        userName: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .userName._value
            : "",
          touched: true,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        vehicleValue: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .vehicleValue._value
            : 0,
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        vehicleMinValue: {
          _value: 20000,
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        vehicleMaxValue: {
          _value: 1000000,
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },

        vehicleTxtPrice: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .vehicleTxtPrice._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        vehiclePrice: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .vehiclePrice._value
            : 0,
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        dLIssuedDate: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .dLIssuedDate._value
            : "",
          _values: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .dLIssuedDate._values
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "",
          errorMessage: "",
        },
        dLIssuedDateIns: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .dLIssuedDateIns._value
            : "",
          _values: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
        dLEmirate: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .dLEmirate._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "OTP is required",
          errorMessage: "",
        },
      },

      currentStep: 1,
      location: props.location.state,

      visible: true,
      quoteList: props.location.state ? props.location.state.quoteList : "",
      largeWidthSidePanel: "",
      showBackground: "",
      documentDetailsMulkiya: {
        documentMulkiyaF: {
          _value1: "",
          touched1: false,
          required: true,
          error: "",
          errorMsg: "Document Mulkiya Front is required",
          errorMessage: "",
        },
        documentMulkiyaB: {
          _value2: "",
          touched2: false,
          required: true,
          error: "",
          errorMsg: "Document Mulkiya Back is required",
          errorMessage: "",
        },
      },
      documentDetailsLicence: {
        documentLicenceF: {
          _value1: "",
          touched1: false,
          required: true,
          error: "",
          errorMsg: "Document Licence Front is required",
          errorMessage: "",
        },
        documentLicenceB: {
          _value2: "",
          touched2: false,
          required: true,
          error: "",
          errorMsg: "Document Licence Back is required",
          errorMessage: "",
        },
      },
      documentDetailsEmiratesID: {
        documentEmiratesIDF: {
          _value1: "",
          touched1: false,
          required: true,
          error: "",
          errorMsg: "Document EmiratesID Front is required",
          errorMessage: "",
        },
        documentEmiratesIDB: {
          _value2: "",
          touched2: false,
          required: true,
          error: "",
          errorMsg: "Document EmiratesID Back is required",
          errorMessage: "",
        },
      },
      fileDetailsMulkiya: {
        documentDetailsID: {
          _value: "",
          touched: false,
          required: false,
          error: "",
          errorMsg: "This field is required.",
        },
        plateCategory: {
          _value: props.location.state
            ? props.location.state.vehicleDetails.plateNumber._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        plateNo: {
          _value: props.location.state
            ? props.location.state.vehicleDetails.plateCode._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        regDate: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .dateOfRegistration._value
            : "",
          _values: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        expDate: {
          _value: "",
          _values: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        insExp: {
          _value: "",
          _values: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        chassisNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        engineNumber: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        trim: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .trimValue._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        cylinder: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        engineSize: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .engineSize._value
            : "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
      },

      fileDetailsLicence: {
        licenceNo: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        placeofIssue: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        issueDate: {
          _value: props.location.state
            ? props.location.state.vehicleRegistrationWithoutChassisNumber
                .dLIssuedDate._value
            : "",
          _values: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        expiryDate: {
          _value: "",
          _values: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        TCNo: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
      },

      fileDetailsEmiratesID: {
        nationality: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        emiratesID: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMessage: "",
          errorMsg: "This field is required.",
        },
        expiryDate: {
          _value: "",
          _values: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        dateofBirth: {
          _value: "",
          _values: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        gender: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
      },
    };
    this.collapse = this.collapse.bind(this);
    this.collapseQuote = this.collapseQuote.bind(this);
    this._next = this._next.bind(this);
    this.handledocumentMulkiyaFChange =
      this.handledocumentMulkiyaFChange.bind(this);
    this.handledocumentMulkiyaBChange =
      this.handledocumentMulkiyaBChange.bind(this);
    this.handledocumentLicenceFChange =
      this.handledocumentLicenceFChange.bind(this);
    this.handledocumentLicenceBChange =
      this.handledocumentLicenceBChange.bind(this);
    this.handledocumentEmiratesIDFChange =
      this.handledocumentEmiratesIDFChange.bind(this);
    this.handledocumentEmiratesIDBChange =
      this.handledocumentEmiratesIDBChange.bind(this);
    this.onChangeEmiratesBirthDate = this.onChangeEmiratesBirthDate.bind(this);
    this.onChangeEmiratesExpDate = this.onChangeEmiratesExpDate.bind(this);
    this.handleChangeNationality = this.handleChangeNationality.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.handlefileDetailEmiratesIDChange =
      this.handlefileDetailEmiratesIDChange.bind(this);
    this.handlefileDetailMulkiyaChange =
      this.handlefileDetailMulkiyaChange.bind(this);
    this.handledocumentDetailsValidationMulkiya =
      this.handledocumentDetailsValidationMulkiya.bind(this);
    this.handledocumentDetailsValidationLicence =
      this.handledocumentDetailsValidationLicence.bind(this);
    this.handledocumentDetailsValidationEmiratesID =
      this.handledocumentDetailsValidationEmiratesID.bind(this);
    this.handlefileDetailMulkiyaRegDateChange =
      this.handlefileDetailMulkiyaRegDateChange.bind(this);
    this.handlefileDetailMulkiyaInsDateChange =
      this.handlefileDetailMulkiyaInsDateChange.bind(this);
    this.handlefileDetailMulkiyaExpDateChange =
      this.handlefileDetailMulkiyaExpDateChange.bind(this);
    this.handlefileDetailMulkiyaInsExpChange =
      this.handlefileDetailMulkiyaInsExpChange.bind(this);
    this.handleVehicleRegistrationWithoutChassisNumberDetailsValidations =
      this.handleVehicleRegistrationWithoutChassisNumberDetailsValidations.bind(
        this
      );
    // this.handlefileDetailLicenceChange = this.handlefileDetailLicenceChange.bind(this);
    this.onChangeLicenceIssueDate = this.onChangeLicenceIssueDate.bind(this);
    this.onChangeLicenceExpDate = this.onChangeLicenceExpDate.bind(this);
    this.onChangeEmirates = this.onChangeEmirates.bind(this);
    this.handlefileDetailLicenceChange =
      this.handlefileDetailLicenceChange.bind(this);
    this.handlefileDetailsValidationsMulkiya =
      this.handlefileDetailsValidationsMulkiya.bind(this);
    this.handlefileDetailsValidationsLicence =
      this.handlefileDetailsValidationsLicence.bind(this);
    this.handlefileDetailsValidationsEmiratesID =
      this.handlefileDetailsValidationsEmiratesID.bind(this);
    this.handleDocumentDetailsValidationsMulkiya =
      this.handleDocumentDetailsValidationsMulkiya.bind(this);
    this.handleDocumentDetailsValidationsLicence =
      this.handleDocumentDetailsValidationsLicence.bind(this);
    this.handleDocumentDetailsValidationsEmiratesID =
      this.handleDocumentDetailsValidationsEmiratesID.bind(this);
    this.uploadEmiratesID = this.uploadEmiratesID.bind(this);
    this.uploadMulkiya = this.uploadMulkiya.bind(this);
    this.uploadLicence = this.uploadLicence.bind(this);
    this.resetDocumentsMulkiya = this.resetDocumentsMulkiya.bind(this);
    this.resetDocumentsLicence = this.resetDocumentsLicence.bind(this);
    this.resetDocumentsEmiratesID = this.resetDocumentsEmiratesID.bind(this);
    this.removeMulkiyaF = this.removeMulkiyaF.bind(this);
    this.removeMulkiyaB = this.removeMulkiyaB.bind(this);
    this.removeLicenceF = this.removeLicenceF.bind(this);
    this.removeLicenceB = this.removeLicenceB.bind(this);
    this.removeEmiratesIDF = this.removeEmiratesIDF.bind(this);
    this.removeEmiratesIDB = this.removeEmiratesIDB.bind(this);
    this.getImageMulkiyaF = this.getImageMulkiyaF.bind(this);
    this.getImageMulkiyaB = this.getImageMulkiyaB.bind(this);
    this.getImageLicenceF = this.getImageLicenceF.bind(this);
    this.getImageLicenceB = this.getImageLicenceB.bind(this);
    this.getImageEmiratesIDF = this.getImageEmiratesIDF.bind(this);
    this.getImageEmiratesIDB = this.getImageEmiratesIDB.bind(this);
    this.displayCarValues = this.displayCarValues.bind(this);

    // Benny Declarations
    this.displayAgency = this.displayAgency.bind(this);
    this.displaySortFilter = this.displaySortFilter.bind(this);

    this.submitAgency = this.submitAgency.bind(this);
    this.radioreset = this.radioreset.bind(this);
    this.onRadioValueChange = this.onRadioValueChange.bind(this);
    this.setTempQuote = this.setTempQuote.bind(this);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleRepairCheckboxChange =
      this.handleRepairCheckboxChange.bind(this);

    this.sortFilter = this.sortFilter.bind(this);
    this.onSortRadioValueChange = this.onSortRadioValueChange.bind(this);
    this.resetsortFilter = this.resetsortFilter.bind(this);

    // End Benny Declarations
    this.displayCarDetails = this.displayCarDetails.bind(this);
    this.displayPersonal = this.displayPersonal.bind(this);
    this.handlePersonalDetailChange =
      this.handlePersonalDetailChange.bind(this);
    this.handleVehicleDetailChange = this.handleVehicleDetailChange.bind(this);
    this.onChangeMake = this.onChangeMake.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
    this.onChangeBodyType = this.onChangeBodyType.bind(this);
    this.onChangeEmirate = this.onChangeEmirate.bind(this);
    this.onChangeTrim = this.onChangeTrim.bind(this);
    this.onChangeEngineSize = this.onChangeEngineSize.bind(this);
    this.submitCarValueDetails = this.submitCarValueDetails.bind(this);
    this.onChangeRegistrationDate = this.onChangeRegistrationDate.bind(this);
    this.onChangeDLIssueDate = this.onChangeDLIssueDate.bind(this);
    this.getMakeApi = this.getMakeApi.bind(this);
    this.getEngineSizeApi = this.getEngineSizeApi.bind(this);
    this.getModelMakeApi = this.getModelMakeApi.bind(this);
    this.getBodyTypeApi = this.getBodyTypeApi.bind(this);
    this.getTrimApi = this.getTrimApi.bind(this);
    this.setSmShow = this.setSmShow.bind(this);
    this.onChangeYear = this.onChangeYear.bind(this);
    this.submitCarDetails = this.submitCarDetails.bind(this);
    this.submitPersonalDetails = this.submitPersonalDetails.bind(this);
    this.onVehicleTxtPriceChange = this.onVehicleTxtPriceChange.bind(this);
    this.handleVehicleDetailsValidations =
      this.handleVehicleDetailsValidations.bind(this);
    this.handlePersonalDetailsValidations =
      this.handlePersonalDetailsValidations.bind(this);
    this.getCustomerInfo = this.getCustomerInfo.bind(this);
    this.getChangeModelMakeApi = this.getChangeModelMakeApi.bind(this);
  }

  componentDidMount() {
    //Benny component
    //http://localhost:3001/insurancelist?customerId=12
    //console.log('location',this.props.location.search.substring(12))
    //console.log("insurance list ", this.props);
    if (this.props.location.search) {
      this.getCustomerInfo();
    }
    this.setState({ tempQuote: this.state.quoteList });
    //console.log(" componentDidMount");
    let insuranceNames = [];

    insuranceNames = this.state.quoteList
      ? this.state.quoteList
          .map((a) => a.InsName)
          .filter((x, i, a) => a.indexOf(x) === i)
      : [];

    this.setState({ uniqueInsuranceName: insuranceNames });

    this.getNationalityApi();
    this.getMakeApi();
    console.log(
      "test",
      this.state.documentDetailsMulkiya.documentMulkiyaF._value1
    );

    axios
      .get(this.state.api + `Master/GetInsuranceCompany`)
      .then((response) => {
        this.setState({
          setinsuranceLogo: response.data,
        });
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.setState({ loader: false, smShow: true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          this.setState({ loader: false, smShow: true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          this.setState({ loader: false, smShow: true });
        }
      });
  }

  getCustomerInfo() {
    axios
      .get(
        this.state.api +
          `Admin/GetCustomer?customerId=${this.props.location.search.substring(
            12
          )}`
      )
      .then((response) => {
        if (response.data.length) {
          this.state.selectedOptionMake.label = response.data[0].make;
          this.state.selectedOptionMake.value = response.data[0].make;
          this.state.selectedOptionModel.label = response.data[0].model;
          this.state.selectedOptionModel.value = response.data[0].model;
          this.state.selectedOptionYear.label = response.data[0].modelYear;
          this.state.selectedOptionYear.value = response.data[0].modelYear;
          this.state.selectedOptionTrim.label = response.data[0].trimValue;
          this.state.selectedOptionTrim.value = response.data[0].trimValue;
          this.state.selectedOptionEngineSize.value =
            response.data[0].engineSize;
          this.state.selectedOptionEngineSize.label =
            response.data[0].engineSize;
          this.state.selectedOptionBodyType.value = response.data[0].bodyType;
          this.state.selectedOptionBodyType.label = response.data[0].bodyType;
          this.state.selectedOptionEmirates.value = response.data[0].dLEmirate;
          this.state.selectedOptionEmirates.label = response.data[0].dLEmirate;
          this.state.vehicleRegistrationWithoutChassisNumber.make._value =
            response.data[0].make;
          this.state.vehicleRegistrationWithoutChassisNumber.make.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.modelYear._value =
            response.data[0].modelYear;
          this.state.vehicleRegistrationWithoutChassisNumber.modelYear.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.model._value =
            response.data[0].model;
          this.state.vehicleRegistrationWithoutChassisNumber.model.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.trimValue._value =
            response.data[0].trimValue;
          this.state.vehicleRegistrationWithoutChassisNumber.trimValue.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.bodyType._value =
            response.data[0].bodyType;
          this.state.vehicleRegistrationWithoutChassisNumber.bodyType.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.engineSize._value =
            response.data[0].engineSize;
          this.state.vehicleRegistrationWithoutChassisNumber.engineSize.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.dLEmirate._value =
            response.data[0].dLEmirate;
          this.state.vehicleRegistrationWithoutChassisNumber.dLEmirate.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.dateOfRegistration._values =
            response.data[0].dateOfRegistration;
          this.state.vehicleRegistrationWithoutChassisNumber.dateOfRegistration._value =
            response.data[0].dateOfRegistration;
          this.state.vehicleRegistrationWithoutChassisNumber.dateOfRegistration.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.dLIssuedDate._values =
            response.data[0].dLIssuedDate;
          this.state.vehicleRegistrationWithoutChassisNumber.dLIssuedDate._value =
            response.data[0].dLIssuedDate;
          this.state.vehicleRegistrationWithoutChassisNumber.dLIssuedDate.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.vehicleTxtPrice._value =
            response.data[0].vehiclePrice;
          this.state.vehicleRegistrationWithoutChassisNumber.vehicleTxtPrice.touched = true;
          this.state.vehicleRegistrationWithoutChassisNumber.vehicleValue._value =
            response.data[0].vehicleValue;
          this.state.vehicleRegistrationWithoutChassisNumber.vehicleValue.touched = true;
          this.state.vehicleDetails.plateCode._value =
            response.data[0].plateCode;
          this.state.vehicleRegistrationWithoutChassisNumber.make.touched = true;
          this.state.vehicleDetails.plateNumber._value =
            response.data[0].plateNumber.toString();
          this.state.vehicleRegistrationWithoutChassisNumber.make.touched = true;
          this.state.personalDetails.yourName._value =
            response.data[0].yourName;
          this.state.vehicleRegistrationWithoutChassisNumber.make.touched = true;
          this.state.personalDetails.email._value = response.data[0].email;
          this.state.vehicleRegistrationWithoutChassisNumber.make.touched = true;
          this.state.personalDetails.mobileNumber._value =
            response.data[0].mobileNumber;
          this.getMakeApi();
          this.getModelMakeApi(this.state.selectedOptionMake.label);
          this.getTrimApi(
            this.state.selectedOptionMake.label,
            this.state.selectedOptionModel.label,
            this.state.selectedOptionYear.value
          );
          this.getBodyTypeApi();
          this.getEngineSizeApi();
        }
      });
  }
  handleVehicleDetailsValidations() {
    let error = false;

    let vehicle = this.state.vehicleDetails;
    for (var i in vehicle) {
      if (
        (vehicle[i].required && !vehicle[i]._value) ||
        vehicle[i].errorMessage !== ""
      ) {
        error = true;
        ////console.log('validations', error, vehicle[i].required, vehicle[i]._value, vehicle[i].errorMessage)
      }
      if (error) return error;
    }
    return error;
  }
  handleVehicleRegistrationWithoutChassisNumberDetailsValidations() {
    let error = false;

    let vehicleRegistrationWithoutChassis =
      this.state.vehicleRegistrationWithoutChassisNumber;
    for (var i in vehicleRegistrationWithoutChassis) {
      if (
        vehicleRegistrationWithoutChassis[i].required &&
        (!vehicleRegistrationWithoutChassis[i]._value ||
          vehicleRegistrationWithoutChassis[i].errorMessage !== "")
      ) {
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  onVehicleTxtPriceChange(event) {
    let { value } = event.target;
    console.log(value);

    let vehicleRegistrationWithoutChassisNumberVehicleValue = Object.assign(
      {},
      this.state.vehicleRegistrationWithoutChassisNumber
    );

    vehicleRegistrationWithoutChassisNumberVehicleValue["vehiclePrice"]._value =
      parseInt(value ? value : "0");
    vehicleRegistrationWithoutChassisNumberVehicleValue[
      "vehicleTxtPrice"
    ]._value = value;
    let currentYear = new Date();
    let years =
      currentYear.getFullYear() -
      vehicleRegistrationWithoutChassisNumberVehicleValue["modelYear"]._value;
    let vehicleActualPrice =
      vehicleRegistrationWithoutChassisNumberVehicleValue["vehiclePrice"]
        ._value;

    for (let i = 0; i < years; i++) {
      vehicleActualPrice = vehicleActualPrice - (vehicleActualPrice * 15) / 100;
    }
    vehicleActualPrice = Number(vehicleActualPrice).toFixed(0);
    vehicleRegistrationWithoutChassisNumberVehicleValue["vehicleValue"]._value =
      vehicleActualPrice;
    let vehicleMinValue = vehicleActualPrice - (vehicleActualPrice * 5) / 100;
    vehicleMinValue = Number(vehicleMinValue).toFixed(0);
    vehicleRegistrationWithoutChassisNumberVehicleValue[
      "vehicleMinValue"
    ]._value = Number(vehicleMinValue);
    let maxPrice = (vehicleActualPrice * 5) / 100;
    let vehicleMaxValue = Number(vehicleActualPrice) + Number(maxPrice);
    vehicleMaxValue = Number(vehicleMaxValue).toFixed(0);
    vehicleRegistrationWithoutChassisNumberVehicleValue[
      "vehicleMaxValue"
    ]._value = Number(vehicleMaxValue);

    this.setState((state) => ({
      vehicleRegistrationWithoutChassisNumber:
        vehicleRegistrationWithoutChassisNumberVehicleValue,
    }));
    ////console.log(this.state.vehicleRegistrationWithoutChassisNumber)
    return vehicleRegistrationWithoutChassisNumberVehicleValue;
  }
  setSmShow() {
    this.setState((prevState) => {
      let smShow = Object.assign({}, prevState.smShow);
      smShow = !prevState.smShow;
      return { smShow };
    });
  }
  submitCarDetails() {
    this.setState({
      submitVehicleRegistrationWithoutChassisNumberDetails: true,
    });
    this.state.submitVehicleRegistrationWithoutChassisNumberDetails = true;
    if (
      this.handleVehicleRegistrationWithoutChassisNumberDetailsValidations()
    ) {
      return;
    }
    this.setState({
      loader: true,
      displayCarDetails: "none",
      displayCarDetail: false,
    });
    //Benny Filter Reset
    this.setState({
      selectedPremiumSortOption: "",
      insuranceFilterList: [],
      repairFilterList: [],
    });
    //Benny Filter Reset End

    let post = {
      headers: new Headers({
        Accept: "text/plain;charset=UTF-8",
        "Content-Type": "text/plain;charset=UTF-8",
        "Access-Control-Allow-Origin": this.state.https,
        Authorization: `Bearer ${localStorage.getItem("user")}`,
        // <-- Specifying the Content-Type
      }),
      mode: "no-cors",
      data: {
        plateCode: this.state.vehicleDetails.plateCode._value,
        plateNumber: this.state.vehicleDetails.plateNumber._value,
        vehicleId: this.state.vehicleDetails.vehicleId._value,
        vehiclecol: this.state.vehicleDetails.vehiclecol._value,
        vehicleDetailsID: this.state.vehicleDetails.vehicleDetailsID._value,
        chassisNo: this.state.vehicleDetails.chassisNo._value,
        isActive: this.state.vehicleDetails.isActive._value,
      },
    };
    axios
      .post(this.state.api + `Vehicle/GetVehicleRegistrationStatus`, post.data)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.Status === "Success" &&
          response.data.IsVehicleRegistered === true
        ) {
          // this.state.currentStep = 3;
          let post = {
            headers: new Headers({
              Accept: "text/plain;charset=UTF-8",
              "Content-Type": "text/plain;charset=UTF-8",
              "Access-Control-Allow-Origin": this.state.https,
              Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
            mode: "no-cors",
            data: {
              Make: this.state.vehicleRegistrationWithoutChassisNumber.make
                ._value,
              Model:
                this.state.vehicleRegistrationWithoutChassisNumber.model._value,
              ModelYear:
                this.state.vehicleRegistrationWithoutChassisNumber.modelYear
                  ._value,
              // "trimValue": 'GLS',
              trimValue:
                this.state.vehicleRegistrationWithoutChassisNumber.trimValue
                  ._value === ""
                  ? "GLS"
                  : this.state.vehicleRegistrationWithoutChassisNumber.trimValue
                      ._value,
              BodyType:
                this.state.vehicleRegistrationWithoutChassisNumber.bodyType
                  ._value,
              DateOfRegistration: this.DBDateOfRegistrationFormat(
                this.state.vehicleRegistrationWithoutChassisNumber
                  .dateOfRegistration._values
              ),
              //   "DateOfRegistration": this.state.vehicleRegistrationWithoutChassisNumber.dateOfRegistration._value,
              // "NoClaimYear":      this.state.vehicleRegistrationWithoutChassisNumber.noClaimYear._value,
              NoClaimYear: "2",
              PlateCode: this.state.vehicleDetails.plateCode._value,
              PlateNumber: this.state.vehicleDetails.plateNumber._value,
              LOCRegion:
                this.state.vehicleRegistrationWithoutChassisNumber.dLEmirate
                  ._value,
              EngineSize:
                this.state.vehicleRegistrationWithoutChassisNumber.engineSize
                  ._value,
              PolicyStartDate: this.DBDateOfRegistrationFormat(
                this.state.vehicleRegistrationWithoutChassisNumber
                  .dateOfRegistration._values
              ),
              UserName: response.data.UserName,
              // "VehicleValue": '100000',
              VehicleValue:
                this.state.vehicleRegistrationWithoutChassisNumber.vehiclePrice._value.toString(),
              DLIssuedDate: this.DBDateOfRegistrationFormat(
                this.state.vehicleRegistrationWithoutChassisNumber.dLIssuedDate
                  ._values
              ),
              DLEmirate:
                this.state.vehicleRegistrationWithoutChassisNumber.dLEmirate
                  ._value,
              // "DLEmirate": 'Dubai'
            },
          };
          this.state.responseMsg = "";

          axios
            .post(
              this.state.api + `Vehicle/VehicleRegistrationWithoutChassisNo`,
              post.data
            )
            .then((response) => {
              if (response) {
                let post = {
                  headers: new Headers({
                    Accept: "text/plain;charset=UTF-8",
                    "Content-Type": "text/plain;charset=UTF-8",
                    "Access-Control-Allow-Origin": this.state.https,
                    Authorization: `Bearer ${localStorage.getItem("user")}`,
                  }),
                  mode: "no-cors",
                  data: {
                    vehicleDetailsID: response.data.Message,
                  },
                };
                if (
                  response.data.Message !== "" &&
                  response.data.Message !== null
                ) {
                  axios
                    .post(this.state.api + `Vehicle/GetMotorQuotes`, post.data)
                    .then((response) => {
                      if (
                        response.data.Message !== "" &&
                        response.data.Message !== null
                      ) {
                        let vehicleDetailsId = response.data;
                        vehicleDetailsId = vehicleDetailsId.replace(/\\/g, "");
                        vehicleDetailsId = JSON.parse(vehicleDetailsId);
                        vehicleDetailsId = vehicleDetailsId.QuoteList;
                        let finalQuotes = [];
                        if (vehicleDetailsId) {
                          this.state.quoteList = [];

                          let quoteList = Object.assign(
                            {},
                            this.state.quoteList
                          );
                          this.state.quoteList.push(vehicleDetailsId);
                          quoteList = this.state.quoteList;
                          if (this.state.quoteList.length) {
                            for (let quotes of this.state.quoteList) {
                              for (let i = 0; i < quotes.length; i++) {
                                let quote = {
                                  InsQuoteID: quotes[i].InsQuoteID,
                                  InsName: quotes[i].InsName,
                                  VehicleValue: quotes[i].VehicleValue,
                                  NetPremium: quotes[i].NetPremium,
                                  PlanName: quotes[i].PlanName,
                                  Premium: quotes[i].Premium,
                                  CoverageType: quotes[i].CoverageType,
                                };
                                finalQuotes.push(quote);
                              }
                            }
                          }
                          this.setState({
                            loader: false,
                            closePopup: "",
                            quoteList: quoteList[0],
                            smShow: false,
                          });
                          this.setState({ tempQuote: quoteList[0] });
                          let insuranceNames = [];

                          insuranceNames = this.state.quoteList
                            .map((a) => a.InsName)
                            .filter((x, i, a) => a.indexOf(x) === i);

                          this.setState({
                            uniqueInsuranceName: insuranceNames,
                          });
                        } else {
                          this.state.quoteList.push(vehicleDetailsId);
                          this.state.responseMsg = "No QuoteList";
                          this.setState({
                            loader: false,
                            closePopup: "",
                            displayCarDetails: "none",
                          });
                        }
                        if (this.state.quoteList.length > 0) {
                          this.setState({
                            vehicleDetailsIDs: finalQuotes,
                            loader: false,
                            closePopup: "",
                            smShow: false,
                          });
                        }
                      }
                    })
                    .catch(function (error) {
                      if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        this.setState({
                          loader: false,
                          closePopup: "",
                          smShow: true,
                        });
                      } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                        this.setState({
                          loader: false,
                          closePopup: "",
                          smShow: true,
                        });
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                        this.setState({
                          loader: false,
                          closePopup: "",
                          smShow: true,
                        });
                      }
                    });
                } else {
                  this.state.responseMsg =
                    response.data.ErrorDesc === null
                      ? "Please filter proper data"
                      : response.data.ErrorDesc;

                  this.setState({
                    loader: false,
                    closePopup: "",
                    submitVehicleRegistrationWithoutChassisNumberDetails: false,
                    smShow: true,
                  });
                }
              } else {
                this.state.responseMsg = response.data.ErrMsg;

                this.setState({ loader: false, closePopup: "", smShow: true });
              }
            })
            .catch(function (error) {
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                this.setState({ loader: false, closePopup: "", smShow: true });
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                this.setState({ loader: false, closePopup: "", smShow: true });
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                this.setState({ loader: false, closePopup: "", smShow: true });
              }
            });
        } else if (
          response.status === 200 &&
          response.data.Status === "Success" &&
          response.data.IsVehicleRegistered === false
        ) {
          if (response.data.Message === "Not Registered") {
            let post = {
              headers: new Headers({
                Accept: "text/plain;charset=UTF-8",
                "Content-Type": "text/plain;charset=UTF-8",
                "Access-Control-Allow-Origin": this.state.https,
                Authorization: `Bearer ${localStorage.getItem("user")}`,
                // <-- Specifying the Content-Type
              }),
              mode: "no-cors",
              data: {
                insuredName: this.state.personalDetails.yourName._value,
                mobileNo: this.state.personalDetails.mobileNumber._value,
                emailID: this.state.personalDetails.email._value,
              },
            };
            axios
              .post(
                this.state.api + `User/UserRegistrationByEmailID`,
                post.data
              )
              .then((response) => {
                if (response.data.Status === "Success") {
                  let post = {
                    headers: new Headers({
                      Accept: "text/plain;charset=UTF-8",
                      "Content-Type": "text/plain;charset=UTF-8",
                      "Access-Control-Allow-Origin": this.state.https,
                      Authorization: `Bearer ${localStorage.getItem("user")}`,
                    }),
                    mode: "no-cors",
                    data: {
                      Make: this.state.vehicleRegistrationWithoutChassisNumber
                        .make._value,
                      Model:
                        this.state.vehicleRegistrationWithoutChassisNumber.model
                          ._value,
                      ModelYear:
                        this.state.vehicleRegistrationWithoutChassisNumber
                          .modelYear._value,
                      trimValue:
                        this.state.vehicleRegistrationWithoutChassisNumber
                          .trimValue._value === ""
                          ? "GLS"
                          : this.state.vehicleRegistrationWithoutChassisNumber
                              .trimValue._value,
                      BodyType:
                        this.state.vehicleRegistrationWithoutChassisNumber
                          .bodyType._value,
                      //   "DateOfRegistration": this.state.vehicleRegistrationWithoutChassisNumber.dateOfRegistration._value,
                      DateOfRegistration: this.DBDateOfRegistrationFormat(
                        this.state.vehicleRegistrationWithoutChassisNumber
                          .dateOfRegistration._values
                      ),
                      NoClaimYear: "2",
                      PlateCode: this.state.vehicleDetails.plateCode._value,
                      PlateNumber: this.state.vehicleDetails.plateNumber._value,
                      LOCRegion:
                        this.state.vehicleRegistrationWithoutChassisNumber
                          .dLEmirate._value,
                      EngineSize:
                        this.state.vehicleRegistrationWithoutChassisNumber
                          .engineSize._value,
                      PolicyStartDate: this.DBDateOfRegistrationFormat(
                        this.state.vehicleRegistrationWithoutChassisNumber
                          .dateOfRegistration._values
                      ),
                      UserName: this.state.personalDetails.email._value,
                      VehicleValue:
                        this.state.vehicleRegistrationWithoutChassisNumber.vehiclePrice._value.toString(),
                      DLIssuedDate: this.DBDateOfRegistrationFormat(
                        this.state.vehicleRegistrationWithoutChassisNumber
                          .dLIssuedDate._values
                      ),
                      DLEmirate:
                        this.state.vehicleRegistrationWithoutChassisNumber
                          .dLEmirate._value,
                    },
                  };
                  this.state.responseMsg = "";
                  axios
                    .post(
                      this.state.api +
                        `Vehicle/VehicleRegistrationWithoutChassisNo`,
                      post.data
                    )
                    .then((response) => {
                      if (response.data.Status === "Success") {
                        let post = {
                          headers: new Headers({
                            Accept: "text/plain;charset=UTF-8",
                            "Content-Type": "text/plain;charset=UTF-8",
                            "Access-Control-Allow-Origin": this.state.https,
                            Authorization: `Bearer ${localStorage.getItem(
                              "user"
                            )}`,
                          }),
                          mode: "no-cors",
                          data: {
                            vehicleDetailsID: response.data.Message,
                          },
                        };
                        this.state.responseMsg = "";
                        if (
                          response.data.Message !== "" &&
                          response.data.Message !== null
                        ) {
                          axios
                            .post(
                              this.state.api + `Vehicle/GetMotorQuotes`,
                              post.data
                            )
                            .then((response) => {
                              if (
                                response.data.Message !== "" &&
                                response.data.Message !== null
                              ) {
                                let vehicleDetailsId = response.data;
                                vehicleDetailsId = vehicleDetailsId.replace(
                                  /\\/g,
                                  ""
                                );
                                vehicleDetailsId = JSON.parse(vehicleDetailsId);
                                vehicleDetailsId = vehicleDetailsId.QuoteList;
                                let finalQuotes = [];
                                if (vehicleDetailsId) {
                                  this.state.quoteList = [];
                                  let quoteList = Object.assign(
                                    {},
                                    this.state.quoteList
                                  );

                                  this.state.quoteList.push(vehicleDetailsId);
                                  quoteList = this.state.quoteList;
                                  if (this.state.quoteList.length) {
                                    for (let quotes of this.state.quoteList) {
                                      for (let i = 0; i < quotes.length; i++) {
                                        let quote = {
                                          InsQuoteID: quotes[i].InsQuoteID,
                                          InsName: quotes[i].InsName,
                                          VehicleValue: quotes[i].VehicleValue,
                                          NetPremium: quotes[i].NetPremium,
                                          PlanName: quotes[i].PlanName,
                                          Premium: quotes[i].Premium,
                                          CoverageType: quotes[i].CoverageType,
                                        };
                                        finalQuotes.push(quote);
                                      }
                                      if (this.state.quoteList.length <= 0) {
                                        this.state.responseMsg =
                                          "No Quote List";
                                        this.setState({
                                          loader: false,
                                          closePopup: "",
                                          quoteList: quoteList[0],
                                          displayCarDetails: "none",
                                        });
                                      }
                                    }
                                  }
                                  this.setState({
                                    loader: false,
                                    closePopup: "",
                                  });
                                  this.setState({ tempQuote: quoteList[0] });
                                  let insuranceNames = [];

                                  insuranceNames = this.state.quoteList
                                    .map((a) => a.InsName)
                                    .filter((x, i, a) => a.indexOf(x) === i);

                                  this.setState({
                                    uniqueInsuranceName: insuranceNames,
                                  });
                                } else {
                                  this.state.quoteList.push(vehicleDetailsId);
                                  this.setState({ displayCarDetails: "none" });
                                }
                                if (this.state.quoteList.length > 0) {
                                  this.setState({
                                    loader: false,
                                    closePopup: "",
                                    smShow: false,
                                  });
                                }
                              } else {
                                this.state.responseMsg = "No Quote List";
                                this.setState({
                                  loader: false,
                                  closePopup: "",
                                });
                              }
                            })
                            .catch(function (error) {
                              if (error.response) {
                                // Request made and server responded
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                                this.setState({
                                  loader: false,
                                  closePopup: "",
                                });
                              } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                                this.setState({
                                  loader: false,
                                  closePopup: "",
                                });
                              } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log("Error", error.message);
                                this.setState({
                                  loader: false,
                                  closePopup: "",
                                });
                                // this.setState({ loader: false,smShow:true });
                              }
                            });
                        } else {
                          this.state.responseMsg = response.data.ErrorDesc;
                          this.setState({
                            loader: false,
                            closePopup: "",
                            smShow: true,
                          });
                        }
                        // else
                        //alert(response.data.ErrorDesc)
                      } else {
                        this.state.responseMsg = response.data.ErrMsg;

                        this.setState({
                          loader: false,
                          closePopup: "",
                          smShow: true,
                        });
                      }
                    })
                    .catch(function (error) {
                      if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        this.setState({
                          loader: false,
                          closePopup: "",
                          smShow: true,
                        });
                      } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                        this.setState({
                          loader: false,
                          closePopup: "",
                          smShow: true,
                        });
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                        this.setState({
                          loader: false,
                          closePopup: "",
                          smShow: true,
                        });
                      }
                    });
                  if (
                    response.status === 200 &&
                    response.data.Status === "Success"
                  ) {
                    // this.state.responseMsg = response.data.Message;
                  } else {
                    this.state.responseMsg = response.data.ErrMsg;
                    this.setState({
                      loader: false,
                      closePopup: "",
                      smShow: true,
                    });
                  }
                } else {
                  this.state.responseMsg = response.data.ErrMsg;
                  this.setState({
                    loader: false,
                    closePopup: "",
                    smShow: true,
                  });
                }
                return response;
              })
              .catch(function (error) {
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                  this.setState({
                    loader: false,
                    closePopup: "",
                    smShow: true,
                  });
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                  this.setState({
                    loader: false,
                    closePopup: "",
                    smShow: true,
                  });
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                  this.setState({
                    loader: false,
                    closePopup: "",
                    smShow: true,
                  });
                }
              });
          } else {
            this.state.responseMsg = response.data.ErrorDesc;
            this.setState({ loader: false, closePopup: "", smShow: true });
          }
        } else {
          this.state.responseMsg = response.data.ErrorDesc;
          this.setState({ loader: false, closePopup: "", smShow: true });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.setState({ loader: false, closePopup: "", smShow: true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          this.setState({ loader: false, closePopup: "", smShow: true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          this.setState({ loader: false, closePopup: "", smShow: true });
        }
      });
  }
  handlePersonalDetailsValidations() {
    let error = false;

    let personal = this.state.personalDetails;
    for (var i in personal) {
      ////console.log('validation', personal[i].errorMessage)
      if (
        (personal[i].required && !personal[i]._value) ||
        personal[i].errorMessage !== ""
      ) {
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  submitPersonalDetails() {
    this.setState({
      submitVehicleDetails: true,
    });
    this.state.submitVehicleDetails = true;
    if (this.handleVehicleDetailsValidations()) return;
    this.setState({
      submitPersonalDetails: true,
    });
    this.state.submitPersonalDetails = true;
    if (this.handlePersonalDetailsValidations()) return;
    this.setState({ displayPersonals: "none", displayPersonal: false });

    //Benny Filter Reset
    this.setState({
      selectedPremiumSortOption: "",
      insuranceFilterList: [],
      repairFilterList: [],
    });
    //Benny Filter Reset End

    this.submitCarDetails();
  }
  submitCarValueDetails() {
    this.setState({ displayCarValues: "none", displayCarValue: false });

    //Benny Filter Reset

    this.setState({
      selectedPremiumSortOption: "",
      insuranceFilterList: [],
      repairFilterList: [],
    });

    //Benny Filter Reset End
    this.submitCarDetails();
  }

  onRadioValueChange(event) {
    this.setState({
      selectedOption: event.target.value,
    });

    if (this.state.selectedOption.indexOf(event.target.value) === -1) {
      this.setState({
        selectedOption: [...this.state.selectedOption, event.target.value],
      });
    }

    if (this.state.selectedOption.indexOf(event.target.value) !== -1) {
      let tempselectedOption = [];
      tempselectedOption = this.state.selectedOption.filter(
        (a) => a !== event.target.value
      );
      this.setState({ selectedOption: tempselectedOption });
    }
    console.log(" selectedOption", this.state.selectedOption);
  }

  setTempQuote() {
    this.setState({ quoteList: this.state.tempQuote });
  }

  submitAgency() {
    this.setState({
      displayAgencys: "none",
      displayAgency: false,
      closePopup: "",
    });
    console.log(this.state.quoteList);
    this.setState({
      selectedPremiumSortOption: "",
      insuranceFilterList: [],
      repairFilterList: [],
    });

    this.state.quoteList = this.state.tempQuote.slice();

    if (this.state.selectedOption.length !== 0) {
      this.state.quoteList = this.state.quoteList.filter((i) =>
        this.state.selectedOption.includes(i.CoverageType)
      );
    }

    // this.state.quoteList=this.state.quoteList.filter(a => a.CoverageType===this.state.selectedOption);

    console.log("quoteatency", this.state.quoteList, this.state.selectedOption);
  }
  radioreset() {
    this.setState({ displayAgencys: "none", displayAgency: false });
    this.setTempQuote();
    this.setState({
      selectedOption: "",
    });
  }

  onSortRadioValueChange(event) {
    this.setState({
      selectedPremiumSortOption: event.target.value,
    });
  }

  handleCheckboxChange = (index) => (event) => {
    if (this.state.insuranceFilterList.indexOf(event.target.value) === -1) {
      this.setState({
        insuranceFilterList: [
          ...this.state.insuranceFilterList,
          event.target.value,
        ],
      });
    }

    if (this.state.insuranceFilterList.indexOf(event.target.value) !== -1) {
      let indexofinsurance = [];
      indexofinsurance = this.state.insuranceFilterList.filter(
        (a) => a !== event.target.value
      );
      this.setState({ insuranceFilterList: indexofinsurance });
    }
    console.log("insuranceFilterList", this.state.insuranceFilterList);
  };

  handleRepairCheckboxChange = (event) => {
    if (this.state.repairFilterList.indexOf(event.target.value) === -1) {
      this.setState({
        repairFilterList: [...this.state.repairFilterList, event.target.value],
      });
    }

    if (this.state.repairFilterList.indexOf(event.target.value) !== -1) {
      let repairofinsurance = [];
      repairofinsurance = this.state.repairFilterList.filter(
        (a) => a !== event.target.value
      );
      this.setState({ repairFilterList: repairofinsurance });
    }
    console.log("repairFilterList", this.state.repairFilterList);
  };

  sortFilter(e) {
    e.preventDefault();
    this.state.quoteList = this.state.tempQuote.slice();
    this.setState({ displaySorts: "none", displaySort: false, closePopup: "" });
    console.log(this.state.quoteList);

    if (this.state.selectedPremiumSortOption === "ascend")
      this.state.quoteList = this.state.quoteList.sort(
        (a, b) => a.NetPremium - b.NetPremium
      );
    else if (this.state.selectedPremiumSortOption === "decend")
      this.state.quoteList = this.state.quoteList.sort(
        (a, b) => b.NetPremium - a.NetPremium
      );

    if (this.state.insuranceFilterList.length !== 0) {
      this.state.quoteList = this.state.quoteList.filter((i) =>
        this.state.insuranceFilterList.includes(i.InsName)
      );
    }
    if (this.state.repairFilterList.length !== 0) {
      this.state.quoteList = this.state.quoteList.filter((i) =>
        this.state.repairFilterList.includes(i.CoverageType)
      );
    }
  }

  resetsortFilter(e) {
    e.preventDefault();
    this.setState({ displaySorts: "none", displaySort: false });
    this.setTempQuote();
    this.setState({
      selectedPremiumSortOption: "",
      insuranceFilterList: [],
      repairFilterList: [],
      closePopup: "",
    });
    console.log("resetsortFilter", this.state.tempQuote);
  }

  getTrimApi(make, model, year) {
    let trim = [],
      trimValues = [];
    axios
      .get(
        this.state.api +
          `Master/GetTrimList?Make=${this.state.vehicleRegistrationWithoutChassisNumber.make._value}&Model=${this.state.vehicleRegistrationWithoutChassisNumber.model._value}&ModelYear=${this.state.vehicleRegistrationWithoutChassisNumber.modelYear._value}`
      )
      .then((response) => {
        if (response.data.length) {
          for (let Trim of response.data) {
            for (let i = 0; i < Trim[0].length; i++) {
              trim = {
                label: Trim,
                value: Trim,
              };
              trimValues.push(trim);
            }
          }
        }
        this.setState((prevState) => {
          let trimMaster = Object.assign({}, prevState.trimMaster);
          trimMaster = trimValues;
          console.log(trimMaster);
          return { trimMaster: trimMaster };
        });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          //  this.setState({ loader: false,smShow:true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          // this.setState({ loader: false,smShow:true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // this.setState({ loader: false,smShow:true });
        }
      });
  }
  getBodyTypeApi() {
    let body = [],
      bodyTypes = [];
    axios
      .get(
        this.state.api +
          `Master/GetBodyTypeList?Make=${this.state.vehicleRegistrationWithoutChassisNumber.make._value}&Model=${this.state.vehicleRegistrationWithoutChassisNumber.model._value}&ModelYear=${this.state.vehicleRegistrationWithoutChassisNumber.modelYear._value}&Trim=${this.state.vehicleRegistrationWithoutChassisNumber.trimValue._value}`
      )
      .then((response) => {
        // this.state.bodyTypeMaster = response.data;
        if (response.data.length) {
          for (let Body of response.data) {
            for (let i = 0; i < Body[0].length; i++) {
              body = {
                value: Body,
                label: Body,
              };
              bodyTypes.push(body);
            }
          }

          this.setState((prevState) => {
            let bodyTypeMaster = Object.assign({}, prevState.bodyTypeMaster);
            bodyTypeMaster = bodyTypes;
            return { bodyTypeMaster: bodyTypeMaster };
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          //  this.setState({ loader: false,smShow:true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          // this.setState({ loader: false,smShow:true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // this.setState({ loader: false,smShow:true });
        }
      });
  }
  getMakeApi() {
    let make = [],
      makeSearchs = [];
    axios
      .get(this.state.api + `Master/GetMake`)
      .then((response) => {
        // this.state.makeMaster = response.data;

        if (response.data) {
          for (let Make of response.data) {
            make = {
              value: Make.make,
              label: Make.make,
            };
            makeSearchs.push(make);
          }
          this.setState((prevState) => {
            let makeSearch = Object.assign({}, prevState.makeSearch);
            makeSearch = makeSearchs;
            return { makeSearch: makeSearch };
          });
          //console.log("makeSearch", this.state.makeSearch);
        }
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  }

  getEngineSizeApi() {
    let engine = [],
      engineSizes = [];
    axios
      .get(
        this.state.api +
          `Master/GetEngineSizeList?Make=${this.state.vehicleRegistrationWithoutChassisNumber.make._value}&Model=${this.state.vehicleRegistrationWithoutChassisNumber.model._value}&ModelYear=${this.state.vehicleRegistrationWithoutChassisNumber.modelYear._value}&Trim=${this.state.vehicleRegistrationWithoutChassisNumber.trimValue._value}&BodyType=${this.state.vehicleRegistrationWithoutChassisNumber.bodyType._value}`
      )
      .then((response) => {
        // this.state.engineSizeMaster = response.data;
        // return response.data;
        if (response.data.length) {
          for (let Engine of response.data) {
            for (let i = 0; i < Engine[0].length; i++) {
              engine = {
                value: Engine,
                label: Engine,
              };
              engineSizes.push(engine);
            }
          }

          this.setState((prevState) => {
            let engineSizeMaster = Object.assign(
              {},
              prevState.engineSizeMaster
            );
            engineSizeMaster = engineSizes;
            return { engineSizeMaster: engineSizeMaster };
          });
        } else {
          axios
            .get(this.state.api + `Master/GetEngineSize`)
            .then((response) => {
              if (response.data.length) {
                for (let Engine of response.data) {
                  engine = {
                    value: Engine.engineSize,
                    label: Engine.engineSize,
                  };
                  engineSizes.push(engine);
                }

                this.setState((prevState) => {
                  let engineSizeMaster = Object.assign(
                    {},
                    prevState.engineSizeMaster
                  );
                  engineSizeMaster = engineSizes;
                  return { engineSizeMaster: engineSizeMaster };
                });
              }
            });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          //   this.setState({ loader: false,smShow:true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          //   this.setState({ loader: false,smShow:true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          //   this.setState({ loader: false,smShow:true });
        }
      });
  }
  // getEngineSizeApi() {
  //   axios.get(this.state.api + `Master/GetEngineSize`
  //   )
  //     .then(response => {
  //       // this.state.engineSizeMaster = response.data;
  //       let engineSize = [];
  //       if (response.data) {
  //         console.log('response', response.data)
  //         for (let EngineSize of response.data) {
  //           engineSize = {
  //             'value': EngineSize.engineSize,
  //             'label': EngineSize.engineSize,

  //           }
  //           this.state.engineSizeMaster.push(engineSize)

  //         }
  //       }
  //       return response.data;
  //     }).catch(function (error) {
  //       if (error.response) {
  //         // Request made and server responded
  //         console.log(error.response.data);
  //         console.log(error.response.status);
  //         console.log(error.response.headers);
  //       } else if (error.request) {
  //         // The request was made but no response was received
  //         console.log(error.request);
  //       } else {
  //         // Something happened in setting up the request that triggered an Error
  //         console.log('Error', error.message);
  //       }
  //     });

  // }
  getModelMakeApi(make) {
    console.log("make", make);
    axios
      .get(this.state.api + `Master/GetModelMake?Make=${make}`)
      .then((response) => {
        let vehicleRegistrationWithoutChassisNumber = Object.assign(
          {},
          this.state.vehicleRegistrationWithoutChassisNumber
        );

        // vehicleRegistrationWithoutChassisNumber['model']._value = '';
        // vehicleRegistrationWithoutChassisNumber['trimValue']._value = '';
        // vehicleRegistrationWithoutChassisNumber['bodyType']._value = '';
        // vehicleRegistrationWithoutChassisNumber['engineSize']._value = '';
        // this.state.modelSearch = [];
        // this.state.trimMaster = [];
        // this.state.bodyTypeMaster = [];
        // this.state.engineSizeMaster = [];
        // this.state.selectedOptionModel = '';
        // this.state.selectedOptionTrim = '';
        // this.state.selectedOptionBodyType = '';
        // this.state.selectedOptionEngineSize = '';
        let model = [],
          modelSearchs = [];
        if (response.data) {
          console.log("response", response.data);
          for (let Model of response.data) {
            model = {
              value: Model.model,
              label: Model.model,
            };
            modelSearchs.push(model);
          }
        }

        this.setState((prevState) => {
          let modelSearch = Object.assign({}, prevState.modelSearch);
          modelSearch = modelSearchs;
          return {
            modelSearch: modelSearch,
            vehicleRegistrationWithoutChassisNumber:
              vehicleRegistrationWithoutChassisNumber,
          };
        });
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  }
  getChangeModelMakeApi(make) {
    console.log("make", make);
    axios
      .get(this.state.api + `Master/GetModelMake?Make=${make.label}`)
      .then((response) => {
        let vehicleRegistrationWithoutChassisNumber = Object.assign(
          {},
          this.state.vehicleRegistrationWithoutChassisNumber
        );

        vehicleRegistrationWithoutChassisNumber["model"]._value = "";
        vehicleRegistrationWithoutChassisNumber["trimValue"]._value = "";
        vehicleRegistrationWithoutChassisNumber["bodyType"]._value = "";
        vehicleRegistrationWithoutChassisNumber["engineSize"]._value = "";
        this.state.modelSearch = [];
        this.state.trimMaster = [];
        this.state.bodyTypeMaster = [];
        this.state.engineSizeMaster = [];
        this.state.selectedOptionModel = "";
        this.state.selectedOptionTrim = "";
        this.state.selectedOptionBodyType = "";
        this.state.selectedOptionEngineSize = "";
        let model = [];
        if (response.data) {
          console.log("response", response.data);
          for (let Model of response.data) {
            model = {
              value: Model.model,
              label: Model.model,
            };
            this.state.modelSearch.push(model);
          }
        }
        console.log(this.state.modelSearch);
        this.setState({
          vehicleRegistrationWithoutChassisNumber:
            vehicleRegistrationWithoutChassisNumber,
        });
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  }
  handleVehicleDetailChange(event) {
    ////console.log(event)
    let { name, value, validationMessage } = event.target;
    this.setState((prevState) => {
      let vehicleDetails = Object.assign({}, this.state.vehicleDetails);
      vehicleDetails[name]._value = value;
      vehicleDetails[name].touched = true;
      vehicleDetails[name].errorMessage = validationMessage;
      if (
        event.target.name === "plateCode" &&
        vehicleDetails["plateCode"].errorMessage !== ""
      )
        vehicleDetails[name].errorMessage =
          "Special Characters are not allowed";
      if (
        event.target.name === "plateNumber" &&
        vehicleDetails["plateNumber"].errorMessage !== ""
      )
        vehicleDetails[name].errorMessage =
          "Characters and Special Characters are not allowed";

      return vehicleDetails;
    });
  }
  getImageMulkiyaF() {
    this.setState((prevState) => {
      let imgMulkiyaF = Object.assign({}, prevState.imgMulkiyaF);
      imgMulkiyaF =
        this.state.documentDetailsMulkiya.documentMulkiyaF._value1.dataURL;
      console.log("imgMulkiyaF", this.state.imgMulkiyaF);
      return { imgMulkiyaF: imgMulkiyaF };
    });
  }
  removeMulkiyaF() {
    this.setState((prevState) => {
      let documentDetailsMulkiya = Object.assign(
        {},
        prevState.documentDetailsMulkiya
      );
      let imgMulkiyaF = Object.assign({}, prevState.imgMulkiyaF);
      imgMulkiyaF = "";
      documentDetailsMulkiya["documentMulkiyaF"]._value1 = "";
      documentDetailsMulkiya["documentMulkiyaF"].touched1 = false;
      documentDetailsMulkiya["documentMulkiyaF"].errorMessage =
        "Document Mulkiya Front is required";
      return { documentDetailsMulkiya, imgMulkiyaF };
    });
  }

  getImageMulkiyaB() {
    this.setState((prevState) => {
      let imgMulkiyaB = Object.assign({}, prevState.imgMulkiyaB);
      imgMulkiyaB =
        this.state.documentDetailsMulkiya.documentMulkiyaB._value2.dataURL;
      // console.log('imgMulkiyaF',this.state.imgMulkiyaF)
      return { imgMulkiyaB: imgMulkiyaB };
    });
  }
  removeMulkiyaB() {
    this.setState((prevState) => {
      let documentDetailsMulkiya = Object.assign(
        {},
        prevState.documentDetailsMulkiya
      );
      let imgMulkiyaB = Object.assign({}, prevState.imgMulkiyaB);
      imgMulkiyaB = "";
      documentDetailsMulkiya["documentMulkiyaB"]._value2 = "";
      documentDetailsMulkiya["documentMulkiyaB"].touched2 = false;
      documentDetailsMulkiya["documentMulkiyaB"].errorMessage =
        "Document Mulkiya Back is required";
      return { documentDetailsMulkiya, imgMulkiyaB };
    });
  }
  getImageLicenceF() {
    this.setState((prevState) => {
      let imgLicenceF = Object.assign({}, prevState.imgLicenceF);
      imgLicenceF =
        this.state.documentDetailsLicence.documentLicenceF._value1.dataURL;
      // console.log('imgMulkiyaF',this.state.imgMulkiyaF)
      return { imgLicenceF: imgLicenceF };
    });
  }
  removeLicenceF() {
    this.setState((prevState) => {
      let documentDetailsLicence = Object.assign(
        {},
        prevState.documentDetailsLicence
      );
      let imgLicenceF = Object.assign({}, prevState.imgLicenceF);
      imgLicenceF = "";
      documentDetailsLicence["documentLicenceF"]._value1 = "";
      documentDetailsLicence["documentLicenceF"].touched1 = false;
      documentDetailsLicence["documentLicenceF"].errorMessage =
        "Document Licence Front is required";
      // documentDetailsLicence.documentLicenceF._value1 = '';
      return {
        documentDetailsLicence: documentDetailsLicence,
        imgLicenceF: imgLicenceF,
      };
    });
  }
  getImageLicenceB() {
    this.setState((prevState) => {
      let imgLicenceB = Object.assign({}, prevState.imgLicenceB);
      imgLicenceB =
        this.state.documentDetailsLicence.documentLicenceB._value2.dataURL;
      // console.log('imgMulkiyaF',this.state.imgMulkiyaF)
      return { imgLicenceB: imgLicenceB };
    });
  }
  removeLicenceB() {
    this.setState((prevState) => {
      let documentDetailsLicence = Object.assign(
        {},
        prevState.documentDetailsLicence
      );
      let imgLicenceB = Object.assign({}, prevState.imgLicenceB);
      imgLicenceB = "";
      documentDetailsLicence["documentLicenceB"]._value2 = "";
      documentDetailsLicence["documentLicenceB"].touched2 = false;
      documentDetailsLicence["documentLicenceB"].errorMessage =
        "Document Licence Back is required";
      // documentDetailsLicence.documentLicenceB._value1 = '';
      return {
        documentDetailsLicence: documentDetailsLicence,
        imgLicenceB: imgLicenceB,
      };
    });
  }
  getImageEmiratesIDF() {
    this.setState((prevState) => {
      let imgEmiratesF = Object.assign({}, prevState.imgEmiratesF);
      imgEmiratesF =
        this.state.documentDetailsEmiratesID.documentEmiratesIDF._value1
          .dataURL;
      // console.log('imgMulkiyaF',this.state.imgMulkiyaF)
      return { imgEmiratesF: imgEmiratesF };
    });
  }
  removeEmiratesIDF() {
    this.setState((prevState) => {
      let documentDetailsEmiratesID = Object.assign(
        {},
        prevState.documentDetailsEmiratesID
      );
      let imgEmiratesF = Object.assign({}, prevState.imgEmiratesF);
      imgEmiratesF = "";
      documentDetailsEmiratesID["documentEmiratesIDF"]._value1 = "";
      documentDetailsEmiratesID["documentEmiratesIDF"].touched1 = false;
      documentDetailsEmiratesID["documentEmiratesIDF"].errorMessage =
        "Document EmiratesId Front is required";
      // documentDetailsEmiratesID.documentLicenceB._value1 = '';
      return {
        documentDetailsEmiratesID: documentDetailsEmiratesID,
        imgEmiratesF: imgEmiratesF,
      };
    });
  }
  getImageEmiratesIDB() {
    this.setState((prevState) => {
      let imgEmiratesB = Object.assign({}, prevState.imgEmiratesB);
      imgEmiratesB =
        this.state.documentDetailsEmiratesID.documentEmiratesIDB._value2
          .dataURL;
      // console.log('imgMulkiyaF',this.state.imgMulkiyaF)
      return { imgEmiratesB: imgEmiratesB };
    });
  }
  removeEmiratesIDB() {
    this.setState((prevState) => {
      let documentDetailsEmiratesID = Object.assign(
        {},
        prevState.documentDetailsEmiratesID
      );
      let imgEmiratesB = Object.assign({}, prevState.imgEmiratesB);
      imgEmiratesB = "";
      documentDetailsEmiratesID["documentEmiratesIDB"]._value2 = "";
      documentDetailsEmiratesID["documentEmiratesIDB"].touched2 = false;
      documentDetailsEmiratesID["documentEmiratesIDB"].errorMessage =
        "Document EmiratesId Back is required";
      // documentDetailsEmiratesID.documentLicenceB._value1 = '';
      return {
        documentDetailsEmiratesID: documentDetailsEmiratesID,
        imgEmiratesB: imgEmiratesB,
      };
    });
  }
  getNationalityApi() {
    axios
      .get(this.state.api + `Master/GetCountry`)
      .then((response) => {
        this.setState((prevState) => {
          let array = [];
          let nationality = Object.assign({}, prevState.nationality);

          array.push(response.data);
          nationality = array[0];
          //console.log("nationality", nationality);
          return { nationality: nationality };
        });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.setState({ loader: false, smShow: true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          this.setState({ loader: false, smShow: true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          this.setState({ loader: false, smShow: true });
        }
      });
  }
  handledocumentMulkiyaFChange(event) {
    this.setState((prevState) => {
      let documentDetailsMulkiya = Object.assign(
        {},
        prevState.documentDetailsMulkiya
      );
      let disableMulkiyabtn = Object.assign({}, prevState.disableMulkiyabtn);
      documentDetailsMulkiya["documentMulkiyaF"]._value1 = event;
      documentDetailsMulkiya["documentMulkiyaF"].touched1 = true;
      documentDetailsMulkiya["documentMulkiyaF"].errorMsg = "";
      disableMulkiyabtn = false;
      return { documentDetailsMulkiya, disableMulkiyabtn: disableMulkiyabtn };
    });
  }
  handledocumentMulkiyaBChange(event) {
    this.setState((prevState) => {
      let disableMulkiyabtn = Object.assign({}, prevState.disableMulkiyabtn);
      let documentDetailsMulkiya = Object.assign(
        {},
        prevState.documentDetailsMulkiya
      );
      documentDetailsMulkiya["documentMulkiyaB"]._value2 = event;
      documentDetailsMulkiya["documentMulkiyaB"].touched2 = true;
      documentDetailsMulkiya["documentMulkiyaB"].errorMsg = "";
      disableMulkiyabtn = false;
      return { documentDetailsMulkiya, disableMulkiyabtn: disableMulkiyabtn };
    });
    // if(this.state.documentDetailsMulkiya.documentMulkiyaB._value2.accepted){
    //     console.log(this.state.documentDetailsMulkiya.documentMulkiyaB._value2,event)
    // this.uploadMulkiya();
    // }
  }
  handledocumentLicenceFChange(event) {
    this.setState((prevState) => {
      let disableLicencebtn = Object.assign({}, prevState.disableLicencebtn);
      let documentDetailsLicence = Object.assign(
        {},
        prevState.documentDetailsLicence
      );
      documentDetailsLicence["documentLicenceF"]._value1 = event;
      documentDetailsLicence["documentLicenceF"].touched1 = true;
      documentDetailsLicence["documentLicenceF"].errorMsg = "";
      disableLicencebtn = false;
      return { documentDetailsLicence, disableLicencebtn: disableLicencebtn };
    });
  }
  handledocumentLicenceBChange(event) {
    this.setState((prevState) => {
      let disableLicencebtn = Object.assign({}, prevState.disableLicencebtn);
      let documentDetailsLicence = Object.assign(
        {},
        prevState.documentDetailsLicence
      );
      documentDetailsLicence["documentLicenceB"]._value2 = event;
      documentDetailsLicence["documentLicenceB"].touched2 = true;
      documentDetailsLicence["documentLicenceB"].errorMsg = "";
      disableLicencebtn = false;
      return { documentDetailsLicence, disableLicencebtn: disableLicencebtn };
    });
  }
  handledocumentEmiratesIDFChange(event) {
    this.setState((prevState) => {
      let documentDetailsEmiratesID = Object.assign(
        {},
        prevState.documentDetailsEmiratesID
      );
      let disableEmiratesbtn = Object.assign({}, prevState.disableEmiratesbtn);
      documentDetailsEmiratesID["documentEmiratesIDF"]._value1 = event;
      documentDetailsEmiratesID["documentEmiratesIDF"].touched1 = true;
      documentDetailsEmiratesID["documentEmiratesIDF"].errorMsg = "";
      disableEmiratesbtn = false;
      return {
        documentDetailsEmiratesID,
        disableEmiratesbtn: disableEmiratesbtn,
      };
    });
  }
  handledocumentEmiratesIDBChange(event) {
    this.setState((prevState) => {
      let documentDetailsEmiratesID = Object.assign(
        {},
        prevState.documentDetailsEmiratesID
      );
      let disableEmiratesbtn = Object.assign({}, prevState.disableEmiratesbtn);
      documentDetailsEmiratesID["documentEmiratesIDB"]._value2 = event;
      documentDetailsEmiratesID["documentEmiratesIDB"].touched2 = true;
      documentDetailsEmiratesID["documentEmiratesIDB"].errorMsg = "";
      disableEmiratesbtn = false;
      return {
        documentDetailsEmiratesID,
        disableEmiratesbtn: disableEmiratesbtn,
      };
    });
  }
  handlefileDetailEmiratesIDChange(event) {
    let { name, value, validationMessage } = event.target;
    this.setState((prevState) => {
      let fileDetailsEmiratesID = Object.assign(
        {},
        prevState.fileDetailsEmiratesID
      );
      fileDetailsEmiratesID[name]._value = value;
      fileDetailsEmiratesID[name].touched = true;
      fileDetailsEmiratesID[name].errorMessage = validationMessage;
      ////console.log('validation', personalDetails['mobileNumber'].errorMessage)

      if (
        event.target.name === "emiratesID" &&
        fileDetailsEmiratesID["emiratesID"].errorMessage !== ""
      ) {
        // fileDetailsEmiratesID[name].errorMsg = "15 digit EmiratesID is allowed";
        fileDetailsEmiratesID[name].errorMessage =
          "15 digit EmiratesID is allowed";
      }
      return { fileDetailsEmiratesID };
    });
  }
  handleChangeNationality(e) {
    console.log("handleChange");
    let { name, value } = e.target;
    value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(name, value);
    this.setState((prevState) => {
      let fileDetailsEmiratesID = Object.assign(
        {},
        prevState.fileDetailsEmiratesID
      );
      fileDetailsEmiratesID["nationality"]._value = value;
      fileDetailsEmiratesID["nationality"].touched = true;
      return { fileDetailsEmiratesID };
    });
  }
  handleChangeGender(e) {
    console.log("handleChange");
    let { name, value } = e.target;
    value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(name, value);
    this.setState((prevState) => {
      let fileDetailsEmiratesID = Object.assign(
        {},
        prevState.fileDetailsEmiratesID
      );
      fileDetailsEmiratesID["gender"]._value = value;
      fileDetailsEmiratesID["gender"].touched = true;
      return { fileDetailsEmiratesID };
    });
  }
  handlefileDetailMulkiyaChange(event) {
    console.log(event);
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

      let { name, value } = event.target;
      fileDetailsMulkiya[name]._value = value;
      fileDetailsMulkiya[name].touched = true;

      return fileDetailsMulkiya;
    });
  }

  onChangeRegistrationDate(day) {
    let value = day;
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );

      vehicleRegistrationWithoutChassisNumber["dateOfRegistration"]._value =
        day;
      vehicleRegistrationWithoutChassisNumber["dateOfRegistration"]._values =
        day;
      vehicleRegistrationWithoutChassisNumber[
        "dateOfRegistration"
      ].touched = true;
      if (value.includes("y") || value.includes("m") || value.includes("d"))
        vehicleRegistrationWithoutChassisNumber["dateOfRegistration"].errorMsg =
          "please give proper date";
      else
        vehicleRegistrationWithoutChassisNumber["dateOfRegistration"].errorMsg =
          "";

      return vehicleRegistrationWithoutChassisNumber;
    });
    console.log(this.state.vehicleRegistrationWithoutChassisNumber);
  }
  handlefileDetailMulkiyaRegDateChange(day) {
    let date = {
      month: day.month,
      year: day.year,
    };
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

      fileDetailsMulkiya["regDate"]._value = day;
      fileDetailsMulkiya["regDate"]._values = value;
      fileDetailsMulkiya["regDate"].touched = true;

      return fileDetailsMulkiya;
    });
  }

  fnMulkiyaRegDateChange(day) {
    let date = {
      month: day.month,
      year: day.year,
    };
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    return value;
  }

  onChangeDLIssueDate(day) {
    let value = day;

    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );

      vehicleRegistrationWithoutChassisNumber["dLIssuedDate"]._value = day;
      vehicleRegistrationWithoutChassisNumber["dLIssuedDate"]._values = day;
      vehicleRegistrationWithoutChassisNumber["dLIssuedDate"].touched = true;
      if (value.includes("y") || value.includes("m") || value.includes("d"))
        vehicleRegistrationWithoutChassisNumber["dLIssuedDate"].errorMsg =
          "please give proper date";
      else
        vehicleRegistrationWithoutChassisNumber["dLIssuedDate"].errorMsg = "";

      return vehicleRegistrationWithoutChassisNumber;
    });
  }
  // handlefileDetailMulkiyaRegDateChange(event) {
  //     this.setState(prevState => {
  //         let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

  //         fileDetailsMulkiya['regDate']._value = event;
  //         fileDetailsMulkiya['regDate'].touched = true;

  //         return fileDetailsMulkiya;
  //     })

  // };
  handlefileDetailMulkiyaInsDateChange(day) {
    let date = {
      month: day.month,
      year: day.year,
    };
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

      fileDetailsMulkiya["insExp"]._value = day;
      fileDetailsMulkiya["insExp"]._values = value;
      fileDetailsMulkiya["insExp"].touched = true;

      return fileDetailsMulkiya;
    });
  }
  // handlefileDetailMulkiyaInsDateChange(event) {
  //     this.setState(prevState => {
  //         let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

  //         fileDetailsMulkiya['insExp']._value = event;
  //         fileDetailsMulkiya['insExp'].touched = true;

  //         return fileDetailsMulkiya;
  //     })

  // };

  handlefileDetailMulkiyaExpDateChange(day) {
    let date = {
      month: day.month,
      year: day.year,
    };
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

      fileDetailsMulkiya["expDate"]._value = day;
      fileDetailsMulkiya["expDate"]._values = value;
      fileDetailsMulkiya["expDate"].touched = true;

      return fileDetailsMulkiya;
    });
  }
  // handlefileDetailMulkiyaExpDateChange(event) {
  //     this.setState(prevState => {
  //         let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

  //         fileDetailsMulkiya['expDate']._value = event;
  //         fileDetailsMulkiya['expDate'].touched = true;

  //         return fileDetailsMulkiya;
  //     })

  // };

  onChangeLicenceIssueDate(day) {
    let date = {
      month: day.month,
      year: day.year,
    };
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    this.setState((prevState) => {
      let fileDetailsLicence = Object.assign({}, prevState.fileDetailsLicence);

      fileDetailsLicence["issueDate"]._value = day;
      fileDetailsLicence["issueDate"]._values = value;
      fileDetailsLicence["issueDate"].touched = true;

      return { fileDetailsLicence };
    });
  }
  onChangeLicenceExpDate(day) {
    let date = {
      month: day.month,
      year: day.year,
    };
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    this.setState((prevState) => {
      let fileDetailsLicence = Object.assign({}, prevState.fileDetailsLicence);

      fileDetailsLicence["expiryDate"]._value = day;
      fileDetailsLicence["expiryDate"]._values = value;
      fileDetailsLicence["expiryDate"].touched = true;

      return { fileDetailsLicence };
    });
  }
  // onChangeLicenceIssueDate(event) {
  //     this.setState(prevState => {
  //         let fileDetailsLicence = Object.assign({}, prevState.fileDetailsLicence);

  //         fileDetailsLicence['issueDate']._value = event;
  //         fileDetailsLicence['issueDate'].touched = true;

  //         return fileDetailsLicence;
  //     })

  // };
  handlePersonalDetailChange(event) {
    let { name, value, validationMessage } = event.target;
    this.setState((prevState) => {
      // ////console.log('test')
      let personalDetails = Object.assign({}, this.state.personalDetails);
      personalDetails[name]._value = value;
      personalDetails[name].touched = true;
      personalDetails[name].errorMessage = validationMessage;
      ////console.log('validation', personalDetails['mobileNumber'].errorMessage)

      if (
        event.target.name === "mobileNumber" &&
        personalDetails["mobileNumber"].errorMessage !== ""
      )
        personalDetails[name].errorMessage =
          "10 digit Mobile Number is allowed";

      if (
        event.target.name === "yourName" &&
        personalDetails["yourName"].errorMessage !== ""
      )
        personalDetails[name].errorMessage =
          "Minimum 3 Characters and Only Special Character Space is allowed";

      if (
        !validator.isEmail(value) &&
        event.target.name === "email" &&
        personalDetails["email"]._value !== ""
      )
        personalDetails[name].errorMessage = "Invalid Email";
      return personalDetails;
    });
  }
  onChangeEmiratesExpDate(day) {
    let date = {
      month: day.month,
      year: day.year,
    };
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    this.setState((prevState) => {
      let fileDetailsEmiratesID = Object.assign(
        {},
        prevState.fileDetailsEmiratesID
      );

      fileDetailsEmiratesID["expiryDate"]._value = day;
      fileDetailsEmiratesID["expiryDate"]._values = value;
      fileDetailsEmiratesID["expiryDate"].touched = true;

      return { fileDetailsEmiratesID };
    });
  }
  onChangeEmiratesBirthDate(day) {
    let date = {
      month: day.month,
      year: day.year,
    };
    let value = day.day + "/" + date["month"] + "/" + date["year"];
    this.setState((prevState) => {
      let fileDetailsEmiratesID = Object.assign(
        {},
        prevState.fileDetailsEmiratesID
      );

      fileDetailsEmiratesID["dateofBirth"]._value = day;
      fileDetailsEmiratesID["dateofBirth"]._values = value;
      fileDetailsEmiratesID["dateofBirth"].touched = true;

      return { fileDetailsEmiratesID };
    });
  }
  // onChangeLicenceExpDate(event) {
  //     this.setState(prevState => {
  //         let fileDetailsLicence = Object.assign({}, prevState.fileDetailsLicence);

  //         fileDetailsLicence['expiryDate']._value = event;
  //         fileDetailsLicence['expiryDate'].touched = true;

  //         return fileDetailsLicence;
  //     })

  // };
  // onChangeEmiratesExpDate(event) {
  //     this.setState(prevState => {
  //         let fileDetailsEmiratesID = Object.assign({}, prevState.fileDetailsEmiratesID);

  //         fileDetailsEmiratesID['expiryDate']._value = event;
  //         fileDetailsEmiratesID['expiryDate'].touched = true;

  //         return fileDetailsEmiratesID;
  //     })

  // };
  // onChangeEmiratesBirthDate(event) {
  //     this.setState(prevState => {
  //         let fileDetailsEmiratesID = Object.assign({}, prevState.fileDetailsEmiratesID);

  //         fileDetailsEmiratesID['dateofBirth']._value = event;
  //         fileDetailsEmiratesID['dateofBirth'].touched = true;

  //         return fileDetailsEmiratesID;
  //     })

  // };
  handlefileDetailMulkiyaInsExpChange(event) {
    this.setState((prevState) => {
      let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

      fileDetailsMulkiya["insExp"]._value = event;
      fileDetailsMulkiya["insExp"].touched = true;

      return fileDetailsMulkiya;
    });
  }
  handlefileDetailLicenceChange(event) {
    let { name, value } = event.target;
    this.setState((prevState) => {
      let fileDetailsLicence = Object.assign({}, prevState.fileDetailsLicence);
      fileDetailsLicence[name]._value = value;
      fileDetailsLicence[name].touched = true;
      return { fileDetailsLicence };
    });
  }
  onChangeEmirates(e) {
    console.log("handleChange");
    let { name, value } = e.target;
    value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(name, value);
    this.setState((prevState) => {
      let fileDetailsLicence = Object.assign({}, prevState.fileDetailsLicence);
      fileDetailsLicence["placeofIssue"]._value = value;
      fileDetailsLicence["placeofIssue"].touched = true;
      return { fileDetailsLicence };
    });
  }

  onChangeYear = (selectedOptionYear) => {
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );
      vehicleRegistrationWithoutChassisNumber["modelYear"]._value =
        selectedOptionYear.label;
      vehicleRegistrationWithoutChassisNumber["modelYear"].touched = true;
      return { vehicleRegistrationWithoutChassisNumber, selectedOptionYear };
    });
  };

  onChangeMake = (selectedOptionMake) => {
    this.getChangeModelMakeApi(selectedOptionMake);
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );
      vehicleRegistrationWithoutChassisNumber["make"]._value =
        selectedOptionMake.label;
      vehicleRegistrationWithoutChassisNumber["model"]._value = "";
      vehicleRegistrationWithoutChassisNumber["trimValue"]._value = "";
      vehicleRegistrationWithoutChassisNumber["bodyType"]._value = "";
      vehicleRegistrationWithoutChassisNumber["engineSize"]._value = "";
      vehicleRegistrationWithoutChassisNumber["make"].touched = true;
      return { vehicleRegistrationWithoutChassisNumber, selectedOptionMake };
    });
    console.log(
      "handleChange",
      this.state.vehicleRegistrationWithoutChassisNumber
    );
  };
  onChangeModel = (selectedOptionModel) => {
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );
      vehicleRegistrationWithoutChassisNumber["model"]._value =
        selectedOptionModel.label;
      vehicleRegistrationWithoutChassisNumber["model"].touched = true;
      this.getTrimApi(
        this.state.vehicleRegistrationWithoutChassisNumber.make._value,
        selectedOptionModel.label,
        this.state.vehicleRegistrationWithoutChassisNumber.modelYear._value
      );
      return { vehicleRegistrationWithoutChassisNumber, selectedOptionModel };
    });
    console.log(
      "handleChange",
      this.state.vehicleRegistrationWithoutChassisNumber,
      selectedOptionModel
    );
  };

  onChangeBodyType = (selectedOptionBodyType) => {
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );
      vehicleRegistrationWithoutChassisNumber["bodyType"]._value =
        selectedOptionBodyType.label;
      vehicleRegistrationWithoutChassisNumber["bodyType"].touched = true;
      this.getEngineSizeApi();
      return {
        vehicleRegistrationWithoutChassisNumber,
        selectedOptionBodyType,
      };
    });
  };
  onChangeEngineSize = (selectedOptionEngineSize) => {
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );
      vehicleRegistrationWithoutChassisNumber["engineSize"]._value =
        selectedOptionEngineSize.label;
      vehicleRegistrationWithoutChassisNumber["engineSize"].touched = true;
      return {
        vehicleRegistrationWithoutChassisNumber,
        selectedOptionEngineSize,
      };
    });
  };
  onChangeEmirate = (selectedOptionEmirates) => {
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );
      vehicleRegistrationWithoutChassisNumber["dLEmirate"]._value =
        selectedOptionEmirates.label;
      vehicleRegistrationWithoutChassisNumber["dLEmirate"].touched = true;
      return {
        vehicleRegistrationWithoutChassisNumber,
        selectedOptionEmirates,
      };
    });
  };
  onChangeTrim = (selectedOptionTrim) => {
    this.setState((prevState) => {
      let vehicleRegistrationWithoutChassisNumber = Object.assign(
        {},
        prevState.vehicleRegistrationWithoutChassisNumber
      );
      vehicleRegistrationWithoutChassisNumber["trimValue"]._value =
        selectedOptionTrim.label;
      vehicleRegistrationWithoutChassisNumber["trimValue"].touched = true;
      this.getBodyTypeApi();

      return { vehicleRegistrationWithoutChassisNumber, selectedOptionTrim };
    });
  };
  handleDocumentDetailsValidationsMulkiya() {
    let error = false;
    if (!this.state.documentDetailsMulkiya.documentMulkiyaF.touched1)
      if (
        this.state.documentDetailsMulkiya.documentMulkiyaF.required &&
        !this.state.documentDetailsMulkiya.documentMulkiyaF._value1.files
          .length > 0
      ) {
        error = true;
      } else error = false;

    if (!this.state.documentDetailsMulkiya.documentMulkiyaB.touched2)
      if (
        this.state.documentDetailsMulkiya.documentMulkiyaB.required &&
        !this.state.documentDetailsMulkiya.documentMulkiyaB._value2.files
          .length > 0
      ) {
        error = true;
      } else error = false;
    if (error) return error;

    return error;
  }
  handleDocumentDetailsValidationsLicence() {
    let error = false;
    if (!this.state.documentDetailsLicence.documentLicenceF.touched1)
      if (
        this.state.documentDetailsLicence.documentLicenceF.required &&
        !this.state.documentDetailsLicence.documentLicenceF._value1.files
          .length > 0
      ) {
        error = true;
      } else error = false;

    if (!this.state.documentDetailsLicence.documentLicenceB.touched2)
      if (
        this.state.documentDetailsLicence.documentLicenceB.required &&
        !this.state.documentDetailsLicence.documentLicenceB._value2.files
          .length > 0
      ) {
        error = true;
      } else error = false;
    if (error) return error;

    return error;
  }
  handleDocumentDetailsValidationsEmiratesID() {
    let error = false;
    if (!this.state.documentDetailsEmiratesID.documentEmiratesIDF.touched1)
      if (
        this.state.documentDetailsEmiratesID.documentEmiratesIDF.required &&
        !this.state.documentDetailsEmiratesID.documentEmiratesIDF._value1.files
          .length > 0
      ) {
        error = true;
      } else error = false;

    if (!this.state.documentDetailsEmiratesID.documentEmiratesIDB.touched2)
      if (
        this.state.documentDetailsEmiratesID.documentEmiratesIDB.required &&
        !this.state.documentDetailsEmiratesID.documentEmiratesIDB._value2.files
          .length > 0
      ) {
        error = true;
      } else error = false;
    if (error) return error;

    return error;
  }

  resetDocumentsMulkiya() {
    this.state.documentDetailsMulkiya.documentMulkiyaF._value1 = "";
    this.state.documentDetailsMulkiya.documentMulkiyaF.touched1 = false;
    this.state.documentDetailsMulkiya.documentMulkiyaB._value2 = "";
    this.state.documentDetailsMulkiya.documentMulkiyaB.touched2 = false;
    let documentDetail = Object.assign({}, this.state.documentDetailsMulkiya);
    this.setState({
      documentDetailsMulkiya: documentDetail,
    });
  }
  resetDocumentsLicence() {
    this.state.documentDetailsLicence.documentLicenceF._value1 = "";
    this.state.documentDetailsLicence.documentLicenceF.touched1 = false;
    this.state.documentDetailsLicence.documentLicenceB._value2 = "";
    this.state.documentDetailsLicence.documentLicenceB.touched2 = false;
    let documentDetail = Object.assign({}, this.state.documentDetailsLicence);
    this.setState({
      documentDetailsLicence: documentDetail,
    });
  }
  resetDocumentsEmiratesID() {
    this.state.documentDetailsEmiratesID.documentEmiratesIDF._value1 = "";
    this.state.documentDetailsEmiratesID.documentEmiratesIDF.touched1 = false;
    this.state.documentDetailsEmiratesID.documentEmiratesIDB._value2 = "";
    this.state.documentDetailsEmiratesID.documentEmiratesIDB.touched2 = false;
    let documentDetail = Object.assign(
      {},
      this.state.documentDetailsEmiratesID
    );
    this.setState({
      documentDetailsEmiratesID: documentDetail,
    });
  }
  handledocumentDetailsValidationMulkiya() {
    let error = false;

    let file = this.state.documentDetailsMulkiya;
    for (var i in file) {
      if (!file["documentMulkiyaF"]._value1) {
        console.log("test", i);
        error = true;
      }

      if (!file["documentMulkiyaB"]._value2) {
        console.log("test", i);
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handledocumentDetailsValidationLicence() {
    let error = false;

    let file = this.state.documentDetailsLicence;
    for (var i in file) {
      if (!file["documentLicenceF"]._value1) {
        console.log("test", i);
        error = true;
      }

      if (!file["documentLicenceB"]._value2) {
        console.log("test", i);
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handledocumentDetailsValidationEmiratesID() {
    let error = false;

    let file = this.state.documentDetailsEmiratesID;
    for (var i in file) {
      if (!file["documentEmiratesIDF"]._value1) {
        console.log("test", i);
        error = true;
      }

      if (!file["documentEmiratesIDB"]._value2) {
        console.log("test", i);
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handlefileDetailsValidationsMulkiya() {
    let error = false;

    let file = this.state.fileDetailsMulkiya;
    for (var i in file) {
      if (file[i].required && !file[i]._value) {
        console.log("test", i);
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handlefileDetailsValidationsLicence() {
    let error = false;

    let file = this.state.fileDetailsLicence;
    for (var i in file) {
      if (file[i].required && !file[i]._value) {
        console.log("test", i);
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  handlefileDetailsValidationsEmiratesID() {
    let error = false;

    let file = this.state.fileDetailsEmiratesID;
    for (var i in file) {
      if (file[i].required && !file[i]._value) {
        console.log("test", i);
        error = true;
      }
      if (error) return error;
    }
    return error;
  }
  collapseQuote() {
    this.setState((prevState) => {
      let collapse = Object.assign({}, prevState.collapse);
      let showSlide = Object.assign({}, prevState.showSlide);
      let showBackground = Object.assign({}, prevState.showBackground);
      let largeWidthSidePanel = Object.assign(
        {},
        prevState.largeWidthSidePanel
      );
      console.log("collapse", collapse);
      collapse = !prevState.collapse;
      console.log("collapse", collapse);
      if (collapse) {
        largeWidthSidePanel = "largeWidth";
        showBackground = "show";
        //   console.log('className',this.state.className,collapse)
      } else {
        largeWidthSidePanel = "";
        showBackground = "";
        showSlide = false;
      }
      return { collapse, largeWidthSidePanel, showBackground, showSlide };
    });
  }
  displayCarValues() {
    this.setState((prevState) => {
      let displayCarValues = Object.assign({}, prevState.displayCarValues);
      let closePopup = Object.assign({}, prevState.closePopup);
      let displayCarValue = Object.assign({}, prevState.displayCarValue);
      displayCarValue = !prevState.displayCarValue;
      if (displayCarValue) {
        closePopup = "show";
        displayCarValues = "block";
      } else {
        closePopup = "";
        displayCarValues = "none";
      }
      return {
        displayCarValue,
        displayCarValues: displayCarValues,
        displayCarDetails: "none",
        displayCarDetail: false,
        displayPersonals: "none",
        displayPersonal: false,
        closePopup: closePopup,
      };
    });
  }
  displayPersonal() {
    this.setState((prevState) => {
      let displayPersonal = Object.assign({}, prevState.displayPersonal);
      let closePopup = Object.assign({}, prevState.closePopup);
      let displayPersonals = Object.assign({}, prevState.displayPersonals);
      displayPersonal = !prevState.displayPersonal;
      if (displayPersonal) {
        displayPersonals = "block";
        closePopup = "show";
      } else {
        closePopup = "";
        displayPersonals = "none";
      }
      return {
        displayPersonal,
        displayPersonals: displayPersonals,
        displayCarDetails: "none",
        displayCarDetail: false,
        displayCarValues: "none",
        displayCarValue: false,
        closePopup: closePopup,
      };
    });
  }
  displayCarDetails() {
    this.setState((prevState) => {
      let displayCarDetail = Object.assign({}, prevState.displayCarDetail);
      let displayCarDetails = Object.assign({}, prevState.displayCarDetails);
      let closePopup = Object.assign({}, prevState.closePopup);
      displayCarDetail = !prevState.displayCarDetail;
      if (displayCarDetail) {
        closePopup = "show";
        displayCarDetails = "block";
      } else {
        closePopup = "";
        displayCarDetails = "none";
      }
      return {
        displayCarDetail,
        displayCarDetails: displayCarDetails,
        displayCarValues: "none",
        displayCarValue: false,
        displayPersonals: "none",
        displayPersonal: false,
        closePopup: closePopup,
      };
    });
  }

  //Benny Function

  displayAgency() {
    this.setState((prevState) => {
      let displayAgencys = Object.assign({}, prevState.displayAgencys);
      let displayAgency = Object.assign({}, prevState.displayAgency);
      let closePopup = Object.assign({}, prevState.closePopup);
      displayAgency = !prevState.displayAgency;
      if (displayAgency) {
        displayAgencys = "block";
        closePopup = "show";
      } else {
        displayAgencys = "none";
        closePopup = "";
      }
      return {
        displayAgency,
        displayAgencys: displayAgencys,
        displayCarValues: "none",
        displayCarValue: false,
        displayPersonals: "none",
        displayPersonal: false,
        closePopup: closePopup,
      };
    });
  }

  displaySortFilter() {
    this.setState((prevState) => {
      let displaySorts = Object.assign({}, prevState.displaySorts);
      let displaySort = Object.assign({}, prevState.displaySort);
      let closePopup = Object.assign({}, prevState.closePopup);
      displaySort = !prevState.displaySort;
      if (displaySort) {
        displaySorts = "block";
        closePopup = "show";
      } else {
        displaySorts = "none";
        closePopup = "";
      }
      return {
        displaySort,
        displaySorts: displaySorts,
        displayCarValues: "none",
        displayCarValue: false,
        displayPersonals: "none",
        displayPersonal: false,
        closePopup: closePopup,
      };
    });
  }

  //Benny Function End

  collapse() {
    this.setState((prevState) => {
      let collapse = Object.assign({}, prevState.collapse);
      let showBackground = Object.assign({}, prevState.showBackground);
      let showSlide = Object.assign({}, prevState.showSlide);
      let largeWidthSidePanel = Object.assign(
        {},
        prevState.largeWidthSidePanel
      );
      console.log("collapse", collapse);
      collapse = !prevState.collapse;
      console.log("collapse", collapse);
      if (collapse) {
        largeWidthSidePanel = "largeWidth";
        showBackground = "show";
        //   console.log('className',this.state.className,collapse)
      } else {
        largeWidthSidePanel = "";
        showBackground = "";
        // this.state.collapse = true;
      }
      return { collapse, largeWidthSidePanel, showBackground, showSlide };
    });
  }

  loadMore() {
    this.setState((prevState) => {
      let visible = Object.assign({}, prevState.visible);
      visible = !prevState.visible;
      return { visible };
    });
  }
  // formatInputValue() {
  //     // console.log(day)
  //     // let date = {
  //     //   "month": day.month,
  //     //   "year": day.year
  //     // }
  //     // let value = day.day + "/" + date["month"] + "/" + date["year"];
  //     this.setState(prevState => {
  //         let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

  //         // fileDetailsMulkiya['regDate']._value.day;
  //         // fileDetailsEmiratesID['regDate']._values = value;
  //         // fileDetailsEmiratesID['regDate'].touched = true;

  //         return `Day: ${fileDetailsMulkiya.regDate._value.day}`;
  //     })
  //   }
  // // formatInputValue(){

  // //     // this.setState(prevState => {
  // //         let fileDetailsMulkiya = Object.assign({}, prevState.fileDetailsMulkiya);

  // //     if (!fileDetailsMulkiya.regDate._value) return '';
  // // return `Day: ${fileDetailsMulkiya.regDate._value.day}`;
  // //     })
  // // }
  uploadMulkiya() {
    this.setState({
      submitDocumentDetailsMulkiya: true,
    });

    this.state.submitDocumentDetailsMulkiya = true;
    if (this.handledocumentDetailsValidationMulkiya()) {
      return;
    }

    if (this.state.submitDocumentDetailsMulkiya) {
      this.setState({ loader: true, closePopup1: "show" });
      let post = {
        headers: new Headers({
          Accept: "text/plain;charset=UTF-8",
          "Content-Type": "text/plain;charset=UTF-8",
          "Access-Control-Allow-Origin": this.state.https,
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        }),
        mode: "no-cors",
        data: {
          UserName: this.state.personalDetails.email._value,
          FileGroup: "Mulkiya",
          PlateCode: this.state.fileDetailsMulkiya.plateNo._value,
          PlateNumber: this.state.fileDetailsMulkiya.plateCategory._value,
          OCRFiles: [
            {
              FileType: "Mulkiya-F",
              File: this.state.documentDetailsMulkiya.documentMulkiyaF._value1
                ? this.state.documentDetailsMulkiya.documentMulkiyaF._value1.dataURL.replace(
                    "data:image/png;base64,",
                    ""
                  )
                : "",
              FileExtesnion: this.state.documentDetailsMulkiya.documentMulkiyaF
                ._value1.type
                ? this.state.documentDetailsMulkiya.documentMulkiyaF._value1
                    .type
                : "",
              FileName: this.state.documentDetailsMulkiya.documentMulkiyaF
                ._value1.name
                ? this.state.documentDetailsMulkiya.documentMulkiyaF._value1
                    .name
                : "",
            },
            {
              FileType: "Mulkiya-B",
              File: this.state.documentDetailsMulkiya.documentMulkiyaB._value2
                ? this.state.documentDetailsMulkiya.documentMulkiyaB._value2.dataURL.replace(
                    "data:image/png;base64,",
                    ""
                  )
                : "",
              FileExtesnion: this.state.documentDetailsMulkiya.documentMulkiyaB
                ._value2.type
                ? this.state.documentDetailsMulkiya.documentMulkiyaB._value2
                    .type
                : "",
              FileName: this.state.documentDetailsMulkiya.documentMulkiyaB
                ._value2.name
                ? this.state.documentDetailsMulkiya.documentMulkiyaB._value2
                    .name
                : "",
            },
          ],
        },
      };
      this.state.responseMsg = "";
      axios
        .post(this.state.api + `Vehicle/UploadOCRDocument`, post.data)
        .then((response) => {
          this.state.responseMsg =
            "Uploaded And Fetched OCR Details Successfully";
          if (response.status === 200 && response.data.Result === "Success") {
          } else {
            this.state.responseMsg = "Failed to Fetch OCR Details";
          }

          console.log(response, response.data.Message, response.data.Result);
          // this.resetDocumentsMulkiya();
          if (
            response &&
            response.status === 200 &&
            typeof response.data.Result !== "undefined"
          ) {
            debugger;
            //   this.state.fileDetailsMulkiya.plateNo._value = response.data.PlateNumber !== null ? response.data.PlateNumber : '';
            this.state.fileDetailsMulkiya.documentDetailsID._value =
              response.data.DocumentDetailsID !== null
                ? response.data.DocumentDetailsID
                : "";
            //   this.state.fileDetailsMulkiya.plateCategory._value = response.data.PlateCode !== null ? response.data.PlateCode : '';
            this.state.fileDetailsMulkiya.insExp._value =
              response.data.Mulkiya.InsExpDate !== null
                ? response.data.Mulkiya.InsExpDate
                : this.state.fileDetailsMulkiya.insExp._value;
            this.state.fileDetailsMulkiya.regDate._value =
              response.data.Mulkiya.VehicleRegDate !== null
                ? response.data.Mulkiya.VehicleRegDate
                : this.state.fileDetailsMulkiya.regDate._value;
            this.state.fileDetailsMulkiya.chassisNumber._value =
              response.data.Mulkiya.ChassisNo !== null
                ? response.data.Mulkiya.ChassisNo
                : this.state.fileDetailsMulkiya.chassisNumber._value;
            this.state.fileDetailsMulkiya.engineNumber._value =
              response.data.Mulkiya.EngineNo !== null
                ? response.data.Mulkiya.EngineNo
                : this.state.fileDetailsMulkiya.engineNumber._value;
            //   this.state.fileDetailsMulkiya.engineSize._value = response.data.Mulkiya.EngineNo !== null ? response.data.Mulkiya.EngineNo : '';
            this.state.fileDetailsMulkiya.expDate._value =
              response.data.Mulkiya.InsExpDate !== null
                ? response.data.Mulkiya.InsExpDate
                : this.state.fileDetailsMulkiya.expDate._value;
            //   this.state.fileDetailsMulkiya.trim._value = '';
            this.state.fileDetailsMulkiya.cylinder._value = this.state
              .fileDetailsMulkiya.cylinder._value
              ? this.state.fileDetailsMulkiya.cylinder._value
              : "";

            this.setState((prevState) => {
              let fileDetailMulkiya = Object.assign(
                {},
                prevState.fileDetailsMulkiya
              );
              return {
                fileDetailsMulkiya: fileDetailMulkiya,
                loader: false,
                disableMulkiyabtn: true,
                closePopup1: "",
              };
            });
          } else {
            this.setState({
              loader: false,
              closePopup1: "",
            });
          }
          return response;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            // this.setState({ loader: false, smShow: true, closePopup1: '' });
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            // this.setState({ loader: false, smShow: true, closePopup1: '' });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            // this.setState({ loader: false, smShow: true, closePopup1: '' });
          }
        });
      //   }
    }
  }
  uploadLicence() {
    this.setState({
      submitDocumentDetailsLicence: true,
    });

    this.state.submitDocumentDetailsLicence = true;
    if (this.handledocumentDetailsValidationLicence()) {
      return;
    }

    if (this.state.submitDocumentDetailsLicence) {
      this.setState({ loader: true, closePopup2: "show" });
      let post = {
        headers: new Headers({
          Accept: "text/plain;charset=UTF-8",
          "Content-Type": "text/plain;charset=UTF-8",
          "Access-Control-Allow-Origin": this.state.https,
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        }),
        mode: "no-cors",
        data: {
          UserName: this.state.personalDetails.email._value,
          FileGroup: "DrivingLicense",
          PlateCode: this.state.fileDetailsMulkiya.plateNo._value,
          PlateNumber: this.state.fileDetailsMulkiya.plateCategory._value,
          OCRFiles: [
            {
              FileType: "DL-F",
              File: this.state.documentDetailsLicence.documentLicenceF._value1.dataURL.replace(
                "data:image/png;base64,",
                ""
              ),
              FileExtesnion:
                this.state.documentDetailsLicence.documentLicenceF._value1.type,
              FileName:
                this.state.documentDetailsLicence.documentLicenceF._value1.name,
            },
            {
              FileType: "DL-B",
              File: this.state.documentDetailsLicence.documentLicenceB._value2.dataURL.replace(
                "data:image/png;base64,",
                ""
              ),
              FileExtesnion:
                this.state.documentDetailsLicence.documentLicenceB._value2.type,
              FileName:
                this.state.documentDetailsLicence.documentLicenceB._value2.name,
            },
          ],
        },
      };
      console.log("post", post.data);
      this.state.responseMsg = "";
      axios
        .post(this.state.api + `Vehicle/UploadOCRDocument`, post.data)
        .then((response) => {
          if (response.status === 200 && response.data.Result === "Success") {
          } else {
          }
          console.log(response);
          // this.resetDocumentsLicence();
          if (
            response &&
            response.status === 200 &&
            typeof response.data.Result !== "undefined"
          ) {
            // PlateNumber PlateCode UserName
            this.state.fileDetailsLicence.licenceNo._value =
              response.data.DrivingLicense.DrivingLicenseNo !== null
                ? response.data.DrivingLicense.DrivingLicenseNo
                : this.state.fileDetailsLicence.licenceNo._value;
            this.state.fileDetailsLicence.placeofIssue._value =
              response.data.DrivingLicense.PlaceOfIssue !== null
                ? response.data.DrivingLicense.PlaceOfIssue
                : this.state.fileDetailsLicence.placeofIssue._value;

            this.state.fileDetailsLicence.issueDate._value =
              response.data.DrivingLicense.DLIssueDate !== null
                ? response.data.DrivingLicense.DLIssueDate
                : this.state.fileDetailsLicence.issueDate._value;
            this.state.fileDetailsLicence.expiryDate._value =
              response.data.DrivingLicense.DLExpDate !== null
                ? response.data.DrivingLicense.dlExpDate
                : this.state.fileDetailsLicence.expiryDate._value;
            this.state.fileDetailsLicence.TCNo._value =
              response.data.DrivingLicense.TCNo !== null
                ? response.data.DrivingLicense.TCNo
                : this.state.fileDetailsLicence.TCNo._value;

            this.setState((prevState) => {
              let fileDetailLicence = Object.assign(
                {},
                prevState.fileDetailsLicence
              );
              return {
                fileDetailsLicence: fileDetailLicence,
                loader: false,
                disableLicencebtn: true,
                closePopup2: "",
              };
            });
          } else {
            this.setState({
              loader: false,
              closePopup2: "",
            });
          }
          return response;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            // this.setState({ loader: false, smShow: true, closePopup2: '' });
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            // this.setState({ loader: false, smShow: true, closePopup2: '' });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            // this.setState({ loader: false, smShow: true, closePopup2: '' });
          }
        });
      // }
    }
  }
  uploadEmiratesID() {
    this.setState({
      submitDocumentDetailsEmiratesID: true,
    });

    this.state.submitDocumentDetailsEmiratesID = true;
    if (this.handledocumentDetailsValidationEmiratesID()) {
      return;
    }

    if (this.state.submitDocumentDetailsEmiratesID) {
      this.setState({ loader: true, closePopup3: "show" });
      let post = {
        headers: new Headers({
          Accept: "text/plain;charset=UTF-8",
          "Content-Type": "text/plain;charset=UTF-8",
          "Access-Control-Allow-Origin": this.state.https,
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        }),
        mode: "no-cors",
        data: {
          UserName: this.state.personalDetails.email._value,
          FileGroup: "Emirates",
          PlateCode: this.state.fileDetailsMulkiya.plateNo._value,
          PlateNumber: this.state.fileDetailsMulkiya.plateCategory._value,
          OCRFiles: [
            {
              FileType: "EID-F",
              File: this.state.documentDetailsEmiratesID.documentEmiratesIDF._value1.dataURL.replace(
                "data:image/png;base64,",
                ""
              ),
              FileExtesnion:
                this.state.documentDetailsEmiratesID.documentEmiratesIDF._value1
                  .type,
              FileName:
                this.state.documentDetailsEmiratesID.documentEmiratesIDF._value1
                  .name,
            },
            {
              FileType: "EID-B",
              File: this.state.documentDetailsEmiratesID.documentEmiratesIDB._value2.dataURL.replace(
                "data:image/png;base64,",
                ""
              ),
              FileExtesnion:
                this.state.documentDetailsEmiratesID.documentEmiratesIDB._value2
                  .type,
              FileName:
                this.state.documentDetailsEmiratesID.documentEmiratesIDB._value2
                  .name,
            },
          ],
        },
      };
      this.state.responseMsg = "";
      axios
        .post(this.state.api + `Vehicle/UploadOCRDocument`, post.data)
        .then((response) => {
          this.state.responseMsg =
            "Uploaded And Fetched OCR Details Successfully";
          if (response.status === 200 && response.data.Result === "Success") {
          } else {
            this.state.responseMsg = "Failed to Fetch OCR Details";
          }
          console.log(response);
          // this.resetDocumentsEmiratesID();
          if (
            response &&
            response.status === 200 &&
            typeof response.data.Result !== "undefined"
          ) {
            this.state.fileDetailsEmiratesID.nationality._value =
              response.data.Emirate.Nationality !== null
                ? response.data.Emirate.Nationality
                : this.state.fileDetailsEmiratesID.nationality._value;
            this.state.fileDetailsEmiratesID.emiratesID._value =
              response.data.Emirate.EmiratesID !== null
                ? response.data.Emirate.EmiratesID
                : this.state.fileDetailsEmiratesID.emiratesID._value;

            this.state.fileDetailsEmiratesID.expiryDate._value =
              response.data.Emirate.EmiratesExpDate !== null
                ? response.data.Emirate.EmiratesExpDate
                : this.state.fileDetailsEmiratesID.expiryDate._value;
            this.state.fileDetailsEmiratesID.dateofBirth._value =
              response.data.Emirate.DOB !== null
                ? response.data.Emirate.DOB
                : this.state.fileDetailsEmiratesID.dateofBirth._value;
            this.state.fileDetailsEmiratesID.gender._value =
              response.data.Emirate.Gender !== null
                ? response.data.Emirate.Gender
                : this.state.fileDetailsEmiratesID.gender._value;

            this.setState((prevState) => {
              let fileDetailEmiratesID = Object.assign(
                {},
                prevState.fileDetailsEmiratesID
              );
              return {
                fileDetailsEmiratesID: fileDetailEmiratesID,
                loader: false,
                disableEmiratesbtn: true,
                closePopup3: "",
              };
            });
          } else {
            this.setState({
              loader: false,
              closePopup3: "",
            });
          }
          return response;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            // this.setState({ loader: false, smShow: true, closePopup3: '' });
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            // this.setState({ loader: false, smShow: true, closePopup3: '' });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            // this.setState({ loader: false, smShow: true, closePopup3: '' });
          }
        });
    }
  }
  _next() {
    if (this.state.currentStep === 1) {
      this.setState({
        submitfileDetailsMulkiya: true,
      });

      this.state.submitfileDetailsMulkiya = true;

      this.setState({
        submitDocumentDetailsMulkiya: true,
      });

      this.state.submitDocumentDetailsMulkiya = true;
      if (this.handledocumentDetailsValidationMulkiya()) {
        return;
      }
      if (this.handlefileDetailsValidationsMulkiya()) {
        return;
      }

      //Upload Mulkiya
      if (this.state.submitfileDetailsMulkiya) {
        debugger;
        this.setState({ loader: true });
        let post = {
          headers: new Headers({
            Accept: "text/plain;charset=UTF-8",
            "Content-Type": "text/plain;charset=UTF-8",
            "Access-Control-Allow-Origin": this.state.https,
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          }),
          mode: "no-cors",
          data: {
            userName: this.state.personalDetails.email._value,
            plateCode: this.state.fileDetailsMulkiya.plateNo._value,
            plateNumber: this.state.fileDetailsMulkiya.plateCategory._value,
            noChange: true,
            fileGroup: "Mulkiya",
            result: "",
            errorDesc: "",
            documentDetailsID:
              this.state.fileDetailsMulkiya.documentDetailsID._value,
            mulkiya: {
              vehicleRegDate: this.fnMulkiyaRegDateChange(
                this.state.fileDetailsMulkiya.regDate._value
              ),
              insExpDate: this.DBDateFormat(
                this.state.fileDetailsMulkiya.insExp._values
              ),
              chassisNo: this.state.fileDetailsMulkiya.chassisNumber._value,
              engineNo: this.state.fileDetailsMulkiya.engineNumber._value,
            },
            drivingLicense: {
              drivingLicenseNo: "",
              placeOfIssue: "",
              dlIssueDate: "",
              dlExpDate: "",
              tcNo: "",
            },
            emirate: {
              emiratesID: "",
              gender: "",
              emiratesExpDate: "",
              nationality: "",
              dob: "",
            },
          },
        };
        axios
          .post(this.state.api + `Vehicle/UpdateOCRDocumentDetails`, post.data)
          .then((response) => {
            this.state.vehicleDetailsID = response.data.VehicleDetailsID;
            if (response.status === 200 && response.data.Status === "Success") {
              this.state.vehicleDetailsID = response.data.VehicleDetailsID;
              this.state.responseMsg =
                response.data.Status === null
                  ? "Success"
                  : response.data.Status;
              this.setState({ loader: false });

              //Add to DB
              debugger;
              post.data.CustomerId = this.state.vehicleId;
              this.fnSaveOcrDetails(post.data);
            } else {
              // this.setState({ loader: false });
              // this.state.responseMsg = response.data.Status === null ? 'Failure' : response.data.Status;

              if (this.state.vehicleDetailsID === null) {
                this.state.vehicleDetailsID =
                  this.state.quoteList.length > 0
                    ? this.state.quoteList[0].VehicleDetailsID
                    : 0;
                this.state.responseMsg =
                  "Please Match Details With Uploaded Mulkiya Card Details";
                this.setState({ loader: false, smShow: true });
                return response;
              } else {
                this.state.responseMsg =
                  response.data.Status === null
                    ? "Success"
                    : response.data.Status;
                this.setState({ loader: false });
              }
            }
            return response;
          })
          .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              // this.setState({ loader: false, smShow: true });
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              // this.setState({ loader: false, smShow: true });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              // this.setState({ loader: false, smShow: true });
            }
          });
      }
    } else if (this.state.currentStep === 2) {
      this.setState({
        submitfileDetailsLicence: true,
      });

      this.state.submitfileDetailsLicence = true;

      this.setState({
        submitDocumentDetailsLicence: true,
      });

      this.state.submitDocumentDetailsLicence = true;
      if (this.handledocumentDetailsValidationLicence()) {
        return;
      }
      if (this.handlefileDetailsValidationsLicence()) {
        return;
      }

      if (this.state.submitfileDetailsLicence) {
        debugger;
        this.setState({ loader: true });
        let post = {
          headers: new Headers({
            Accept: "text/plain;charset=UTF-8",
            "Content-Type": "text/plain;charset=UTF-8",
            "Access-Control-Allow-Origin": this.state.https,
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          }),
          mode: "no-cors",
          data: {
            userName: this.state.personalDetails.email._value,
            plateCode: this.state.fileDetailsMulkiya.plateNo._value,
            plateNumber: this.state.fileDetailsMulkiya.plateCategory._value,
            noChange: true,
            fileGroup: "Driving License",
            result: "",
            errorDesc: "",
            documentDetailsID:
              this.state.fileDetailsMulkiya.documentDetailsID._value,
            mulkiya: {
              vehicleRegDate: "",
              insExpDate: "",
              chassisNo: "",
              engineNo: "",
            },
            drivingLicense: {
              drivingLicenseNo: this.state.fileDetailsLicence.licenceNo._value,
              placeOfIssue: this.state.fileDetailsLicence.placeofIssue._value,
              dlIssueDate: this.DBDateFormat(
                this.state.fileDetailsLicence.issueDate._values
              ),
              dlExpDate: this.DBDateFormat(
                this.state.fileDetailsLicence.expiryDate._values
              ),
              tcNo: this.state.fileDetailsLicence.TCNo._value,
            },
            emirate: {
              emiratesID: "",
              gender: "",
              emiratesExpDate: "",
              nationality: "",
              dob: "",
            },
          },
        };
        axios
          .post(this.state.api + `Vehicle/UpdateOCRDocumentDetails`, post.data)
          .then((response) => {
            if (response.status === 200 && response.data.Status === "Success") {
              this.state.vehicleDetailsID = response.data.VehicleDetailsID;
              this.state.responseMsg =
                response.data.Status === null
                  ? "Success"
                  : response.data.Status;
              this.setState({ loader: false });

              //Add to DB
              post.data.CustomerId = this.state.vehicleId;
              this.fnSaveOcrDetails(post.data);
            }
            return response;
          })
          .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              // this.setState({ loader: false, smShow: true });
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              // this.setState({ loader: false, smShow: true });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              // this.setState({ loader: false, smShow: true });
            }
          });
        this.setState({ loader: false });
      }
    } else if (this.state.currentStep === 3) {
      this.setState({
        submitDocumentDetailsEmiratesID: true,
      });

      this.state.submitDocumentDetailsEmiratesID = true;

      this.setState({
        submitfileDetailsEmiratesID: true,
      });

      this.state.submitfileDetailsEmiratesID = true;
      if (this.handledocumentDetailsValidationEmiratesID()) {
        return;
      }
      if (this.handlefileDetailsValidationsEmiratesID()) {
        return;
      }

      if (this.state.submitfileDetailsEmiratesID) {
        debugger;
        this.setState({ loader: true, closePopup3: "show" });
        let post = {
          headers: new Headers({
            Accept: "text/plain;charset=UTF-8",
            "Content-Type": "text/plain;charset=UTF-8",
            "Access-Control-Allow-Origin": this.state.https,
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          }),
          mode: "no-cors",
          data: {
            UserName: this.state.personalDetails.email._value,
            PlateCode: this.state.fileDetailsMulkiya.plateNo._value,
            PlateNumber: this.state.fileDetailsMulkiya.plateCategory._value,
            NoChange: false,
            FileGroup: "Emirates",
            Result: "",
            ErrorDesc: "",
            DocumentDetailsID:
              this.state.fileDetailsMulkiya.documentDetailsID._value,
            Mulkiya: {
              vehicleRegDate: "",
              insExpDate: "",
              chassisNo: "",
              engineNo: "",
            },
            drivingLicense: {
              drivingLicenseNo: "",
              placeOfIssue: "",
              dlIssueDate: "",
              dlExpDate: "",
              tcNo: "",
            },
            Emirate: {
              EmiratesID: this.state.fileDetailsEmiratesID.emiratesID._value,
              Gender: this.state.fileDetailsEmiratesID.gender._value,
              EmiratesExpDate: this.DBDateFormat(
                this.state.fileDetailsEmiratesID.expiryDate._values
              ),
              Nationality: this.state.fileDetailsEmiratesID.nationality._value,
              DOB: this.DBDateFormat(
                this.state.fileDetailsEmiratesID.dateofBirth._values
              ),
            },
          },
        };
        axios
          .post(this.state.api + `Vehicle/UpdateOCRDocumentDetails`, post.data)
          .then((response) => {
            if (response.status === 200) {
              // this.state.vehicleDetailsID =;

              let post2 = {
                headers: new Headers({
                  Accept: "text/plain;charset=UTF-8",
                  "Content-Type": "text/plain;charset=UTF-8",
                  "Access-Control-Allow-Origin": this.state.https,
                  Authorization: `Bearer ${localStorage.getItem("user")}`,
                }),
                mode: "no-cors",
                data: {
                  VehicleDetailsID: this.state.vehicleDetailsID,
                  //   "VehicleDetailsID": 36357
                },
                data2: {
                  // "VehicleDetailsID": this.state.vehicleDetailsID,
                  VehicleDetailsID: 36357,
                },
              };
              console.log("post", post2.data, response.data.VehicleDetailsID);
              axios
                .post(
                  this.state.api + `Vehicle/GetMotorActualQuotes`,
                  post2.data
                )
                .then((response) => {
                  //Set Actual Quote
                  this.setState({ stateActualQuote: 1 });

                  if (
                    response.data.Result === "Success" &&
                    response.data.Result !== null &&
                    response.data.QuoteList.length > 0
                  ) {
                    let vehicleDetailsId = response.data.QuoteList;
                    let finalQuotes = [];
                    this.state.quoteList = [];
                    this.state.quoteList.push(vehicleDetailsId);

                    console.log(this.state.quoteList, vehicleDetailsId);
                    let finalquoteList = Object.assign(
                      {},
                      this.state.quoteList
                    );
                    finalquoteList = this.state.quoteList[0];
                    this.state.collapse = true;
                    console.log(
                      this.state.quoteList,
                      finalquoteList,
                      this.state.collapse
                    );
                    console.log(this.state.collapse);
                    this.setState({
                      loader: false,
                      collapse: true,
                      quoteList: finalquoteList,
                      closePopup3: "",
                    });
                    this.collapseQuote();
                    if (this.state.quoteList.length > 0) {
                      console.log(this.state.quoteList, finalQuotes);
                      this.setState({
                        vehicleDetailsIDs: finalQuotes,
                        loader: false,
                        smShow: false,
                        closePopup3: "",
                      });
                      this.setState({ tempQuote: finalquoteList });
                      console.log(" componentDidMount");
                      let insuranceNames = [];

                      insuranceNames = this.state.quoteList
                        .map((a) => a.InsName)
                        .filter((x, i, a) => a.indexOf(x) === i);

                      this.setState({ uniqueInsuranceName: insuranceNames });
                    }
                  } else {
                    //Hakkim
                    console.log("GetMotorActualQuotes response empty");

                    // axios.post(this.state.api + `Vehicle/GetMotorActualQuotes`, post2.data2)
                    // axios
                    //   .post(
                    //     this.state.api + `Vehicle/GetMotorQuotes`,
                    //     post2.data
                    //   )
                    //   .then((response) => {
                    //     let vehicleDetailsId = response.data.QuoteList;
                    //     let finalQuotes = [];
                    //     this.state.quoteList = [];
                    //     this.state.quoteList.push(vehicleDetailsId);
                    //     console.log(this.state.quoteList, vehicleDetailsId);
                    //     let finalquoteList = Object.assign(
                    //       {},
                    //       this.state.quoteList
                    //     );
                    //     finalquoteList = this.state.quoteList[0];
                    //     this.state.collapse = true;
                    //     console.log(
                    //       this.state.quoteList,
                    //       finalquoteList,
                    //       this.state.collapse
                    //     );
                    //     console.log(this.state.collapse);
                    //     this.setState({
                    //       loader: false,
                    //       closePopup3: "",
                    //       collapse: true,
                    //       quoteList: finalquoteList,
                    //     });
                    //     this.collapseQuote();
                    //     if (this.state.quoteList.length > 0) {
                    //       console.log(this.state.quoteList, finalQuotes);
                    //       this.setState({
                    //         vehicleDetailsIDs: finalQuotes,
                    //         loader: false,
                    //         closePopup3: "",
                    //         smShow: false,
                    //       });
                    //       this.setState({ tempQuote: finalquoteList });
                    //       console.log(" componentDidMount");
                    //       let insuranceNames = [];
                    //       insuranceNames = this.state.quoteList
                    //         .map((a) => a.InsName)
                    //         .filter((x, i, a) => a.indexOf(x) === i);
                    //       this.setState({
                    //         uniqueInsuranceName: insuranceNames,
                    //       });
                    //     }
                    //   })
                    //   .catch(function (error) {
                    //     if (error.response) {
                    //       // Request made and server responded
                    //       console.log(error.response.data);
                    //       console.log(error.response.status);
                    //       console.log(error.response.headers);
                    //       // this.setState({ loader: false, closePopup3: '', smShow: true });
                    //     } else if (error.request) {
                    //       // The request was made but no response was received
                    //       console.log(error.request);
                    //       // this.setState({ loader: false, closePopup3: '', smShow: true });
                    //     } else {
                    //       // Something happened in setting up the request that triggered an Error
                    //       console.log("Error", error.message);
                    //       // this.setState({ loader: false, closePopup3: '', smShow: true });
                    //     }
                    //   });
                  }
                });

              //Add to DB
              post.data.CustomerId = this.state.vehicleId;
              this.fnSaveOcrDetails(post.data);
            } else {
              this.state.responseMsg =
                response.data.Status === null
                  ? "Failure"
                  : response.data.Status;
              this.setState({ closePopup3: "", loader: false });
            }
            return response;
          })
          .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              // this.setState({ loader: false, closePopup3: '', smShow: true });
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              // this.setState({ loader: false, closePopup3: '', smShow: true });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              // this.setState({ loader: false, closePopup3: '', smShow: true });
            }
          });
      }
    } else {
    }
    let currentStep = this.state.currentStep;
    console.log(currentStep);
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    console.log(currentStep);
    this.setState({
      currentStep: currentStep,
    });
  }

  DBDateOfRegistrationFormat(data) {
    if (!data) {
      data = "";
      return data;
    }
    // console.log(data)
    // // data = new Date(data).toLocaleDateString();
    // data = data.day + "/" + data.month + "/" + data.year;
    // console.log(data)
    // data = data.split("/");
    // data = (data[0] < 10 ? "0" + data[0] : data[0]) + "/" + (data[1] < 10 ? "0" + data[1] : data[1]) + "/" + data[2];
    // console.log(data);
    return data;
  }
  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  DBDateFormat(date) {
    if (!date) {
      date = "";
      return date;
    }
    //console.log(date)
    // date = new Date(date).toLocaleDateString();
    date = date.split("/");
    // date = (date[0] < 10 ? "0" + date[0] : date[0]) + "/" + (date[1] < 10 ? "0" + date[1] : date[1]) + "/" + date[2];
    date =
      (date[0] < 10 ? "0" + date[0] : date[0]) +
      "/" +
      (date[1] < 10 ? "0" + date[1] : date[1]) +
      "/" +
      date[2];

    return date;
  }

  fnSaveOcrDetails(ocrdetails) {
    debugger;

    axios
      .post(this.state.api + `Document/UpdateOCR`, ocrdetails)
      .then((response) => {
        debugger;
        if (response.status === 200 && response.data.Status === "Success") {
          //Set Actual Quote
        } else {
        }
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          // this.setState({ loader: false, smShow: true });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          // this.setState({ loader: false, smShow: true });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          // this.setState({ loader: false, smShow: true });
        }
      });
  }

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-secondary float-center"
          type="button"
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep === 1) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Next
        </button>
      );
    }
    if (currentStep === 2) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Next
        </button>
      );
    }

    if (currentStep > 2) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Next
        </button>
      );
    }
    return null;
  }
  uploadFile() {
    let currentStep = this.state.currentStep;
    let disableMulkiyabtn = this.state.disableMulkiyabtn;
    //console.log("disableMulkiyabtn", disableMulkiyabtn);
    if (currentStep === 1) {
      return (
        <button
          className="btn btn-primary float-right"
          disabled={disableMulkiyabtn}
          type="button"
          onClick={this.uploadMulkiya}
        >
          Upload Mulkiya
        </button>
      );
    }
    if (currentStep === 2) {
      return (
        <button
          className="btn btn-primary float-right"
          disabled={this.state.disableLicencebtn}
          type="button"
          onClick={this.uploadLicence}
        >
          Upload Licence
        </button>
      );
    }

    if (currentStep > 2) {
      return (
        <button
          className="btn btn-primary float-right"
          disabled={this.state.disableEmiratesbtn}
          type="button"
          onClick={this.uploadEmiratesID}
        >
          Upload EmiratesID
        </button>
      );
    }
    return null;
  }
  render() {
    const {
      selectedOptionYear,
      selectedOptionMake,
      selectedOptionModel,
      selectedOptionTrim,
      selectedOptionBodyType,
      selectedOptionEngineSize,
      selectedOptionEmirates,
    } = this.state;

    return (
      <div>
        <div className={"overlay " + this.state.showBackground}></div>

        <div className="wrapper bg-light">
          <header className="pt-2 pb-2 mb-4 border-bottom bg-blue">
            <div className="container">
              <a
                href="/"
                className="d-flex align-items-center text-dark text-decoration-none"
              >
                <img alt="" src="img/bbc-1.png" width="250px" />
              </a>
            </div>
          </header>

          <div className="outer-body-wrap ">
            <div className="container">
              <div className="w-98">
                <div className="quotes-title">
                  <span>Filter</span>
                </div>
                <div className="top-panel">
                  <div className="top-box" id="carValue">
                    <div
                      className="d-flex custom-allign"
                      onClick={() => this.displayCarValues()}
                    >
                      <h3>Car Value:</h3>
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                  <div className="top-box" id="repairType">
                    <div
                      className="d-flex custom-allign"
                      onClick={() => this.displayAgency()}
                    >
                      <h3>Repair Type:</h3>
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                  <div className="top-box" id="personalDetail">
                    <div
                      className="d-flex custom-allign"
                      onClick={() => this.displayPersonal()}
                    >
                      <h3>Personal Details:</h3>
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                  <div
                    className="top-box"
                    id="otherDetail"
                    onClick={() => this.displayCarDetails()}
                  >
                    <div className="d-flex custom-allign">
                      <h3>Car Details:</h3>
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                  <div className="top-box" id="sort">
                    <div
                      className="d-flex custom-allign"
                      onClick={() => this.displaySortFilter()}
                    >
                      <h3>Sort / Filter</h3>
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                </div>
                <div className={"close-popup " + this.state.closePopup}></div>

                <div
                  className="custom_popup"
                  style={{ display: this.state.displayCarValues }}
                  id="carValuePopup"
                >
                  <div className="popup-overlay-box">
                    <div className="popup_heading">
                      <h2>Car Value</h2>
                      <span className="close-popup-btn">
                        <i
                          className="svg-close"
                          onClick={() => this.displayCarValues()}
                        >
                          <svg
                            id="Capa_1"
                            enable-background="new 0 0 413.348 413.348"
                            height="512"
                            viewBox="0 0 413.348 413.348"
                            width="512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"></path>
                          </svg>
                        </i>
                      </span>
                    </div>

                    <div className="popup-content">
                      <div className="icon-with-form">
                        <span className="icon fas fa-money-check-alt fa-lg"></span>
                        <input
                          type="number"
                          autoComplete="off"
                          name="VehiclePurchasePrice"
                          className="form-control"
                          placeholder="Enter Vehicle Purchase Price"
                          title="Vehicle Purchase Price"
                          onChange={this.onVehicleTxtPriceChange}
                          value={
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .vehicleTxtPrice._value
                          }
                        />
                      </div>
                      <div
                        className="slider-range"
                        style={{ paddingLeft: "20px", paddingRight: "25px" }}
                      >
                        <p className="text-center">
                          Vehicle Purchase Price: AED{" "}
                          <span id="demo">
                            <Label color="red">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .vehiclePrice._value
                              }
                            </Label>
                          </span>
                        </p>

                        <InputRange
                          background="#b0d039"
                          step={100}
                          maxValue={1000000}
                          minValue={20000}
                          value={
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .vehiclePrice._value
                          }
                          // onChange={value => this.state.setState({ value })} />
                          onChange={(value) => {
                            let vehicleRegistrationWithoutChassisNumberVehicleValue =
                              Object.assign(
                                {},
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                              );

                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehiclePrice"
                            ]._value = value;
                            let currentYear = new Date();
                            let years =
                              currentYear.getFullYear() -
                              vehicleRegistrationWithoutChassisNumberVehicleValue[
                                "modelYear"
                              ]._value;
                            let vehicleActualPrice =
                              vehicleRegistrationWithoutChassisNumberVehicleValue[
                                "vehiclePrice"
                              ]._value;

                            for (let i = 0; i < years; i++) {
                              vehicleActualPrice =
                                vehicleActualPrice -
                                (vehicleActualPrice * 15) / 100;
                            }
                            vehicleActualPrice =
                              Number(vehicleActualPrice).toFixed(0);
                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehicleValue"
                            ]._value = vehicleActualPrice;
                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehicleTxtPrice"
                            ]._value = value.toString();
                            let vehicleMinValue =
                              vehicleActualPrice -
                              (vehicleActualPrice * 5) / 100;
                            vehicleMinValue =
                              Number(vehicleMinValue).toFixed(0);
                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehicleMinValue"
                            ]._value = Number(vehicleMinValue);
                            let maxPrice = (vehicleActualPrice * 5) / 100;
                            let vehicleMaxValue =
                              Number(vehicleActualPrice) + Number(maxPrice);
                            vehicleMaxValue =
                              Number(vehicleMaxValue).toFixed(0);
                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehicleMaxValue"
                            ]._value = Number(vehicleMaxValue);
                            this.setState({
                              vehicleRegistrationWithoutChassisNumber:
                                vehicleRegistrationWithoutChassisNumberVehicleValue,
                            });
                          }}
                        />
                      </div>
                      <br />
                      <br />
                      <div
                        className="slider-range"
                        style={{ paddingLeft: "20px", paddingRight: "25px" }}
                      >
                        <p className="text-center">
                          Car Value to Insure now: AED{" "}
                          <span id="demo">
                            <Label color="red">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .vehicleValue._value
                              }
                            </Label>
                          </span>
                        </p>
                        <InputRange
                          background="#b0d039"
                          step={500}
                          maxValue={
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .vehicleMaxValue._value
                          }
                          minValue={
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .vehicleMinValue._value
                          }
                          value={
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .vehicleValue._value
                          }
                          // onChange={value => props.setState({ value })} />
                          onChange={(value) => {
                            let vehicleRegistrationWithoutChassisNumberVehicleValue =
                              Object.assign(
                                {},
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                              );

                            vehicleRegistrationWithoutChassisNumberVehicleValue[
                              "vehicleValue"
                            ]._value = value;
                            this.setState({
                              vehicleRegistrationWithoutChassisNumber:
                                vehicleRegistrationWithoutChassisNumberVehicleValue,
                            });
                          }}
                        />
                      </div>
                      <br />
                      <div className="apply-cta">
                        <button
                          className="btn btn-apply"
                          onClick={() => this.submitCarValueDetails()}
                        >
                          Apply changes
                        </button>
                      </div>
                    </div>
                    <div className="popup-footer">
                      <div className="note">
                        <p>
                          <strong>Note:</strong> Your Policy will be issued
                          instantly and uploaded to RTA/Traffic immediately. You
                          can download the Policy Schedule, Certificate & Policy
                          Wordings immediately
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="custom_popup repair-popup"
                  style={{ display: "none" }}
                  id="RepairPopup"
                >
                  <div className="popup-overlay-box">
                    <div className="popup_heading">
                      <h2>Repair Types</h2>
                    </div>
                    <div className="popup-content">
                      <div className="form-check custom_check">
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Agency
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck1"
                        />
                      </div>
                      <div className="form-check custom_check">
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck2"
                        >
                          Garage
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck2"
                        />
                      </div>

                      <div className="form-check custom_check">
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck3"
                        >
                          DynaTrade / Premium
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck3"
                        />
                      </div>
                      <div className="form-check custom_check">
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck4"
                        >
                          Third Party
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck4"
                        />
                      </div>

                      <div className="apply-cta">
                        <button className="btn btn-apply">Apply changes</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="custom_popup personal-popup"
                  style={{ display: this.state.displayPersonals }}
                  id="personalPopup"
                >
                  <div className="popup-overlay-box">
                    <div className="popup_heading">
                      <h2>Personal Details</h2>
                      <span className="close-popup-btn">
                        <i
                          className="svg-close"
                          onClick={() => this.displayPersonal()}
                        >
                          <svg
                            id="Capa_1"
                            enableBackground="new 0 0 413.348 413.348"
                            height="512"
                            viewBox="0 0 413.348 413.348"
                            width="512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"></path>
                          </svg>
                        </i>
                      </span>
                    </div>

                    <div className="popup-content">
                      <form>
                        <div className="form-floating">
                          <input
                            type="text"
                            autoComplete="off"
                            name="plateCode"
                            className="form-control"
                            placeholder="Enter Code"
                            maxLength="2"
                            pattern="[a-zA-Z0-9]+"
                            onChange={this.handleVehicleDetailChange}
                            value={this.state.vehicleDetails.plateCode._value}
                          />

                          {/* <span>Plate Code</span> */}

                          <label htmlFor="floatingInputValue">Plate Code</label>
                          {(this.state.submitVehicleDetails ||
                            this.state.vehicleDetails.plateCode.touched) &&
                          !this.state.vehicleDetails.plateCode._value ? (
                            <span className="text-danger">
                              {this.state.vehicleDetails.plateCode.errorMsg}
                            </span>
                          ) : (
                            <span className="text-danger">
                              {this.state.vehicleDetails.plateCode.errorMessage}
                            </span>
                          )}
                        </div>
                        <div className="form-floating">
                          <input
                            type="text"
                            autoComplete="off"
                            name="plateNumber"
                            className="form-control"
                            placeholder="Plate Number"
                            maxLength="5"
                            pattern="[0-9]+"
                            onChange={this.handleVehicleDetailChange}
                            value={this.state.vehicleDetails.plateNumber._value}
                          />

                          <label htmlFor="floatingInputValue">
                            Plate Number
                          </label>
                          {(this.state.submitVehicleDetails ||
                            this.state.vehicleDetails.plateNumber.touched) &&
                          !this.state.vehicleDetails.plateNumber._value ? (
                            <span className="text-danger">
                              {this.state.vehicleDetails.plateNumber.errorMsg}
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state.vehicleDetails.plateNumber
                                  .errorMessage
                              }
                            </span>
                          )}
                        </div>
                        <div className="form-floating">
                          <input
                            type="text"
                            autoComplete="off"
                            name="yourName"
                            className="form-control"
                            placeholder="Your Name"
                            required
                            pattern="[a-zA-Z\s]{3,}"
                            onChange={this.handlePersonalDetailChange}
                            value={this.state.personalDetails.yourName._value}
                          />
                          <label htmlFor="floatingInputValue">Your Name</label>
                          {(this.state.submitPersonalDetails ||
                            this.state.personalDetails.yourName.touched) &&
                          !this.state.personalDetails.yourName._value ? (
                            <span className="text-danger">
                              {this.state.personalDetails.yourName.errorMsg}
                            </span>
                          ) : (
                            <span className="text-danger">
                              {this.state.personalDetails.yourName.errorMessage}
                            </span>
                          )}
                        </div>
                        <div className="form-floating">
                          <NumberFormat
                            format="0#########"
                            mask=""
                            name="mobileNumber"
                            className="form-control"
                            placeholder="Mobile Number( 502020333 )"
                            maxLength="10"
                            title="Mobile Number"
                            pattern="[0-9]{10}"
                            onChange={this.state.handlePersonalDetailChange}
                            value={
                              this.state.personalDetails.mobileNumber._value
                            }
                          />
                          <label htmlFor="floatingInputValue">
                            Mobile Number
                          </label>

                          {(this.state.submitPersonalDetails ||
                            this.state.personalDetails.mobileNumber.touched) &&
                          !this.state.personalDetails.mobileNumber._value ? (
                            <span className="text-danger">
                              {this.state.personalDetails.mobileNumber.errorMsg}
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state.personalDetails.mobileNumber
                                  .errorMessage
                              }
                            </span>
                          )}
                        </div>
                        <div className="form-floating">
                          <input
                            type="text"
                            autoComplete="off"
                            name="email"
                            className="form-control"
                            onChange={this.handlePersonalDetailChange}
                            placeholder="E-mail"
                            value={this.state.personalDetails.email._value}
                          />
                          {/* <span>E-mail</span> */}
                          {(this.state.submitPersonalDetails ||
                            this.state.personalDetails.email.touched) &&
                          !this.state.personalDetails.email._value ? (
                            <span className="text-danger">
                              {this.state.personalDetails.email.errorMsg}
                            </span>
                          ) : (
                            <span className="text-danger">
                              {this.state.personalDetails.email.errorMessage}
                            </span>
                          )}
                          <label htmlFor="floatingEmail">Email</label>
                        </div>
                      </form>

                      <div className="apply-cta">
                        <button
                          className="btn btn-apply"
                          onClick={() => this.submitPersonalDetails()}
                        >
                          Apply changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="custom_popup car-popup"
                  style={{ display: this.state.displayCarDetails }}
                  id="personalPopup"
                >
                  <div className="popup-overlay-box">
                    <div className="popup_heading">
                      <h2>Car Details</h2>
                      <span className="close-popup-btn">
                        <i
                          className="svg-close"
                          onClick={() => this.displayCarDetails()}
                        >
                          <svg
                            id="Capa_1"
                            enableBackground="new 0 0 413.348 413.348"
                            height="512"
                            viewBox="0 0 413.348 413.348"
                            width="512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                    <div className="popup-content">
                      <form>
                        <div className="form-floating-make">
                          <label htmlFor="floatingInputValue">Model Year</label>
                          <Select
                            placeholder="Select Car Model Year"
                            value={selectedOptionYear}
                            onChange={this.onChangeYear}
                            options={this.state.yearMaster}
                          ></Select>
                          {(this.state
                            .submitVehicleRegistrationWithoutChassisNumberDetails ||
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .modelYear.touched) &&
                          !this.state.vehicleRegistrationWithoutChassisNumber
                            .modelYear._value ? (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .modelYear.errorMsg
                              }
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .modelYear.errorMessage
                              }
                            </span>
                          )}
                        </div>
                        <div className="form-floating-make">
                          <label htmlFor="floatingInputValue">Make</label>
                          <Select
                            placeholder="Select Make"
                            value={selectedOptionMake}
                            onChange={this.onChangeMake}
                            options={this.state.makeSearch}
                          ></Select>
                          {(this.state
                            .submitVehicleRegistrationWithoutChassisNumberDetails ||
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .make.touched) &&
                          !this.state.vehicleRegistrationWithoutChassisNumber
                            .make._value ? (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber.make
                                  .errorMsg
                              }
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber.make
                                  .errorMessage
                              }
                            </span>
                          )}
                        </div>
                        <div className="form-floating-make">
                          <label htmlFor="floatingInputValue">Model</label>
                          <Select
                            placeholder="Select Model"
                            value={selectedOptionModel}
                            onChange={this.onChangeModel}
                            options={this.state.modelSearch}
                          ></Select>
                          {(this.state
                            .submitVehicleRegistrationWithoutChassisNumberDetails ||
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .model.touched) &&
                          !this.state.vehicleRegistrationWithoutChassisNumber
                            .model._value ? (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber.model
                                  .errorMsg
                              }
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber.model
                                  .errorMessage
                              }
                            </span>
                          )}
                        </div>
                        <div className="form-floating-make">
                          <label htmlFor="floatingInputValue">Model Trim</label>
                          <Select
                            placeholder="Select Model Trim"
                            value={selectedOptionTrim}
                            onChange={this.onChangeTrim}
                            options={this.state.trimMaster}
                          >
                            {(this.state
                              .submitVehicleRegistrationWithoutChassisNumberDetails ||
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .trimValue.touched) &&
                            !this.state.vehicleRegistrationWithoutChassisNumber
                              .trimValue._value ? (
                              <span className="text-danger">
                                {
                                  this.state
                                    .vehicleRegistrationWithoutChassisNumber
                                    .trimValue.errorMsg
                                }
                              </span>
                            ) : (
                              <span className="text-danger">
                                {
                                  this.state
                                    .vehicleRegistrationWithoutChassisNumber
                                    .trimValue.errorMessage
                                }
                              </span>
                            )}
                          </Select>
                        </div>
                        <div className="form-floating-make">
                          <label htmlFor="floatingInputValue">
                            Car Variant
                          </label>
                          <Select
                            placeholder="Select Car Variant"
                            value={selectedOptionBodyType}
                            onChange={this.onChangeBodyType}
                            options={this.state.bodyTypeMaster}
                          ></Select>
                          {(this.state
                            .submitVehicleRegistrationWithoutChassisNumberDetails ||
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .bodyType.touched) &&
                          !this.state.vehicleRegistrationWithoutChassisNumber
                            .bodyType._value ? (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .bodyType.errorMsg
                              }
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .bodyType.errorMessage
                              }
                            </span>
                          )}
                        </div>
                        <div className="form-floating-make">
                          <label htmlFor="floatingInputValue">
                            Car Engine Size
                          </label>
                          <Select
                            placeholder="Select Car Engine Size"
                            value={selectedOptionEngineSize}
                            onChange={this.onChangeEngineSize}
                            options={this.state.engineSizeMaster}
                          ></Select>
                          {(this.state
                            .submitVehicleRegistrationWithoutChassisNumberDetails ||
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .engineSize.touched) &&
                          !this.state.vehicleRegistrationWithoutChassisNumber
                            .engineSize._value ? (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .engineSize.errorMsg
                              }
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .engineSize.errorMessage
                              }
                            </span>
                          )}
                        </div>{" "}
                        <div className="form-floating-make">
                          <label className="form-datepicker-label">
                            Date Of Registration
                          </label>

                          <ReactInputDateMask
                            mask="dd/mm/yyyy"
                            showMaskOnFocus={true}
                            id="floatingDatePicker"
                            className="inputDateMask"
                            value={
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .dateOfRegistration._values
                            }
                            onChange={this.onChangeRegistrationDate}
                            showMaskOnHover={true}
                          />
                          {this.state
                            .submitVehicleRegistrationWithoutChassisNumberDetails ||
                          this.state.vehicleRegistrationWithoutChassisNumber
                            .dateOfRegistration.touched ? (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .dateOfRegistration.errorMsg
                              }
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .dateOfRegistration.errorMessage
                              }
                            </span>
                          )}
                        </div>
                        <div className="form-floating">
                          <label className="form-datepicker-label">
                            Driving License Issued Date
                          </label>
                          <ReactInputDateMask
                            mask="dd/mm/yyyy"
                            showMaskOnFocus={true}
                            id="floatingDatePicker"
                            className="inputDateMask"
                            value={
                              this.state.vehicleRegistrationWithoutChassisNumber
                                .dLIssuedDate._values
                            }
                            onChange={this.onChangeDLIssueDate}
                            showMaskOnHover={true}
                          />

                          {this.state
                            .submitVehicleRegistrationWithoutChassisNumberDetails ||
                          this.state.vehicleRegistrationWithoutChassisNumber
                            .dLIssuedDate.touched ? (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .dLIssuedDate.errorMsg
                              }
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .dLIssuedDate.errorMessage
                              }
                            </span>
                          )}
                        </div>
                        <div className="form-floating-make">
                          <label htmlFor="floatingInputValue">City</label>
                          <Select
                            placeholder="Select City"
                            value={selectedOptionEmirates}
                            onChange={this.onChangeEmirate}
                            options={this.state.emirates}
                          ></Select>
                          {(this.state
                            .submitVehicleRegistrationWithoutChassisNumberDetails ||
                            this.state.vehicleRegistrationWithoutChassisNumber
                              .dLEmirate.touched) &&
                          !this.state.vehicleRegistrationWithoutChassisNumber
                            .dLEmirate._value ? (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .dLEmirate.errorMsg
                              }
                            </span>
                          ) : (
                            <span className="text-danger">
                              {
                                this.state
                                  .vehicleRegistrationWithoutChassisNumber
                                  .dLEmirate.errorMessage
                              }
                            </span>
                          )}
                        </div>
                      </form>

                      <div className="apply-cta">
                        <button
                          className="btn btn-apply"
                          onClick={() => this.submitCarDetails()}
                        >
                          Apply changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Benny Html Addition */}

                <div
                  className="custom_popup repair-popup"
                  style={{ display: this.state.displayAgencys }}
                  id="RepairPopup"
                >
                  <div className="popup-overlay-box">
                    <div className="popup_heading">
                      <h2>Repair Types</h2>
                      <span className="close-popup-btn">
                        <i className="svg-close">
                          <svg
                            id="Capa_1"
                            onClick={() => this.displayAgency()}
                            enableBackground="new 0 0 413.348 413.348"
                            height="512"
                            viewBox="0 0 413.348 413.348"
                            width="512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                    <div className="popup-content">
                      <div className="form-check custom_check">
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Agency
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="Agency"
                          checked={this.state.selectedOption.includes("Agency")}
                          onChange={this.onRadioValueChange}
                          name="Agency"
                          id="defaultCheck1"
                        />
                      </div>

                      <div className="form-check custom_check">
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck41"
                        >
                          Non-Agency
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="NonAgency"
                          checked={this.state.selectedOption.includes(
                            "NonAgency"
                          )}
                          onChange={this.onRadioValueChange}
                          name="Agency"
                          id="defaultCheck41"
                        />
                      </div>

                      {/* <div className="apply-cta">
                              <button className="btn btn-apply" onClick={() => this.submitAgency()}>Apply changes</button>
                              </div> */}

                      <div className="apply-cta-with-reset">
                        {/* <button className="btn btn-reset" onClick={this.radioreset}>Reset </button> */}
                        <button
                          className="btn btn-apply"
                          onClick={() => this.submitAgency()}
                        >
                          Apply changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="custom_popup sort-popup"
                  style={{ display: this.state.displaySorts }}
                  id="sortPopup"
                >
                  <div className="popup-overlay-box">
                    <div className="popup_heading">
                      <h2>Sort / Filter</h2>
                      <span className="close-popup-btn">
                        <i className="svg-close">
                          <svg
                            id="Capa_1"
                            onClick={() => this.displaySortFilter()}
                            enableBackground="new 0 0 413.348 413.348"
                            height="512"
                            viewBox="0 0 413.348 413.348"
                            width="512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                    <div className="popup-content">
                      <form>
                        <div className="d-flex">
                          <div className="sort-head"></div>
                          <div className="sort-content">
                            <div className="d-flex align-items-start custom_pills">
                              <div
                                className="nav flex-column nav-pills me-3"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                              >
                                <button
                                  className="nav-link active"
                                  id="v-pills-home-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#v-pills-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="v-pills-home"
                                  aria-selected="true"
                                >
                                  Sort
                                </button>
                                {/* <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Plan Types</button> */}
                                <button
                                  className="nav-link"
                                  id="v-pills-settings-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#v-pills-settings"
                                  type="button"
                                  role="tab"
                                  aria-controls="v-pills-settings"
                                  aria-selected="false"
                                >
                                  Repair Types
                                </button>
                                <button
                                  className="nav-link"
                                  id="v-pills-insurer-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#v-pills-insurer"
                                  type="button"
                                  role="tab"
                                  aria-controls="v-pills-insurer"
                                  aria-selected="false"
                                >
                                  Insurer
                                </button>
                              </div>
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-home"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-home-tab"
                                >
                                  <div className="form-check custom-check">
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault2"
                                    >
                                      Premium Low to High
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      value="ascend"
                                      checked={
                                        this.state.selectedPremiumSortOption ===
                                        "ascend"
                                      }
                                      onChange={this.onSortRadioValueChange}
                                      name="Premium"
                                      id="flexRadioDefault2"
                                    />
                                  </div>

                                  <div className="form-check custom-check">
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault3"
                                    >
                                      Premium High to Low
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      value="decend"
                                      checked={
                                        this.state.selectedPremiumSortOption ===
                                        "decend"
                                      }
                                      onChange={this.onSortRadioValueChange}
                                      name="Premium"
                                      id="flexRadioDefault3"
                                    />
                                  </div>
                                </div>

                                {/* <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
   <h3 className="heading-sort">Plan Type:</h3>
   <div className="form-check custom_check">
                               <label className="form-check-label" htmlFor="defaultCheck1">
Comprehensive Plan
</label>
<input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />

</div>
                            <div className="form-check custom_check">
                               <label className="form-check-label" htmlFor="defaultCheck2">
Third Party Plan
</label>
<input className="form-check-input" type="checkbox" value="" id="defaultCheck2" />

</div>
                          
                            <div className="form-check custom_check">
                               <label className="form-check-label" htmlFor="defaultCheck3">
Takaful Plan
</label>
<input className="form-check-input" type="checkbox" value="" id="defaultCheck3" />

</div>
    
  </div> */}

                                <div
                                  className="tab-pane fade"
                                  id="v-pills-settings"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-settings-tab"
                                >
                                  <h3 className="heading-sort">Repair Type:</h3>
                                  <div className="form-check custom_check">
                                    <label
                                      className="form-check-label"
                                      htmlFor="defaultCheck1"
                                    >
                                      Agency
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value="Agency"
                                      onChange={(event) =>
                                        this.handleRepairCheckboxChange(event)
                                      }
                                      checked={this.state.repairFilterList.includes(
                                        "Agency"
                                      )}
                                      id="defaultCheck1"
                                    />
                                  </div>
                                  <div className="form-check custom_check">
                                    <label
                                      className="form-check-label"
                                      htmlFor="defaultCheck2"
                                    >
                                      Non-Agency
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value="NonAgency"
                                      onChange={(event) =>
                                        this.handleRepairCheckboxChange(event)
                                      }
                                      checked={this.state.repairFilterList.includes(
                                        "NonAgency"
                                      )}
                                      id="defaultCheck2"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-insurer"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-insurer-tab"
                                >
                                  <h3 className="heading-sort">Insurer:</h3>
                                  <div className="scroll-y min-height-250">
                                    {this.state.uniqueInsuranceName &&
                                      this.state.uniqueInsuranceName.map(
                                        (insname, index) => (
                                          <div className="form-check custom_check">
                                            <label
                                              className="form-check-label"
                                              htmlFor="defaultCheck1"
                                            >
                                              {insname}
                                            </label>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              onChange={(event) =>
                                                this.handleCheckboxChange(
                                                  index
                                                )(event)
                                              }
                                              value={insname}
                                              checked={this.state.insuranceFilterList.includes(
                                                insname
                                              )}
                                            />
                                          </div>
                                        )
                                      )}

                                    {/* <div className="form-check custom_check">
                               <label className="form-check-label" htmlFor="defaultCheck1">
SALAMA ARAB Insurance Company
</label>
<input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />

</div>
                            <div className="form-check custom_check">
                               <label className="form-check-label" htmlFor="defaultCheck2">
The New India Assurance 
</label>
<input className="form-check-input" type="checkbox" value="" id="defaultCheck2" />

</div>
                          
                            <div className="form-check custom_check">
                               <label className="form-check-label" htmlFor="defaultCheck3">
Noor takaful
</label>
<input className="form-check-input" type="checkbox" value="" id="defaultCheck3" />

</div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="apply-cta-with-reset">
                          <button
                            className="btn btn-reset"
                            onClick={this.resetsortFilter}
                          >
                            Reset All
                          </button>
                          <button
                            className="btn btn-apply"
                            onClick={this.sortFilter}
                          >
                            Apply changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div>
                  <Modal
                    fade={false}
                    size="sm"
                    centered
                    isOpen={this.state.smShow}
                    toggle={() => this.setSmShow()}
                  >
                    <ModalBody>
                      <div>
                        {" "}
                        <Label>
                          {this.state.responseMsg !== ""
                            ? this.state.responseMsg
                            : "Something Went Wrong"}
                        </Label>
                      </div>
                    </ModalBody>{" "}
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={() => this.setSmShow()}
                      >
                        Ok
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>

                <div className="quotes-title">
                  <span>Comprehensive Plans</span>
                </div>
                <div className="col-lg-12">
                  <div className=" container q_block">
                    {this.state.quoteList &&
                      this.state.quoteList.map((quote) => {
                        return (
                          <div className="list-table-row row">
                            <div className="w-20 v-allign-middle">
                              <div className="quote-box">
                                <div className="company-logo">
                                  {this.state.setinsuranceLogo.length !== 0 &&
                                    this.state.setinsuranceLogo.map((logo) => {
                                      if (
                                        logo.insuranceCompany === quote.InsName
                                      )
                                        return (
                                          <img
                                            alt=""
                                            src={
                                              "https://uat.bankbychoice.com" +
                                              logo.logo
                                            }
                                          />
                                        );
                                    })}
                                </div>
                                <div className="company-name">
                                  <h4 className="plan_name">{quote.InsName}</h4>
                                  <h3>{quote.PlanName}</h3>
                                </div>
                              </div>{" "}
                            </div>
                            <div className="w-20 no-padding">
                              <div className="quote-box">
                                <div className="quote-plan">
                                  <ul className="plan-details">
                                    {/* <li className="p_name"><strong>Plan name: </strong> <span>{quote.PlanName}</span></li> */}
                                    <li className="p_car">
                                      <strong>Car Value: </strong>{" "}
                                      <span>AED {quote.VehicleValue}</span>
                                    </li>
                                    <li className="p_money">
                                      <strong>Premium: </strong>{" "}
                                      <span>AED {quote.Premium}</span>
                                    </li>
                                    {/* <li className="p_money"><strong>Net Premium: </strong> <span>AED {quote.NetPremium}</span></li> */}
                                    <li className="p_agency">
                                      <strong>Agency: </strong>{" "}
                                      <span>{quote.CoverageType}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="w-20 no-padding">
                              <div className="quote-box">
                                <div className="q_features">
                                  <h3>Accident Cover</h3>
                                </div>
                                <ul className="plan_cover with-tick show-more check-list">
                                  <li>
                                    <strong>
                                      Driver Cover :{" "}
                                      <span>Limit Upto AED 200,000</span>
                                    </strong>{" "}
                                    <span>Free</span>
                                  </li>
                                  <li>
                                    <strong>
                                      Passenger Cover :{" "}
                                      <span>Limit Upto AED 200,000</span>
                                    </strong>{" "}
                                    <span>Free</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/* <div className="w-20 no-padding">
                    <div className="quote-box">
                        <div className="quote-plan">
                            <ul className="plan-details">
                                <li className="p_name"><strong>Plan name: </strong> <span>{quote.PlanName}</span></li>
                                <li className="p_car"><strong>Car Value: </strong> <span>AED {quote.VehicleValue}</span></li>
                                <li className="p_money"><strong>Premium: </strong> <span>AED {quote.Premium}</span></li>
                                <li className="p_money"><strong>Net Premium: </strong> <span>AED {quote.NetPremium}</span></li>
                                <li className="p_agency"><strong>Agency: </strong> <span>{quote.CoverageType}</span></li>
                            </ul>
                        </div>
                    </div>
                </div> */}

                            <div className="w-20 no-padding">
                              <div className="quote-box">
                                <div className="q_features">
                                  <h3>Additional Covers</h3>
                                </div>
                                <ul className="plan_cover with-tick show-more check-list">
                                  <Features features={quote.Features} />
                                </ul>
                              </div>
                            </div>

                            <div className="w-20 no-padding">
                              <div className="quote-box">
                                <div className="q_pricing">
                                  <ul className="plan-details">
                                    <li className="p_car">
                                      <strong>Vehicle Value: </strong>{" "}
                                      <span>AED {quote.VehicleValue}</span>
                                    </li>
                                    <li className="p_money">
                                      <strong>Premium: </strong>
                                      <s className="strike-price">
                                        AED {quote.Premium}
                                      </s>{" "}
                                      <span className="final-amount">
                                        AED {quote.Premium}
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="quote-cta">
                                    <Link
                                      to={{
                                        pathname: "/quotation",
                                        state: {
                                          stateActualQuote:
                                            this.state.stateActualQuote,
                                          quote: quote ? quote : "",
                                          quoteList: this.state.quoteList,
                                          vehicleId: this.state.vehicleId,
                                          vehicleDetails:
                                            this.state.vehicleDetails,
                                          personalDetails:
                                            this.state.personalDetails,
                                          vehicleRegistrationWithoutChassisNumber:
                                            this.state
                                              .vehicleRegistrationWithoutChassisNumber
                                              ? this.state
                                                  .vehicleRegistrationWithoutChassisNumber
                                              : "",
                                          fileDetailsMulkiya:
                                            this.state.fileDetailsMulkiya,
                                        },
                                      }}
                                    >
                                      Buy Now
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {this.state.quoteList && this.state.quoteList.length <= 0 && (
                      <div className="no-quote-list">
                        <h3>
                          No Quote List available, Please change the car
                          values!!
                        </h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={(e) => this.onSubmit()}>
              <div>
                <div className="container">
                  <div
                    className={
                      "side-panel-section " + this.state.largeWidthSidePanel
                    }
                    id="expand"
                  >
                    <div className="side-panel">
                      <div className="sidenav-header">
                        <h3>
                          More Quotes available for instant policy issuance with
                          special offers!
                        </h3>
                      </div>
                      <hr />
                      <div className="side-panel-body">
                        <div className="panel-block">
                          <div className="p_img_block">
                            <div className="p_outer-img-bl">
                              <img alt="" src="img/driver-license.svg" />
                            </div>
                            <h3>Upload Mulkiya</h3>
                          </div>
                          <div className="p_img_block">
                            <div className="p_outer-img-bl">
                              <img alt="" src="img/driver-license.svg" />
                            </div>
                            <h3>Upload Emirates ID</h3>
                          </div>
                          <div className="p_img_block">
                            <div className="p_outer-img-bl">
                              <img alt="" src="img/driver-license.svg" />
                            </div>
                            <h3>Upload Driving License</h3>
                          </div>
                        </div>
                        <div
                          className="side-button"
                          id="toggle-button"
                          onClick={() => this.collapse()}
                        >
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>

                    {this.state.showSlide && (
                      <div>
                        <Collapse
                          className="side-button"
                          isOpened={this.state.collapse}
                          onClick={() => this.state.collapse()}
                        >
                          <Step1
                            currentStep={this.state.currentStep}
                            submitDocumentDetailsMulkiya={
                              this.state.submitDocumentDetailsMulkiya
                            }
                            documentDetailsMulkiya={
                              this.state.documentDetailsMulkiya
                            }
                            onChange1={this.handledocumentMulkiyaFChange}
                            onChange2={this.handledocumentMulkiyaBChange}
                            removeMulkiyaF={this.removeMulkiyaF}
                            removeMulkiyaB={this.removeMulkiyaB}
                            handlefileDetailsValidationsMulkiya={
                              this.state.handlefileDetailsValidationsMulkiya
                            }
                            loader={this.state.loader}
                            horizontalScroll={this.horizontalScroll}
                            setSmShow={this.setSmShow}
                            smShow={this.state.smShow}
                            myJson={this.state.vehicleDetailsIDs}
                            sliderDetails={this.state.sliderDetails}
                            slideIndex3={this.state.slideIndex3}
                            getMulkiyaDetails={this.getMulkiyaDetails}
                            setState={this.setState}
                            state={this.state}
                            ref={this.slide3}
                            fileDetailsMulkiya={this.state.fileDetailsMulkiya}
                            updateCount3={this.state.updateCount3}
                            onChange={this.handlefileDetailMulkiyaChange}
                            onChangeInsDate={
                              this.handlefileDetailMulkiyaInsDateChange
                            }
                            onChangeExpDate={
                              this.handlefileDetailMulkiyaExpDateChange
                            }
                            onChangeRegDate={
                              this.handlefileDetailMulkiyaRegDateChange
                            }
                            prevButton={this.previousButton()}
                            nextButton={this.nextButton()}
                            uploadFile={this.uploadFile()}
                            collapse={this.collapse}
                            uploadMulkiya={this.uploadMulkiya}
                            formatInputValue={this.formatInputValue}
                            collapsed={this.state.collapse}
                            largeWidthSidePanel={this.state.largeWidthSidePanel}
                            submitfileDetailsMulkiya={
                              this.state.submitfileDetailsMulkiya
                            }
                            vehicleDetails={this.state.vehicleDetails}
                            personalDetails={this.state.personalDetails}
                            vehicleRegistrationWithoutChassisNumber={
                              this.state.vehicleRegistrationWithoutChassisNumber
                            }
                            imgMulkiyaF={this.state.imgMulkiyaF}
                            imgMulkiyaB={this.state.imgMulkiyaB}
                            getImageMulkiyaF={this.getImageMulkiyaF}
                            getImageMulkiyaB={this.getImageMulkiyaB}
                            closePopup1={this.state.closePopup1}
                          />

                          <Step2
                            currentStep={this.state.currentStep}
                            removeLicenceF={this.removeLicenceF}
                            removeLicenceB={this.removeLicenceB}
                            submitDocumentDetailsLicence={
                              this.state.submitDocumentDetailsLicence
                            }
                            documentDetailsLicence={
                              this.state.documentDetailsLicence
                            }
                            onChange1={this.handledocumentLicenceFChange}
                            onChange2={this.handledocumentLicenceBChange}
                            fileDetailsLicence={this.state.fileDetailsLicence}
                            onChange={this.handlefileDetailLicenceChange}
                            onChangeEmirates={this.onChangeEmirates}
                            emirates={this.state.emirates}
                            emiratesMaster={this.state.emiratesMaster}
                            onChangeExpDate={this.onChangeLicenceExpDate}
                            onChangeIssueDate={this.onChangeLicenceIssueDate}
                            submitfileDetailsLicence={
                              this.state.submitfileDetailsLicence
                            }
                            loader={this.state.loader}
                            prevButton={this.previousButton()}
                            nextButton={this.nextButton()}
                            uploadFile={this.uploadFile()}
                            setState={this.setState}
                            state={this.state}
                            imgLicenceF={this.state.imgLicenceF}
                            imgLicenceB={this.state.imgLicenceB}
                            getImageLicenceF={this.getImageLicenceF}
                            getImageLicenceB={this.getImageLicenceB}
                            closePopup2={this.state.closePopup2}
                          />

                          <Step3
                            currentStep={this.state.currentStep}
                            removeEmiratesIDF={this.removeEmiratesIDF}
                            removeEmiratesIDB={this.removeEmiratesIDB}
                            nationality={this.state.nationality}
                            gender={this.state.gender}
                            submitDocumentDetailsEmiratesID={
                              this.state.submitDocumentDetailsEmiratesID
                            }
                            documentDetailsEmiratesID={
                              this.state.documentDetailsEmiratesID
                            }
                            onChange1={this.handledocumentEmiratesIDFChange}
                            onChange2={this.handledocumentEmiratesIDBChange}
                            onChange3={this.handleChangeNationality}
                            onChange4={this.handleChangeGender}
                            setState={this.setState}
                            state={this.state}
                            onChangeExpDate={this.onChangeEmiratesExpDate}
                            onChangeBirthDate={this.onChangeEmiratesBirthDate}
                            fileDetailsEmiratesID={
                              this.state.fileDetailsEmiratesID
                            }
                            onChange={this.handlefileDetailEmiratesIDChange}
                            submitfileDetailsEmiratesID={
                              this.state.submitfileDetailsEmiratesID
                            }
                            loader={this.state.loader}
                            prevButton={this.previousButton()}
                            nextButton={this.nextButton()}
                            uploadFile={this.uploadFile()}
                            imgEmiratesF={this.state.imgEmiratesF}
                            imgEmiratesB={this.state.imgEmiratesB}
                            getImageEmiratesIDF={this.getImageEmiratesIDF}
                            getImageEmiratesIDB={this.getImageEmiratesIDB}
                            closePopup3={this.state.closePopup3}
                          />

                          <Step4
                            currentStep={this.state.currentStep}
                            loader={this.state.loader}
                          ></Step4>
                          <br />
                        </Collapse>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>

          <footer className="page-footer">
            <div className="footer">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-sm-12">
                    <p className="">
                      Copyright 2021 | bankbychoice.com – All rights reserved
                    </p>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <p className=" text-center">
                      Insurance is provided by Gulf Oasis Insurance Brokers LLC
                      Authored by UAE Insurance Authority
                    </p>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <ul className="breadcrumb pt-0 pr-0 float-right">
                      <li className="breadcrumb-item ">
                        <a
                          target="_blank"
                          href="https://www.bankbychoice.com/terms-conditions/"
                          className="btn-link"
                        >
                          Terms of Use
                        </a>
                      </li>
                      <li className="breadcrumb-item ">
                        <a
                          target="_blank"
                          href="https://www.bankbychoice.com/privacy-policy/"
                          className="btn-link"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      {/* <li className="breadcrumb-item mb-0">
                        <a href="#" className="btn-link">Docs</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
function Step1(props) {
  var myDropzone;
  const renderCustomInputregDate = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Date of Registration"
      value={
        props.fileDetailsMulkiya.regDate._value
          ? `${
              props.fileDetailsMulkiya.regDate._value.day +
              "/" +
              props.fileDetailsMulkiya.regDate._value.month +
              "/" +
              props.fileDetailsMulkiya.regDate._value.year
            }`
          : ""
      }
      className="form-control" // a styling className
    />
  );
  const renderCustomInputexpDate = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Expiry Date"
      value={
        props.fileDetailsMulkiya.expDate._value
          ? `${
              props.fileDetailsMulkiya.expDate._value.day +
              "/" +
              props.fileDetailsMulkiya.expDate._value.month +
              "/" +
              props.fileDetailsMulkiya.expDate._value.year
            }`
          : ""
      }
      className="form-control" // a styling className
    />
  );
  const renderCustomInputinsExp = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Ins Date"
      value={
        props.fileDetailsMulkiya.insExp._value
          ? `${
              props.fileDetailsMulkiya.insExp._value.day +
              "/" +
              props.fileDetailsMulkiya.insExp._value.month +
              "/" +
              props.fileDetailsMulkiya.insExp._value.year
            }`
          : ""
      }
      className="form-control" // a styling className
    />
  );
  const dropzoneComponentConfigdocumentMulkiyaF = {
    postUrl: "https://httpbin.org/post",
    iconFiletypes: [".jpg", ".png", ".gif"],
    showFiletypeIcon: true,
  };
  const dropzoneConfigdocumentMulkiyaF = {
    thumbnailHeight: 260,
    thumbnailWidth: 250,
    maxFilesize: 5,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 w-30 position-relative">
            <div className="dz-error-mark">
              <span>
                <i />{" "}
              </span>
            </div>
            <div className="dz-success-mark">
              <span>
                <i />
              </span>
            </div>
            <div className="preview-container">
              <img
                alt=""
                data-dz-thumbnail
                className="img-thumbnail border-0"
              />
              <i className="simple-icon-doc preview-icon" />
            </div>
          </div>
          <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
            <div>
              {" "}
              <span data-dz-name />{" "}
            </div>
            <div className="text-primary text-extra-small" data-dz-size />
            <div className="dz-progress">
              <span className="dz-upload" data-dz-uploadprogress />
            </div>
            <div className="dz-error-message">
              <span data-dz-errormessage />
            </div>
          </div>
        </div>
        <div className="success">Successfully uploaded </div>
        <div onClick={() => props.removeMulkiyaF}>
          <a href="#/" className="remove" data-dz-remove>
            <i className="fa fa-remove" />{" "}
          </a>
        </div>
      </div>
    ),
    headers: { "My-Awesome-Header": "header value" },
  };
  const dropzoneComponentConfigdocumentMulkiyaB = {
    postUrl: "https://httpbin.org/post",
    iconFiletypes: [".jpg", ".png", ".gif"],
    showFiletypeIcon: true,
  };
  const dropzoneConfigdocumentMulkiyaB = {
    thumbnailHeight: 260,
    thumbnailWidth: 250,
    maxFilesize: 5,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 w-30 position-relative">
            <div className="dz-error-mark">
              <span>
                <i />{" "}
              </span>
            </div>
            <div className="dz-success-mark">
              <span>
                <i />
              </span>
            </div>
            <div className="preview-container">
              <img
                alt=""
                data-dz-thumbnail
                className="img-thumbnail border-0"
              />
              <i className="simple-icon-doc preview-icon" />
            </div>
          </div>
          <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
            <div>
              {" "}
              <span data-dz-name />{" "}
            </div>
            <div className="text-primary text-extra-small" data-dz-size />
            <div className="dz-progress">
              <span className="dz-upload" data-dz-uploadprogress />
            </div>
            <div className="dz-error-message">
              <span data-dz-errormessage />
            </div>
          </div>
        </div>
        <div className="success">Successfully uploaded </div>
        <div>
          <a href="#/" className="remove" data-dz-remove>
            {" "}
            <i className="fa fa-remove" onClick={props.removeMulkiyaB} />{" "}
          </a>
        </div>
      </div>
    ),
    headers: { "My-Awesome-Header": "header value" },
  };
  if (props.currentStep !== 1) {
    //console.log(props.currentStep);
    return null;
  }
  return (
    <React.Fragment>
      <div className="container">
        <div className={"close-popup " + props.closePopup1}></div>
        <div className="row">
          <div className="col-lg-6">
            <div className="header-panel-expand">
              <span className="icon-img-expand">
                {" "}
                <img alt="" src="img/driver-license.svg" />
              </span>{" "}
              <h3>Upload your Mulkiya Card details </h3>
            </div>
            <br />{" "}
            <div className="row">
              <div className="col-lg-12">
                <div className="card-right-header">
                  <h3>
                    Mulkiya Card Front
                    <span style={{ color: "red" }}>*</span>
                  </h3>
                </div>
                <div className="allign-center card-mobile-fix justify-content-between">
                  <div className="right-side-content">
                    {/* <!-- Upload Area --> */}
                    <div id="uploadArea" className="upload-area">
                      {(props.submitDocumentDetailsMulkiya ||
                        props.documentDetailsMulkiya.documentMulkiyaF
                          .touched1) &&
                        !props.documentDetailsMulkiya.documentMulkiyaF
                          ._value1 && (
                          <span className="text-danger">
                            {
                              props.documentDetailsMulkiya.documentMulkiyaF
                                .errorMsg
                            }
                          </span>
                        )}

                      <DropzoneComponent
                        id="documentMulkiyaF"
                        name="documentMulkiyaF"
                        config={dropzoneComponentConfigdocumentMulkiyaF}
                        djsConfig={dropzoneConfigdocumentMulkiyaF}
                        eventHandlers={{
                          init: (dropzone) => {
                            myDropzone = dropzone;
                            //  props.documentDetailsMulkiya.documentMulkiyaF._value1 = dropzone
                            //console.log("dropzone", myDropzone);
                          },
                          addedfile: props.onChange1,
                          complete: props.getImageMulkiyaF,
                          removedfile: myDropzone
                            ? myDropzone.removeFile()
                            : props.removeMulkiyaF,
                        }}
                      />

                      {/* End Drop Zoon  */}

                      {/* File Details */}
                      {/* <div id="fileDetails" className="upload-area__file-details file-details">
                                            <h3 className="file-details__title">Uploaded File</h3>

                                            <div id="uploadedFile" className="uploaded-file">
                                                <div className="uploaded-file__icon-container">
                                                    <i className='bx bxs-file-blank uploaded-file__icon'></i>
                                                    <span className="uploaded-file__icon-text"></span>
                                                   
                                                </div>

                                                <div id="uploadedFileInfo" className="uploaded-file__info">
                                                    <span className="uploaded-file__name">Project 1</span>
                                                    <span className="uploaded-file__counter">0%</span>
                                                </div>
                                            </div>
                                        </div>
                                        */}
                      {/* End File Details  */}
                    </div>
                    {/* End Upload Area  */}
                  </div>
                  <div className="right-side-content-image">
                    <img
                      alt=""
                      src={
                        props.imgMulkiyaF
                          ? props.imgMulkiyaF
                          : "img/mulikya-frnt.jpeg"
                      }
                      width="100%"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-12 ">
                <div className="card-right-header">
                  <h3>
                    Mulkiya Card Back
                    <span style={{ color: "red" }}>*</span>
                  </h3>
                </div>
                <div className="allign-center card-mobile-fix justify-content-between">
                  <div className="right-side-content">
                    <div id="uploadArea" className="upload-area">
                      {(props.submitDocumentDetailsMulkiya ||
                        props.documentDetailsMulkiya.documentMulkiyaB
                          .touched2) &&
                        !props.documentDetailsMulkiya.documentMulkiyaB
                          ._value2 && (
                          <span className="text-danger">
                            {
                              props.documentDetailsMulkiya.documentMulkiyaB
                                .errorMsg
                            }
                          </span>
                        )}

                      <DropzoneComponent
                        id="documentMulkiyaB"
                        name="documentMulkiyaB"
                        config={dropzoneComponentConfigdocumentMulkiyaB}
                        djsConfig={dropzoneConfigdocumentMulkiyaB}
                        eventHandlers={{
                          init: (dropzone) => {
                            // props.documentDetailsMulkiya.documentMulkiyaB._value2 = dropzone
                          },
                          addedfile: props.onChange2,
                          complete: props.getImageMulkiyaB,
                          removedfile: props.removeMulkiyaB,
                        }}
                      />
                    </div>
                  </div>
                  <div className="right-side-content-image">
                    <img
                      alt=""
                      src={
                        props.imgMulkiyaB
                          ? props.imgMulkiyaB
                          : "img/mulikya-bck.jpeg"
                      }
                      width="100%"
                    />
                  </div>
                </div>
                <div className="row m-t-30">
                  <div className="col-lg-8">
                    <p style={{ color: "red", marginBottom: "0rem" }}>
                      * Ensure you upload the front and back separately, in
                      .jpeg, .png or .heic format, and not more than 5 MB each.
                    </p>
                  </div>
                  <div className="col-lg-4">{props.uploadFile}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="header-panel-expand">
              <span className="icon-img-expand">
                {" "}
                <img alt="" src="img/driver-license.svg" />
              </span>
              <h3>Mulkiya Card details </h3>
            </div>
            <div className="form-custom">
              <div className="field-section height-correrction">
                {props.loader && <div className="loading" />}
                {/* {!props.loader && */}
                <div className="card-body min-height-300">
                  <div className="row">
                    <div className="col-lg-4 col-xs-6">
                      <div className="form-group form-floating">
                        {/* <DatePicker
selected={props.fileDetailsMulkiya.regDate._value}
onChange={props.onChange} 
/> */}
                        {/* <ThemeProvider
theme={{
breakpoints: ["32em", "48em", "64em"],
reactDatepicker: {
daySize: [36, 40],
fontFamily: "system-ui, -apple-system",
colors: {
accessibility: "#D80249",
selectedDay: "#f7518b",
selectedDayHover: "#F75D95",
primaryColor: "#d8366f"
}
}
}}
>
<DateSingleInput
onDateChange={data => dispatch({ type: 'dateChange', payload: data })}
onFocusChange={focusedInput => dispatch({ type: 'focusChange', payload: focusedInput })}
date={props.fileDetailsMulkiya.regDate._value} // Date or null
showDatepicker={state.showDatepicker} // Boolean
/>
</ThemeProvider> */}
                        <label className="form-datepicker-label">
                          Reg Date
                        </label>
                        <DatePicker
                          renderInput={renderCustomInputregDate}
                          value={props.fileDetailsMulkiya.regDate._value}
                          onChange={props.onChangeRegDate}
                          maximumDate={utils().getToday()}
                          format={"DD/MM/YYYY"}
                          shouldHighlightWeekends
                        />

                        {/* <DatePicker className={"form-control"} name="regDate" id="regDate" selected={props.fileDetailsMulkiya.regDate._value} onChange={props.onChangeRegDate} selectsStart
                                                            startDate={props.fileDetailsMulkiya.regDate._value}
                                                            placeholder="Reg Date" maxDate={new Date()}
                                                            selectsStart
                                                            dateFormat="dd/MM/yyyy" autoComplete="off"
                                                            
    showMonthDropdown
    showYearDropdown
    dropdownMode= "select"     maxLength="11"        value={props.fileDetailsMulkiya.regDate._value}
                                                        /> */}

                        {/* <span>Reg Date</span> */}
                      </div>
                      {(props.submitfileDetailsMulkiya ||
                        props.fileDetailsMulkiya.regDate.touched) &&
                        !props.fileDetailsMulkiya.regDate._value && (
                          <span className="text-danger">
                            {props.fileDetailsMulkiya.regDate.errorMsg}
                          </span>
                        )}
                      {/* <br/>  <span className="text-danger">{props.otpDetails.otp.errorMessage}</span>   */}
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group form-floating">
                        <label className="form-datepicker-label">
                          Expiry Date
                        </label>
                        <DatePicker
                          renderInput={renderCustomInputexpDate}
                          value={props.fileDetailsMulkiya.expDate._value}
                          onChange={props.onChangeExpDate}
                          minimumDate={utils().getToday()}
                          shouldHighlightWeekends
                        />
                        {/* <DatePicker className={"form-control"} name="expDate" id="expDate" selected={props.fileDetailsMulkiya.expDate._value} onChange={props.onChangeExpDate} selectsStart
                                                            startDate={props.fileDetailsMulkiya.expDate._value}
                                                            placeholder="Expiry Date" minDate={new Date()}
                                                            selectsStart
                                                            dateFormat="dd/MM/yyyy" autoComplete="off"
                                                                                                
    showMonthDropdown
    showYearDropdown
    dropdownMode= "select"           maxLength="11"      value={props.fileDetailsMulkiya.expDate._value}
                                                        /> */}
                      </div>
                      {(props.submitfileDetailsMulkiya ||
                        props.fileDetailsMulkiya.expDate.touched) &&
                        !props.fileDetailsMulkiya.expDate._value && (
                          <span className="text-danger">
                            {props.fileDetailsMulkiya.expDate.errorMsg}
                          </span>
                        )}
                      {/* <br/>  <span className="text-danger">{props.otpDetails.otp.errorMessage}</span>   */}
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group form-floating">
                        {/* <DatePicker 
selected={props.fileDetailsMulkiya.insExp._value} onChange={props.onChange}
showTimeSelect
dateFormat="Pp"
className="Datepicker pa2"
minDate={new Date()}
placeholderText="Select a date"
calendarClassName="rasta-stripes"
value={props.fileDetailsMulkiya.insExp._value}
popperModifiers={{
offset: {
enabled: true,
offset: "0px, 0px"
},
preventOverflow: {
enabled: true,
escapeWithReference: false,
boundariesElement: "scrollParent"
}
}} /> */}
                        <label className="form-datepicker-label">Ins Exp</label>
                        <DatePicker
                          renderInput={renderCustomInputinsExp}
                          value={props.fileDetailsMulkiya.insExp._value}
                          onChange={props.onChangeInsDate}
                          minimumDate={utils().getToday()}
                          shouldHighlightWeekends
                        />

                        {/* <DatePicker className={"form-control"} name="insExp" id="insExp" selected={props.fileDetailsMulkiya.insExp._value} onChange={props.onChangeInsDate} selectsStart
                                                            startDate={props.fileDetailsMulkiya.insExp._value}
                                                            placeholder="Ins Date" minDate={new Date()}
                                                            selectsStart
                                                            dateFormat="dd/MM/yyyy" autoComplete="off"
                                                                                                    
    showMonthDropdown
    showYearDropdown
    dropdownMode= "select"       maxLength="11"      value={props.fileDetailsMulkiya.insExp._value}
                                                        />  */}
                      </div>
                      {(props.submitfileDetailsMulkiya ||
                        props.fileDetailsMulkiya.insExp.touched) &&
                        !props.fileDetailsMulkiya.insExp._value && (
                          <span className="text-danger">
                            {props.fileDetailsMulkiya.insExp.errorMsg}
                          </span>
                        )}
                      {/* <br/>  <span className="text-danger">{props.otpDetails.otp.errorMessage}</span>   */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group form-floating">
                        <input
                          type="text"
                          autoComplete="off"
                          name="plateCategory"
                          disabled
                          className="form-control"
                          placeholder="Plate Category"
                          onChange={props.onChange}
                          value={props.fileDetailsMulkiya.plateCategory._value}
                        />{" "}
                        <label>Plate Category</label>
                      </div>
                      {(props.submitfileDetailsMulkiya ||
                        props.fileDetailsMulkiya.plateCategory.touched) &&
                        !props.fileDetailsMulkiya.plateCategory._value && (
                          <span className="text-danger">
                            {props.fileDetailsMulkiya.plateCategory.errorMsg}
                          </span>
                        )}

                      {/* <br/>  <span className="text-danger">{props.otpDetails.otp.errorMessage}</span>   */}
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group form-floating">
                        <input
                          type="text"
                          autoComplete="off"
                          name="plateNo"
                          disabled
                          className="form-control"
                          placeholder="Plate No"
                          onChange={props.onChange}
                          value={props.fileDetailsMulkiya.plateNo._value}
                        />{" "}
                        <label>Plate No</label>
                      </div>{" "}
                      {(props.submitfileDetailsMulkiya ||
                        props.fileDetailsMulkiya.plateNo.touched) &&
                        !props.fileDetailsMulkiya.plateNo._value && (
                          <span className="text-danger">
                            {props.fileDetailsMulkiya.plateNo.errorMsg}
                          </span>
                        )}
                      {/* <br/>  <span className="text-danger">{props.otpDetails.otp.errorMessage}</span>   */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group form-floating">
                        <input
                          type="text"
                          autoComplete="off"
                          name="chassisNumber"
                          className="form-control"
                          placeholder="Chassis Number"
                          onChange={props.onChange}
                          maxLength="17"
                          value={props.fileDetailsMulkiya.chassisNumber._value}
                        />{" "}
                        <label>ChassisNumber</label>
                      </div>{" "}
                      {(props.submitfileDetailsMulkiya ||
                        props.fileDetailsMulkiya.chassisNumber.touched) &&
                        !props.fileDetailsMulkiya.chassisNumber._value && (
                          <span className="text-danger">
                            {props.fileDetailsMulkiya.chassisNumber.errorMsg}
                          </span>
                        )}
                      {/* <br/>  <span className="text-danger">{props.otpDetails.otp.errorMessage}</span>   */}
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group form-floating">
                        <input
                          type="text"
                          autoComplete="off"
                          name="engineNumber"
                          className="form-control"
                          placeholder="Engine Number"
                          onChange={props.onChange}
                          maxLength="10"
                          value={props.fileDetailsMulkiya.engineNumber._value}
                        />{" "}
                        <label>Engine Number</label>
                      </div>{" "}
                      {(props.submitfileDetailsMulkiya ||
                        props.fileDetailsMulkiya.engineNumber.touched) &&
                        !props.fileDetailsMulkiya.engineNumber._value && (
                          <span className="text-danger">
                            {props.fileDetailsMulkiya.engineNumber.errorMsg}
                          </span>
                        )}
                      {/* <br/>  <span className="text-danger">{props.otpDetails.otp.errorMessage}</span>   */}
                    </div>
                  </div>
                  {/* <div className="col-4">



                  <div className="form-group form-floating">

< input type="text" autoComplete="off"
name="trim"
className="form-control"
placeholder="Trim"
onChange={props.onChange}
value={props.fileDetailsMulkiya.trim._value}
/>  <span>Trim</span>
</div> {(props.submitfileDetailsMulkiya || props.fileDetailsMulkiya.trim.touched) && !props.fileDetailsMulkiya.trim._value && <span className="text-danger">{props.fileDetailsMulkiya.trim.errorMsg}</span>}


</div> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group form-floating">
                        <input
                          type="number"
                          autoComplete="off"
                          name="cylinder"
                          className="form-control"
                          placeholder="Cylinder"
                          onChange={props.onChange}
                          maxLength="10"
                          value={props.fileDetailsMulkiya.cylinder._value}
                        />{" "}
                        <label>Cylinder</label>
                      </div>{" "}
                      {(props.submitfileDetailsMulkiya ||
                        props.fileDetailsMulkiya.cylinder.touched) &&
                        !props.fileDetailsMulkiya.cylinder._value && (
                          <span className="text-danger">
                            {props.fileDetailsMulkiya.cylinder.errorMsg}
                          </span>
                        )}
                      {/* <br/>  <span className="text-danger">{props.otpDetails.otp.errorMessage}</span>   */}
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group form-floating">
                        <input
                          type="text"
                          autoComplete="off"
                          name="engineSize"
                          className="form-control"
                          placeholder="Engine Size"
                          onChange={props.onChange}
                          disabled
                          maxLength="10"
                          value={props.fileDetailsMulkiya.engineSize._value}
                        />{" "}
                        <label>Engine Size</label>
                      </div>{" "}
                      {(props.submitfileDetailsMulkiya ||
                        props.fileDetailsMulkiya.engineSize.touched) &&
                        !props.fileDetailsMulkiya.engineSize._value && (
                          <span className="text-danger">
                            {props.fileDetailsMulkiya.engineSize.errorMsg}
                          </span>
                        )}
                      {/* <br/>  <span className="text-danger">{props.otpDetails.otp.errorMessage}</span>   */}
                    </div>
                  </div>
                  <br />{" "}
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4"></div>
                    <div className="col-4"></div>
                  </div>
                </div>
                {/* } */}
              </div>

              {/* <div className="next-btn">
                        <a href="#" className="btn btn-primary" onClick={props.uploadMulkiya}><i className="fas fa-upload"></i> Upload details</a>
                    </div> */}
            </div>

            <div className="next-btn">
              {props.prevButton}
              {props.nextButton}
            </div>
          </div>
        </div>

        {/* <div className="next-btn float-right">
                <a href="#" className="btn btn-primary"> Next</a>
            </div> */}
      </div>
    </React.Fragment>
  );
}

function Step2(props) {
  const renderCustomInputissueDate = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Issue Date"
      value={
        props.fileDetailsLicence.issueDate._value
          ? `${
              props.fileDetailsLicence.issueDate._value.day +
              "/" +
              props.fileDetailsLicence.issueDate._value.month +
              "/" +
              props.fileDetailsLicence.issueDate._value.year
            }`
          : ""
      }
      className="form-control" // a styling className
    />
  );
  const renderCustomInputexpiryDates = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Expiry Date"
      value={
        props.fileDetailsLicence.expiryDate._value
          ? `${
              props.fileDetailsLicence.expiryDate._value.day +
              "/" +
              props.fileDetailsLicence.expiryDate._value.month +
              "/" +
              props.fileDetailsLicence.expiryDate._value.year
            }`
          : ""
      }
      className="form-control" // a styling className
    />
  );
  const dropzoneComponentConfigLicenceF = {
    postUrl: "https://httpbin.org/post",
    iconFiletypes: [".jpg", ".png", ".gif"],
    showFiletypeIcon: true,
  };
  const dropzoneConfigLicenceF = {
    thumbnailHeight: 260,
    thumbnailWidth: 250,
    maxFilesize: 1,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 w-30 position-relative">
            <div className="dz-error-mark">
              <span>
                <i />{" "}
              </span>
            </div>
            <div className="dz-success-mark">
              <span>
                <i />
              </span>
            </div>
            <div className="preview-container">
              <img
                alt=""
                data-dz-thumbnail
                className="img-thumbnail border-0"
              />
              <i className="simple-icon-doc preview-icon" />
            </div>
          </div>
          <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
            <div>
              {" "}
              <span data-dz-name />{" "}
            </div>
            <div className="text-primary text-extra-small" data-dz-size />
            <div className="dz-progress">
              <span className="dz-upload" data-dz-uploadprogress />
            </div>
            <div className="dz-error-message">
              <span data-dz-errormessage />
            </div>
          </div>
        </div>
        <div className="success">Successfully uploaded </div>
        <div>
          <a href="#/" className="remove" data-dz-remove>
            {" "}
            <i className="fa fa-remove" onClick={props.removeLicenceF} />{" "}
          </a>
        </div>
      </div>
    ),
    headers: { "My-Awesome-Header": "header value" },
  };
  const dropzoneComponentConfigLicenceB = {
    postUrl: "https://httpbin.org/post",
    iconFiletypes: [".jpg", ".png", ".gif"],
    showFiletypeIcon: true,
  };
  const dropzoneConfigLicenceB = {
    thumbnailHeight: 260,
    thumbnailWidth: 250,
    maxFilesize: 1,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 w-30 position-relative">
            <div className="dz-error-mark">
              <span>
                <i />{" "}
              </span>
            </div>
            <div className="dz-success-mark">
              <span>
                <i />
              </span>
            </div>
            <div className="preview-container">
              <img
                alt=""
                data-dz-thumbnail
                className="img-thumbnail border-0"
              />
              <i className="simple-icon-doc preview-icon" />
            </div>
          </div>
          <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
            <div>
              {" "}
              <span data-dz-name />{" "}
            </div>
            <div className="text-primary text-extra-small" data-dz-size />
            <div className="dz-progress">
              <span className="dz-upload" data-dz-uploadprogress />
            </div>
            <div className="dz-error-message">
              <span data-dz-errormessage />
            </div>
          </div>
        </div>
        <div className="success">Successfully uploaded </div>
        <div>
          <a href="#/" className="remove" data-dz-remove>
            {" "}
            <i className="fa fa-remove" />{" "}
          </a>
        </div>
      </div>
    ),
    headers: { "My-Awesome-Header": "header value" },
  };
  if (props.currentStep !== 2) {
    //console.log(props.currentStep);
    return null;
  }
  return (
    <React.Fragment>
      <div className={"close-popup " + props.closePopup2}></div>

      <div className="container">
        <div>
          <br />{" "}
          <div className="row">
            <div className="col-lg-6">
              <div className="header-panel-expand">
                <span className="icon-img-expand">
                  {" "}
                  <img alt="" src="img/driver-license.svg" />
                </span>{" "}
                <h3>Upload your Driving Licence Card details </h3>
              </div>
              <br />{" "}
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-right-header">
                    <h3>
                      Driving Licence Card front
                      <span style={{ color: "red" }}>*</span>
                    </h3>
                  </div>
                  <div className="allign-center card-mobile-fix justify-content-between">
                    <div className="right-side-content">
                      <div id="uploadArea" className="upload-area">
                        {(props.submitDocumentDetailsLicence ||
                          props.documentDetailsLicence.documentLicenceF
                            .touched1) &&
                          !props.documentDetailsLicence.documentLicenceF
                            ._value1 && (
                            <span className="text-danger">
                              {
                                props.documentDetailsLicence.documentLicenceF
                                  .errorMsg
                              }
                            </span>
                          )}

                        <DropzoneComponent
                          id="documentLicenceF"
                          name="documentLicenceF"
                          config={dropzoneComponentConfigLicenceF}
                          djsConfig={dropzoneConfigLicenceF}
                          eventHandlers={{
                            init: (dropzone) => {
                              // props.documentDetailsLicence.documentLicenceF._value1 = dropzone
                            },
                            addedfile: props.onChange1,
                            complete: props.getImageLicenceF,
                            removedfile: props.removeLicenceF,
                          }}
                        />
                      </div>
                    </div>
                    <div className="right-side-content-image">
                      <img
                        alt=""
                        src={
                          props.imgLicenceF
                            ? props.imgLicenceF
                            : "img/driver-license-front.jpeg"
                        }
                        width="100%"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div className="card-right-header">
                    <h3>
                      Driving Licence Card Back
                      <span style={{ color: "red" }}>*</span>
                    </h3>
                  </div>
                  <div className="allign-center card-mobile-fix justify-content-between">
                    <div className="right-side-content">
                      <div id="uploadArea" className="upload-area">
                        {(props.submitDocumentDetailsLicence ||
                          props.documentDetailsLicence.documentLicenceB
                            .touched2) &&
                          !props.documentDetailsLicence.documentLicenceB
                            ._value2 && (
                            <span className="text-danger">
                              {
                                props.documentDetailsLicence.documentLicenceB
                                  .errorMsg
                              }
                            </span>
                          )}

                        <DropzoneComponent
                          id="documentLicenceB"
                          name="documentLicenceB"
                          config={dropzoneComponentConfigLicenceB}
                          djsConfig={dropzoneConfigLicenceB}
                          eventHandlers={{
                            init: (dropzone) => {
                              // props.documentDetailsLicence.documentLicenceB._value2 = dropzone
                            },
                            addedfile: props.onChange2,
                            complete: props.getImageLicenceB,
                            removedfile: props.removeLicenceB,
                          }}
                        />
                      </div>
                    </div>
                    <div className="right-side-content-image">
                      {/* <img alt="" src="img/driver-license-back.jpeg" width="100%" /> */}
                      <img
                        alt=""
                        src={
                          props.imgLicenceB
                            ? props.imgLicenceB
                            : "img/driver-license-back.jpeg"
                        }
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8">
                      <p style={{ color: "red", marginBottom: "0rem" }}>
                        * Ensure you upload the front and back separately, in
                        .jpeg, .png or .heic format, and not more than 5 MB
                        each.
                      </p>
                    </div>
                    <div className="col-lg-4">{props.uploadFile}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="header-panel-expand">
                <span className="icon-img-expand">
                  {" "}
                  <img alt="" src="img/driver-license.svg" />
                </span>
                <h3>Driving Licence Card details </h3>
              </div>
              <div className="form-custom">
                <div className="field-section height-correrction">
                  {props.loader && <div className="loading" />}
                  {/* {!props.loader && */}
                  <div className="card-body min-height-300">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group form-floating">
                          <label className="form-datepicker-label">
                            Expiry Date
                          </label>
                          <DatePicker
                            renderInput={renderCustomInputexpiryDates}
                            value={props.fileDetailsLicence.expiryDate._value}
                            inputPlaceholder="Expiry Date"
                            onChange={props.onChangeExpDate}
                            minimumDate={utils().getToday()}
                            shouldHighlightWeekends
                          />
                          {/* <DatePicker className={"form-control"} name="expiryDate" id="expiryDate" selected={props.fileDetailsLicence.expiryDate._value} onChange={props.onChangeExpDate} selectsStart
         maxLength="11"      startDate={props.fileDetailsLicence.expiryDate._value}
            placeholder="Expiry Date" minDate={new Date()}
                                                
showMonthDropdown
showYearDropdown
dropdownMode= "select"           selectsStart
            dateFormat="dd/MM/yyyy" autoComplete="off"
            value={props.fileDetailsLicence.expiryDate._value}
        /> */}
                        </div>{" "}
                        {(props.submitfileDetailsLicence ||
                          props.fileDetailsLicence.expiryDate.touched) &&
                          !props.fileDetailsLicence.expiryDate._value && (
                            <span className="text-danger">
                              {props.fileDetailsLicence.expiryDate.errorMsg}
                            </span>
                          )}
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group form-floating">
                          <label className="form-datepicker-label">
                            Issue Date
                          </label>
                          <DatePicker
                            renderInput={renderCustomInputissueDate}
                            value={props.fileDetailsLicence.issueDate._value}
                            inputPlaceholder="Issue Date"
                            onChange={props.onChangeIssueDate}
                            maximumDate={utils().getToday()}
                            shouldHighlightWeekends
                          />
                          {/* <DatePicker className={"form-control"} name="issueDate" id="issueDate" selected={props.fileDetailsLicence.issueDate._value} onChange={props.onChangeIssueDate} selectsStart
                                                        maxLength="11"       startDate={props.fileDetailsLicence.issueDate._value}
                                                            placeholder="Issue Date" maxDate={new Date()}
                                                            selectsStart
                                                            dateFormat="dd/MM/yyyy" autoComplete="off"
                                                                                               
    showMonthDropdown
    showYearDropdown
    dropdownMode= "select"            value={props.fileDetailsLicence.issueDate._value}
                                                        /> */}
                        </div>{" "}
                        {(props.submitfileDetailsLicence ||
                          props.fileDetailsLicence.issueDate.touched) &&
                          !props.fileDetailsLicence.issueDate._value && (
                            <span className="text-danger">
                              {props.fileDetailsLicence.issueDate.errorMsg}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group form-floating">
                          <input
                            type="number"
                            autoComplete="off"
                            name="licenceNo"
                            className="form-control"
                            placeholder="Licence No"
                            onChange={props.onChange}
                            maxLength="10"
                            value={props.fileDetailsLicence.licenceNo._value}
                          />{" "}
                          <label>Licence No</label>
                        </div>
                        {(props.submitfileDetailsLicence ||
                          props.fileDetailsLicence.licenceNo.touched) &&
                          !props.fileDetailsLicence.licenceNo._value && (
                            <span className="text-danger">
                              {props.fileDetailsLicence.licenceNo.errorMsg}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group form-floating">
                          <select
                            className="form-control "
                            onChange={(event) => props.onChangeEmirates(event)}
                            value={props.fileDetailsLicence.placeofIssue._value}
                          >
                            <option value="">Choose Emirates</option>
                            {props.emiratesMaster &&
                              props.emiratesMaster.map((data, index) => {
                                return (
                                  <option key={data.id} value={data.emirates}>
                                    {data.emirates}
                                  </option>
                                );
                              })}
                          </select>
                          <label>Place of Issue</label>

                          {/* < input type="text" autoComplete="off"
                                                                                              name="placeofIssue"
                                                                                              className="form-control"
                                                                                              placeholder="Place of Issue"
                                                                                              onChange={props.onChange}
                                                                                              maxLength="10"            value={props.fileDetailsLicence.placeofIssue._value}
                                                                                          />  */}
                        </div>{" "}
                        {(props.submitfileDetailsLicence ||
                          props.fileDetailsLicence.placeofIssue.touched) &&
                          !props.fileDetailsLicence.placeofIssue._value && (
                            <span className="text-danger">
                              {props.fileDetailsLicence.placeofIssue.errorMsg}
                            </span>
                          )}
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group form-floating">
                          <input
                            type="number"
                            autoComplete="off"
                            name="TCNo"
                            className="form-control"
                            placeholder="TCNo"
                            onChange={props.onChange}
                            maxLength="10"
                            value={props.fileDetailsLicence.TCNo._value}
                          />{" "}
                          <label>TCNo</label>
                        </div>{" "}
                        {(props.submitfileDetailsLicence ||
                          props.fileDetailsLicence.TCNo.touched) &&
                          !props.fileDetailsLicence.TCNo._value && (
                            <span className="text-danger">
                              {props.fileDetailsLicence.TCNo.errorMsg}
                            </span>
                          )}
                      </div>
                    </div>
                    <br />{" "}
                    <div className="row">
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                    </div>
                  </div>
                  {/* } */}
                </div>

                <div className="next-btn">
                  {props.prevButton}
                  {props.nextButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
function Step3(props) {
  const renderCustomInputexpiryDate = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Expiry Date"
      value={
        props.fileDetailsEmiratesID.expiryDate._value
          ? `${
              props.fileDetailsEmiratesID.expiryDate._value.day +
              "/" +
              props.fileDetailsEmiratesID.expiryDate._value.month +
              "/" +
              props.fileDetailsEmiratesID.expiryDate._value.year
            }`
          : ""
      }
      className="form-control" // a styling className
    />
  );
  const renderCustomInputdateofBirth = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Date of Birth"
      value={
        props.fileDetailsEmiratesID.dateofBirth._value
          ? `${
              props.fileDetailsEmiratesID.dateofBirth._value.day +
              "/" +
              props.fileDetailsEmiratesID.dateofBirth._value.month +
              "/" +
              props.fileDetailsEmiratesID.dateofBirth._value.year
            }`
          : ""
      }
      className="form-control" // a styling className
    />
  );
  const dropzoneComponentConfigEmiratesIDF = {
    postUrl: "https://httpbin.org/post",
    iconFiletypes: [".jpg", ".png", ".gif"],
    showFiletypeIcon: true,
  };
  const dropzoneConfigEmiratesIDF = {
    thumbnailHeight: 260,
    thumbnailWidth: 250,
    maxFilesize: 1,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 w-30 position-relative">
            <div className="dz-error-mark">
              <span>
                <i />{" "}
              </span>
            </div>
            <div className="dz-success-mark">
              <span>
                <i />
              </span>
            </div>
            <div className="preview-container">
              <img
                alt=""
                data-dz-thumbnail
                className="img-thumbnail border-0"
              />
              <i className="simple-icon-doc preview-icon" />
            </div>
          </div>
          <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
            <div>
              {" "}
              <span data-dz-name />{" "}
            </div>
            <div className="text-primary text-extra-small" data-dz-size />
            <div className="dz-progress">
              <span className="dz-upload" data-dz-uploadprogress />
            </div>
            <div className="dz-error-message">
              <span data-dz-errormessage />
            </div>
          </div>
        </div>
        <div className="success">Successfully uploaded </div>
        <div>
          <a href="#/" className="remove" data-dz-remove>
            {" "}
            <i className="fa fa-remove" />{" "}
          </a>
        </div>
      </div>
    ),
    headers: { "My-Awesome-Header": "header value" },
  };
  const dropzoneComponentConfigEmiratesIDB = {
    postUrl: "https://httpbin.org/post",
    iconFiletypes: [".jpg", ".png", ".gif"],
    showFiletypeIcon: true,
  };
  const dropzoneConfigEmiratesIDB = {
    thumbnailHeight: 260,
    thumbnailWidth: 250,
    maxFilesize: 1,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 w-30 position-relative">
            <div className="dz-error-mark">
              <span>
                <i />{" "}
              </span>
            </div>
            <div className="dz-success-mark">
              <span>
                <i />
              </span>
            </div>
            <div className="preview-container">
              <img
                alt=""
                data-dz-thumbnail
                className="img-thumbnail border-0"
              />
              <i className="simple-icon-doc preview-icon" />
            </div>
          </div>
          <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
            <div>
              {" "}
              <span data-dz-name />{" "}
            </div>
            <div className="text-primary text-extra-small" data-dz-size />
            <div className="dz-progress">
              <span className="dz-upload" data-dz-uploadprogress />
            </div>
            <div className="dz-error-message">
              <span data-dz-errormessage />
            </div>
          </div>
        </div>
        <div className="success">Successfully uploaded </div>
        <div>
          <a href="#/" className="remove" data-dz-remove>
            {" "}
            <i className="fa fa-remove" />{" "}
          </a>
        </div>
      </div>
    ),
    headers: { "My-Awesome-Header": "header value" },
  };
  if (props.currentStep !== 3) {
    //console.log(props.currentStep);
    return null;
  }
  return (
    <React.Fragment>
      <div className={"close-popup " + props.closePopup3}></div>

      <div className="container">
        <div>
          <br />{" "}
          <div className="row">
            <div className="col-lg-6">
              <div className="header-panel-expand">
                <span className="icon-img-expand">
                  {" "}
                  <img alt="" src="img/driver-license.svg" />
                </span>{" "}
                <h3>Upload your EmiratesID Card details </h3>
              </div>
              <br />{" "}
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-right-header">
                    <h3>
                      EmiratesID Card front
                      <span style={{ color: "red" }}>*</span>
                    </h3>
                  </div>
                  <div className="allign-center card-mobile-fix justify-content-between">
                    <div className="right-side-content">
                      <div id="uploadArea" className="upload-area">
                        {(props.submitDocumentDetailsEmiratesID ||
                          props.documentDetailsEmiratesID.documentEmiratesIDF
                            .touched1) &&
                          !props.documentDetailsEmiratesID.documentEmiratesIDF
                            ._value1 && (
                            <span className="text-danger">
                              {
                                props.documentDetailsEmiratesID
                                  .documentEmiratesIDF.errorMsg
                              }
                            </span>
                          )}

                        <DropzoneComponent
                          id="documentEmiratesIDF"
                          name="documentEmiratesIDF"
                          config={dropzoneComponentConfigEmiratesIDF}
                          djsConfig={dropzoneConfigEmiratesIDF}
                          eventHandlers={{
                            init: (dropzone) => {
                              // props.documentDetailsEmiratesID.documentEmiratesIDF._value1 = dropzone
                            },
                            addedfile: props.onChange1,
                            complete: props.getImageEmiratesIDF,
                            removedfile: props.removeEmiratesIDF,
                          }}
                        />
                      </div>
                    </div>
                    <div className="right-side-content-image">
                      <img
                        alt=""
                        src={
                          props.imgEmiratesF
                            ? props.imgEmiratesF
                            : "img/emiratesid-front.jpeg"
                        }
                        width="100%"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div className="card-right-header">
                    <h3>
                      EmiratesID Card Back
                      <span style={{ color: "red" }}>*</span>
                    </h3>
                  </div>
                  <div className="allign-center card-mobile-fix justify-content-between">
                    <div className="right-side-content">
                      <div id="uploadArea" className="upload-area">
                        {(props.submitDocumentDetailsEmiratesID ||
                          props.documentDetailsEmiratesID.documentEmiratesIDB
                            .touched2) &&
                          !props.documentDetailsEmiratesID.documentEmiratesIDB
                            ._value2 && (
                            <span className="text-danger">
                              {
                                props.documentDetailsEmiratesID
                                  .documentEmiratesIDB.errorMsg
                              }
                            </span>
                          )}

                        <DropzoneComponent
                          id="documentEmiratesIDB"
                          name="documentEmiratesIDB"
                          config={dropzoneComponentConfigEmiratesIDB}
                          djsConfig={dropzoneConfigEmiratesIDB}
                          eventHandlers={{
                            init: (dropzone) => {
                              // props.documentDetailsEmiratesID.documentEmiratesIDB._value2 = dropzone
                            },
                            addedfile: props.onChange2,
                            complete: props.getImageEmiratesIDB,
                            removedfile: props.removeEmiratesIDB,
                          }}
                        />
                      </div>
                    </div>
                    <div className="right-side-content-image">
                      <img
                        alt=""
                        src={
                          props.imgEmiratesB
                            ? props.imgEmiratesB
                            : "img/emiratesid-back.jpeg"
                        }
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8">
                      <p style={{ color: "red", marginBottom: "0rem" }}>
                        * Ensure you upload the front and back separately, in
                        .jpeg, .png or .heic format, and not more than 5 MB
                        each.
                      </p>{" "}
                    </div>
                    <div className="col-lg-4">{props.uploadFile}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="header-panel-expand">
                <span className="icon-img-expand">
                  {" "}
                  <img alt="" src="img/driver-license.svg" />
                </span>
                <h3>EmiratesID Card details </h3>
              </div>
              <div className="form-custom">
                <div className="field-section height-correrction">
                  {props.loader && <div className="loading" />}
                  {/* {!props.loader && */}
                  <div className="card-body min-height-300">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group form-floating">
                          <label className="form-datepicker-label">
                            Date of Birth
                          </label>
                          <DatePicker
                            renderInput={renderCustomInputdateofBirth}
                            value={
                              props.fileDetailsEmiratesID.dateofBirth._value
                            }
                            inputPlaceholder="Date of Birth"
                            onChange={props.onChangeBirthDate}
                            maximumDate={new Date().getFullYear() - 18}
                            dateFormat="dd/MM/yyyy"
                            shouldHighlightWeekends
                          />
                          {/* <DatePicker className={"form-control"} name="dateofBirth" id="dateofBirth" selected={props.fileDetailsEmiratesID.dateofBirth._value} onChange={props.onChangeBirthDate} selectsStart
                                                            startDate={props.fileDetailsEmiratesID.dateofBirth._value}
                                                            placeholder="Date of Birth" maxDate={new Date()}
                                                            selectsStart
                                                                                                 
    showMonthDropdown
    showYearDropdown
    dropdownMode= "select"          dateFormat="dd/MM/yyyy" autoComplete="off"
                                                            value={props.fileDetailsEmiratesID.dateofBirth._value}
                                                        /> */}
                        </div>{" "}
                        {(props.submitfileDetailsEmiratesID ||
                          props.fileDetailsEmiratesID.dateofBirth.touched) &&
                          !props.fileDetailsEmiratesID.dateofBirth._value && (
                            <span className="text-danger">
                              {props.fileDetailsEmiratesID.dateofBirth.errorMsg}
                            </span>
                          )}
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group form-floating">
                          <label className="form-datepicker-label">
                            Expiry Date
                          </label>
                          <DatePicker
                            renderInput={renderCustomInputexpiryDate}
                            value={
                              props.fileDetailsEmiratesID.expiryDate._value
                            }
                            inputPlaceholder="Expiry Date"
                            onChange={props.onChangeExpDate}
                            minimumDate={utils().getToday()}
                            shouldHighlightWeekends
                          />
                          {/* <DatePicker className={"form-control"} name="expiryDate" id="expiryDate" selected={props.fileDetailsEmiratesID.expiryDate._value} onChange={props.onChangeExpDate} selectsStart
                                                            startDate={props.fileDetailsEmiratesID.expiryDate._value}
                                                            placeholder="Expiry Date" minDate={new Date()}
                                                                                                  
    showMonthDropdown
    showYearDropdown
    dropdownMode= "select"         selectsStart
                                                            dateFormat="dd/MM/yyyy" autoComplete="off"
                                                            value={props.fileDetailsEmiratesID.expiryDate._value}
                                                        /> */}
                        </div>{" "}
                        {(props.submitfileDetailsEmiratesID ||
                          props.fileDetailsEmiratesID.expiryDate.touched) &&
                          !props.fileDetailsEmiratesID.expiryDate._value && (
                            <span className="text-danger">
                              {props.fileDetailsEmiratesID.expiryDate.errorMsg}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group form-floating">
                          <input
                            type="text"
                            autoComplete="off"
                            name="emiratesID"
                            className="form-control"
                            placeholder="EmiratesID"
                            maxLength="15"
                            onChange={props.onChange}
                            pattern="[0-9]{15}"
                            value={
                              props.fileDetailsEmiratesID.emiratesID._value
                            }
                          />{" "}
                          <label>EmiratesID</label>
                        </div>{" "}
                        {(props.submitfileDetailsEmiratesID ||
                          props.fileDetailsEmiratesID.emiratesID.touched) &&
                        !props.fileDetailsEmiratesID.emiratesID._value ? (
                          <span className="text-danger">
                            {props.fileDetailsEmiratesID.emiratesID.errorMsg}
                          </span>
                        ) : (
                          <span className="text-danger">
                            {
                              props.fileDetailsEmiratesID.emiratesID
                                .errorMessage
                            }
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group form-floating">
                          <select
                            className="form-control "
                            onChange={(event) => props.onChange3(event)}
                            value={
                              props.fileDetailsEmiratesID.nationality._value
                            }
                          >
                            <option value="">Choose Nationality</option>
                            {props.nationality &&
                              props.nationality.map((data, index) => {
                                return (
                                  <option key={data.id} value={data.country}>
                                    {data.country}
                                  </option>
                                );
                              })}
                          </select>
                          <label>Nationality</label>
                        </div>
                        {(props.submitfileDetailsEmiratesID ||
                          props.fileDetailsEmiratesID.nationality.touched) &&
                          !props.fileDetailsEmiratesID.nationality._value && (
                            <span className="text-danger">
                              {props.fileDetailsEmiratesID.nationality.errorMsg}
                            </span>
                          )}
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group form-floating">
                          <select
                            className="form-control "
                            onChange={(event) => props.onChange4(event)}
                            value={props.fileDetailsEmiratesID.gender._value}
                          >
                            <option value="">Choose Gender</option>
                            {props.gender &&
                              props.gender.map((data, index) => {
                                return (
                                  <option key={data.id} value={data.gender}>
                                    {data.gender}
                                  </option>
                                );
                              })}
                          </select>
                          <label>Gender</label>
                        </div>{" "}
                        {(props.submitfileDetailsEmiratesID ||
                          props.fileDetailsEmiratesID.gender.touched) &&
                          !props.fileDetailsEmiratesID.gender._value && (
                            <span className="text-danger">
                              {props.fileDetailsEmiratesID.gender.errorMsg}
                            </span>
                          )}
                      </div>
                    </div>
                    <br />{" "}
                    <div className="row">
                      <div className="col-5"></div>
                      <div className="col-3"></div>
                      <div className="col-4"></div>
                    </div>
                  </div>
                  {/* } */}
                </div>
              </div>

              <div className="next-btn">
                {props.prevButton}
                {props.nextButton}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function Step4(props) {
  if (props.currentStep !== 4) {
    return null;
  }
  return (
    <React.Fragment>
      {props.loader && <div className="loading" />}
      {!props.loader && (
        <div className={"close-popup " + props.closePopup3}></div>
      )}
    </React.Fragment>
  );
}
export default InsuranceList;
