import React, { Component } from "react";
import ValueExatractor from "./ValueExtractor";
import axios from "axios";
import { apiUAT } from "../../apiConfig/apiurl";

class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: apiUAT,
      login: true,
      loginError: false,
      submittet: false,
      loading: false,
      errorMsg: "",
      error: false,
      loginDetails: {
        username: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
        password: {
          _value: "",
          touched: false,
          required: true,
          error: "",
          errorMsg: "This field is required.",
        },
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.inputRef = React.createRef();
    this.FPinputRef = React.createRef();
  }

  handleChange(e) {
    const { name, value } = e.target;
    var currentState = { ...this.state.loginDetails[name] };
    this.setState((prevState) => {
      let loginDetails = Object.assign({}, prevState.loginDetails); // creating copy of state variable jasper
      loginDetails[name]._value = value;
      loginDetails[name].touched = true; // update the name property, assign a new value
      return { loginDetails }; // return new object jasper object
    });
  }

  handleValidations() {
    let login = this.state.loginDetails;
    let error = false;
    for (var i in login) {
      if (login[i].required && !login[i]._value) {
        error = true;
      }
    }
    return error;
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      submittet: true,
    });
    if (!this.handleValidations()) {
      debugger;
      let data = ValueExatractor(this.state.loginDetails);
      console.log("data", data);

      //MY SQL
      axios
        .post(this.state.api + "AdminUser/ValidateUser", {
          UserName: data.username,
          Password: data.password,
        })
        .then(
          (response) => {
            debugger;
            if (response.status === 200 && response.data !== -1) {
              sessionStorage.setItem("email", data.username);
              sessionStorage.setItem("userID", response.data.userID);
              this.props.history.push("/dashboard/");
            } else {
              this.setState((prevState) => {
                let loginError = true;
                let errorMsg = "Invalid User Name or Password";
                return { loginError, errorMsg };
              });
            }
            console.log(response);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="LoginForm" className="container">
          <div className="row align-items-center h-600">
            <div className="col-lg-12 col-sm-12 ">
              <div className="col-lg-offset-3 col-lg-6 col-sm-offset-2 col-sm-8">
                <div className="login-form ">
                  <div className="main-div">
                    <div className="panel text-center">
                      <h2> Login</h2>
                      <p>Please enter your email and password </p>
                    </div>
                    <form id="Login" onSubmit={this.submitHandler}>
                      <div className="form-group">
                        <input
                          type="email"
                          name="username"
                          value={this.state.loginDetails.username._value}
                          ref={this.inputRef}
                          onChange={this.handleChange}
                          className={
                            "form-control " +
                            ((this.state.submittet ||
                              this.state.loginDetails.username.touched) &&
                            !this.state.loginDetails.username._value
                              ? "is-invalid"
                              : "")
                          }
                          id="inputEmail"
                          placeholder="Email Address"
                        />
                        {(this.state.submittet ||
                          this.state.loginDetails.username.touched) &&
                          !this.state.loginDetails.username._value && (
                            <span className="text-danger">
                              {this.state.loginDetails.username.errorMsg}
                            </span>
                          )}
                      </div>
                      <div
                        className={
                          "form-group " +
                          ((this.state.submittet ||
                            this.state.loginDetails.password.touched) &&
                          !this.state.loginDetails.password._value
                            ? "has-error"
                            : "")
                        }
                      >
                        <input
                          type="password"
                          name="password"
                          value={this.state.loginDetails.password._value}
                          onChange={this.handleChange}
                          className={
                            "form-control " +
                            ((this.state.submittet ||
                              this.state.loginDetails.username.touched) &&
                            !this.state.loginDetails.username._value
                              ? "is-invalid"
                              : "")
                          }
                          id="inputPwd"
                          placeholder="Password"
                        />
                        {(this.state.submittet ||
                          this.state.loginDetails.username.touched) &&
                          !this.state.loginDetails.password._value && (
                            <span className="text-danger">
                              {this.state.loginDetails.password.errorMsg}
                            </span>
                          )}
                      </div>
                      <div className="col-md-9 form-group text-danger">
                        {" "}
                        {this.state.errorMsg}
                      </div>
                      {/* {this.state.loginError &&
                                                <Alert key={1} variant={'danger'}>
                                                    Error! <strong>
                                                        {this.state.errorMsg}
                                                    </strong>
                                                </Alert>
                                            } */}
                      <button
                        type="submit"
                        className="btn btn-block btn-primary "
                      >
                        Login
                      </button>

                      <p className="forgot-password text-center">
                        {" "}
                        <a href="/forgotpassword">Forgot Password?</a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

login.propTypes = {};
export default login;
