import React, { Component } from "react";
import axios from "axios";
//import { api } from "./src/apiConfig/apiurl";

class dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //api: api,
    };
  }

  render() {
    return (
      <React.Fragment>
        <p>Dashboard - List of Customers</p>
      </React.Fragment>
    );
  }
}

dashboard.propTypes = {};
export default dashboard;
